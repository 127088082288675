import parseJSON from 'date-fns/parseJSON'

import { DateParam } from 'use-query-params'

export const DATE_RANGE_START_QUERY_PARAM = {
    name: 'start-date',
    type: DateParam,
    localStorageOptions: { parser: parseJSON },
}

export const DATE_RANGE_END_QUERY_PARAM = {
    name: 'end-date',
    type: DateParam,
    localStorageOptions: { parser: parseJSON },
}
