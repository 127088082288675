import MuiPopover from '@mui/material/Popover'
import { observer } from 'mobx-react-lite'

import { LegacyPopoverProps } from './LegacyPopover.types'

import styles from './LegacyPopover.module.scss'

/**
 * Component can be used for Selects, Popovers, etc.
 */
export const LegacyPopover = observer((props: LegacyPopoverProps) => {
    const {
        children,
        maxHeight = 'unset',
        // 300 - just visually ok width
        // TODO: 300 is not enough for showing Accounts, should we use some constans for this?
        maxWidth = 600,
        anchorOrigin = {
            vertical: 'bottom',
            horizontal: 'left',
        },
        transformOrigin = {
            vertical: 'top',
            horizontal: 'left',
        },
        ...otherProps
    } = props

    return (
        <MuiPopover
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
            style={{
                maxWidth,
                maxHeight,
            }}
            classes={{
                paper: styles.popover,
            }}
            {...otherProps}
        >
            {children}
        </MuiPopover>
    )
})
