import { Tooltip } from '@mui/material'

import { Text } from '@/components/base'
import { Layout } from '@/components/containers'

import { ListInTooltipCellProps } from './ListInTooltipCell.types'

import styles from './ListInTooltipCell.module.scss'

export const ListInTooltipCell = (props: ListInTooltipCellProps) => {
    const { className, list, maxHeight } = props

    const [firstItem = '—', ...restItems] = list

    const tooltipContent = (
        <Layout direction='column' className={styles.container} style={{ maxHeight }}>
            {restItems.map((item, i) => (
                <div key={i}><Text variant='labelMediumDefault' color='colorsPrimaryWhite'>{item}</Text></div>
            ))}
        </Layout>
    )

    return (
        <Layout gap={4} align='center'>
            <Text
                variant='smallTextMediumDefault'
                color='colorsPrimaryGrey'
                className={className}
                overflow='hidden'
                textOverflow='ellipsis'
                elementTitle={firstItem}
            >
                {firstItem}
            </Text>
            {restItems.length > 0 ? (
                <Tooltip title={tooltipContent} arrow>
                    <div
                        className={styles.counter}
                    >
                    +{restItems.length}
                    </div>
                </Tooltip>
            ) : null}
        </Layout>
    )
}
