import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'
import { CompanyUser } from '../company'
import { AccessLevel } from '../core'

import { AssetGroup } from './AssetGroup'

@api('company-user-in-asset-group')
@model
export class CompanyUserInAssetGroup extends Model {
    @field company_user_id?: number
    @field asset_group_id?:  number
    @field level?:           AccessLevel

    @foreign(AssetGroup) readonly asset_group:   AssetGroup
    @foreign(CompanyUser) readonly company_user: CompanyUser

    get level_name (): string {
        // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return AccessLevel[this.level]
    }
}
many(CompanyUserInAssetGroup, 'asset_group_id')(AssetGroup, 'personal_permissions')
many(CompanyUserInAssetGroup, 'company_user_id')(CompanyUser, 'asset_group_personal_permissions')
