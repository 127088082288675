import isValid from 'date-fns/isValid'

import { observer } from 'mobx-react-lite'

import { DatePicker as BaseDatePicker } from '@/components/base/DatePicker'
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate'

import { DatePickerProps } from './DatePicker.types'

export const DatePicker = observer((props: DatePickerProps) => {
    const { input, label = 'Date', ...rest } = props
    return (
        <BaseDatePicker
            label={label}
            // Need normalization here bacause 'mobx-orm' transformed date to format without timezone and it will be parsed like absolute date
            // FIXME: It must be fixed in 'mobx-orm' with adding timezone it query parameter
            // @ts-expect-error TS(2345) FIXME: Argument of type 'Date | null | undefined' is not ... Remove this comment to see the full error message
            date={isValid(input.value) ? normalizeShortBackDate(input.value) : undefined}
            onChange={(date) => { input.set(isValid(date) ? date : undefined) }}
            icon='arrowDown'
            rotateIcon
            {...rest}
        />
    )
})
