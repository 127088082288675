import { useContext } from 'react'

import { useQueryParams } from 'use-query-params'

import { Text } from '@/components/base'
import { TableContext } from '@/components/tables/AgGridTable'
import {
    getAgGridLimitQueryParam,
    getAgGridOffsetQueryParam,
} from '@/components/tables/AgGridTable/AgGridTable.constants'

import { AgGridPaginationLeftPanelProps } from './AgGridPaginationLeftPanel.types'

export const AgGridPaginationLeftPanel = (props: AgGridPaginationLeftPanelProps) => {
    const { className } = props

    const { totalItemsCount, itemsCount, customOffsetQueryParamKey, customLimitQueryParamKey } = useContext(TableContext)
    // @ts-expect-error TS(2532) FIXME: customLimitQueryParamKey and customOffsetQueryParamKey is possibly 'undefined'.
    const [{ [customLimitQueryParamKey]: tablePageLimit, [customOffsetQueryParamKey]: tablePageOffset }] = useQueryParams({
        ...getAgGridLimitQueryParam(customLimitQueryParamKey),
        ...getAgGridOffsetQueryParam(customOffsetQueryParamKey),
    })

    let lastElemOnPage = tablePageOffset + tablePageLimit

    // @ts-expect-error TS(2532) FIXME: totalItemsCount is possibly 'undefined'.
    if (lastElemOnPage > totalItemsCount) {
        lastElemOnPage = totalItemsCount
    }

    // Case for one page
    let countString = itemsCount.toString()

    // If more than one page
    // @ts-expect-error TS(2532) FIXME: totalItemsCount is possibly 'undefined'.
    if (totalItemsCount > tablePageLimit || tablePageOffset > 0) {
        countString += `-${tablePageLimit}`
    }

    return (
        <Text
            variant='labelMediumDefault'
            color='secondaryLightBlack'
            className={className}
        >
            {countString} of {totalItemsCount || itemsCount}
        </Text>
    )
}
