import { useCallback, useEffect, useState } from 'react'

import { useInputState } from '@/hooks'
import { Asset } from '@/models/asset'

import { AssetListSideModal } from '../AssetListSideModal'

import { ASSET_SIDE_MODAL_INPUT_QUERY_PARAM } from './AssetSideModalInput.constants'
import { AssetSideModalInputProps } from './AssetSideModalInput.types'

const { type: paramType } = ASSET_SIDE_MODAL_INPUT_QUERY_PARAM

export const AssetSideModalInput = (props: AssetSideModalInputProps) => {
    const {
        paramName = ASSET_SIDE_MODAL_INPUT_QUERY_PARAM.name,
        options = [],
        onClose,
        multiSelect = false,
        defaultActiveOptions = 'first',
        open,
        required = false,
        syncLocalStorage,
        inputParams,
    } = props

    const [assetIds = [], setAssetIds] = useInputState({ name: paramName, type: paramType, syncLocalStorage, ...inputParams })
    const [selected, setSelected] = useState<Asset[]>([])

    useEffect(() => {
        if (!options?.length) { return }

        const selectedOptions = options.filter((option) => (assetIds ?? []).includes(option.id ?? null))
        if (selectedOptions.length) {
            setSelected(selectedOptions)
            if (selectedOptions.length !== assetIds?.length) {
                // remove non-existing ids from query params
                setAssetIds(selectedOptions.map((option) => option.id ?? null))
            }
            return
        }

        switch (defaultActiveOptions) {
            case 'none':
                if (selected.length) {
                    setSelected([])
                    setAssetIds(undefined)
                }
                break
            case 'first':
                setAssetIds([options[0].id ?? null])
                break
            case 'all':
                setAssetIds(options.map((option) => option.id ?? null))
                break
            default:
                throw new Error(`Unknown reset strategy: ${defaultActiveOptions}`)
        }
    }, [assetIds, options, defaultActiveOptions, selected.length, setAssetIds])

    const handleOnSave = useCallback((assets: Asset[]) => {
        setAssetIds(assets.map((asset) => asset.id ?? null))
        onClose()
    }, [onClose, setAssetIds])

    return (open
        ? (
            <AssetListSideModal
                options={options}
                selected={selected}
                onSave={handleOnSave}
                onClose={onClose}
                multiSelect={multiSelect}
                required={required}
            />
        )
        : null
    )
}
