import { memo } from 'react'

import { createPortal } from 'react-dom'

import { SIDE_MODAL_CONTAINER } from '@/constants/ids'
import { usePortal } from '@/hooks/usePortal'

import { SideModalContainerProps } from './SideModalContainer.types'

import styles from './SideModalContainer.module.scss'

export const SideModalContainer = memo((props: SideModalContainerProps) => {
    const { children, onBgClick } = props

    const container = usePortal(SIDE_MODAL_CONTAINER)

    if (!container) {
        return null
    }

    const handleBgClick = (e) => {
        if (e.target === e.currentTarget) {
            onBgClick(e)
        }
    }

    const content = (
        <div
            className={styles.sideModalBG}
            onClick={handleBgClick}
        >
            <div className={styles.sideModalContainer}>
                {children}
            </div>
        </div>
    )

    return createPortal(
        content,
        container,
    )
})
