import { useCallback, useEffect, useRef, useState } from 'react'

import { SelectOptionListProps, SelectOptionDefault, Loader } from '@/components/base'
import { SelectOptionBase } from '@/components/base/Select/options'

import { SelectNoOptionsBase } from '../../options/SelectNoOptionsBase'

import styles from './SelectOptionListBase.module.scss'

/**
 * @todo: Add virtual scroll
 */
export const SelectOptionListBase = <Option = SelectOptionDefault>(props: SelectOptionListProps<Option>) => {
    const {
        optionComponent: OptionComponent = SelectOptionBase,
        options,
        selected = [],
        labelFn,
        idFn,
        onChange,
        noItemsPlaceholder = 'No items found',
        loopListNavigation = false,
        width = 240,
        minWidth = 240,
        maxWidth,
        maxHeight,
        loading = false,
        disableAll = false,
    } = props

    const listRef = useRef<HTMLUListElement>(null)
    const [isFocused, setIsFocused] = useState(false)
    const [activeIndex, setActiveIndex] = useState<number | null>(null)
    const [selectedInitial] = useState(selected)

    const handleSelect = useCallback(() => {
        if (disableAll) return

        // @ts-expect-error TS(2538) FIXME: Type 'null' cannot be used as an index type.
        const option = options[activeIndex]
        if (option) {
            onChange(option)
        }
    }, [activeIndex, options, onChange])

    useEffect(() => {
        if (!options.length) {
            // No options: reset activeIndex
            setActiveIndex(null)
        } else if (activeIndex === null && !selected.length) {
            // First render: nothing is selected, set activeIndex to be 0
            setActiveIndex(0)
        } else {
            // Multiselect: activeIndex is set
            if (activeIndex !== null && activeIndex < options.length) { return }

            // Set activeIndex to be the index of the first selected option or 0
            const index = options.findIndex(option => selected.length && idFn(option) === idFn(selected[0]))
            setActiveIndex(index === -1 ? 0 : index)
        }
    }, [options, selected, activeIndex, idFn])

    useEffect(() => {
        const handleListNavigation = (e: KeyboardEvent) => {
            if (document.activeElement !== listRef.current) {
                return
            }

            if (e.key === 'Enter' || e.key === ' ') {
                e.preventDefault()
                handleSelect()
            }

            if (e.key === 'ArrowDown') {
                e.preventDefault()

                setActiveIndex((prevIndex) => {
                    if (prevIndex === null) { return null }
                    if (prevIndex === options.length - 1) {
                        return loopListNavigation ? 0 : prevIndex
                    }
                    return prevIndex + 1
                })
            }

            if (e.key === 'ArrowUp') {
                e.preventDefault()

                setActiveIndex((prevIndex) => {
                    if (prevIndex === null) { return null }
                    if (prevIndex === 0) {
                        return loopListNavigation ? options.length - 1 : prevIndex
                    }
                    return prevIndex - 1
                })
            }
        }
        window.addEventListener('keydown', handleListNavigation)

        return () => window.removeEventListener('keydown', handleListNavigation)
    }, [handleSelect, options.length, loopListNavigation])

    return (
        <ul
            style={{ width, maxWidth, minWidth, maxHeight }}
            className={styles.list}
            tabIndex={0}
            ref={listRef}
            role='listbox'
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
            data-testid='options-list'
        >
            {loading && (
                <Loader centered/>
            )}
            {!loading && options.map((option, index) => (
                <OptionComponent
                    key={idFn(option)}
                    option={option}
                    selected={(selected.find(item => idFn(item) === idFn(option))) !== undefined}
                    seletedInitalMark={selectedInitial.some(el => idFn(el) === idFn(option))}
                    active={isFocused && activeIndex === index}
                    labelFn={labelFn}
                    idFn={idFn}
                    disabled={disableAll}
                    onClick={() => onChange(option)}
                    onMouseEnter={() => {
                        listRef.current?.focus()
                        setActiveIndex(index)
                    }}
                />
            ))}
            {(options.length === 0 && !loading) && (<SelectNoOptionsBase placeholder={noItemsPlaceholder}/>)}
        </ul>
    )
}
