import { useContext, useEffect, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { TextInput } from '@/components/base'
import { ModelInput } from '@/utils/mobx-orm'

import { ModelFormContext } from '../../ModelFromContext'

import { StringInputProps } from './StringInput.types'

/**
 * @deprecated use StringInput2
 */
export const StringInput = observer((props: StringInputProps) => {
    const { obj, field, filter, syncURL, syncLocalStorage, onChange, ...rest } = props
    const modelForm = useContext(ModelFormContext)

    const [modelInput] = useState(() => new ModelInput({
        obj: obj || modelForm.obj,
        field,
        filter,
        syncURL: syncURL || modelForm.syncURL,
        syncLocalStorage: syncLocalStorage || modelForm.syncLocalStorage,
    }))

    const onChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        modelInput.set(e.target.value)
        onChange?.(e)
    }

    // destructore
    useEffect(() => {
        return () => {
            modelInput.destroy()
        }
    }, [])

    return (
        <TextInput
            {...rest}
            value={modelInput.value}
            onChange={onChangeHandler}
            // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
            error={modelInput.obj?.__errors?.[field]}
            // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
            hint={modelInput.obj?.__errors?.[field]}
        />
    )
})
