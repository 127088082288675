import { Suspense, useEffect, useState } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { ErrorBoundary, Loader } from '@/components/base'
import { getAvailableRoutes } from '@/core/routes/routes.utils'
import { useRouteKey } from '@/hooks'
import { InternalForbiddenPage } from '@/pages/errors/internal/InternalForbiddenPage'
import { InternalNotFoundPage } from '@/pages/errors/internal/InternalNotFoundPage'

import { PageLayoutProps } from './PageLayout.types'

import styles from './PageLayout.module.scss'

/**
 * @todo Add props for adding filters block
 * @todo Show no access page if parent have no access (not it's strict check and we need to add feature flag for every route)
 */
export const PageLayout = observer((props: PageLayoutProps) => {
    const {
        loading = false,
        warning,
        children,
        noPadding = false,
        className,
        gap = null,
        showNotFoundError = false,
    } = props

    const routeKey = useRouteKey()
    const [showNoAccessPage, setShowNoAccessPage] = useState(false)

    useEffect(() => {
        if (!routeKey) return

        const availableRoutesConfig = getAvailableRoutes()

        if (!availableRoutesConfig[routeKey]) {
            setShowNoAccessPage(true)
        }
    }, [routeKey])

    const loadingPlaceholder = (
        <div className={styles.loaderContainer}>
            <Loader
                centered
            />
        </div>
    )
    const warningPlaceholder = (
        <div className={styles.loaderContainer}>
            {warning}
        </div>
    )

    return (
        <div className={styles.pageLayout}>
            {props.header && <div> {props.header} </div>}
            <div
                className={cn(styles.content, className, { [styles.withPadding]: !noPadding })}
                // @ts-expect-error TS(2322) FIXME: Type 'LayoutSpace | null' is not assignable to typ... Remove this comment to see the full error message
                style={{ gap }}
            >
                <ErrorBoundary noBackground level='pageLayout'>
                    <Suspense fallback={loadingPlaceholder}>
                        {showNoAccessPage
                            ? <InternalForbiddenPage rounded/>
                            : showNotFoundError ? (
                                <InternalNotFoundPage rounded/>
                            ) : warning
                                ? (warningPlaceholder)
                                : loading
                                    ? (loadingPlaceholder)
                                    : (children)
                        }
                    </Suspense>
                </ErrorBoundary>
            </div>
        </div>
    )
})
