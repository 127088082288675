import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'
import { CompanyUserGroupInAsset, CompanyUserGroupInAssetGroup } from '../asset'
import { CompanyUserGroupInLedger } from '../ledger'

import { Company } from './Company'
import { CompanyUserInCompanyUserGroup } from './CompanyUserInCompanyUserGroup'

/**
 * Groups list for company
 */
@api('company-user-group')
@model
export class CompanyUserGroup extends Model {
    @field parent_id?:  number
    @field company_id?: number
    @field name?:       string

    @foreign(Company, 'company_id') readonly company: Company

    ledger_permissions:      CompanyUserGroupInLedger[]
    asset_permissions:       CompanyUserGroupInAsset[]
    asset_group_permissions: CompanyUserGroupInAssetGroup[]

    readonly parent?:  CompanyUserGroup
    readonly children: CompanyUserGroup[]
    readonly links:    CompanyUserInCompanyUserGroup[]
}
foreign(CompanyUserGroup, 'parent_id')(CompanyUserGroup.prototype, 'parent')
many(CompanyUserGroup, 'parent_id')(CompanyUserGroup, 'children')
many(CompanyUserGroup, 'company_id')(Company, 'company_user_groups')
