import { useEffect, useMemo, useState } from 'react'

import { XAND as AND, XEQ as EQ, XILIKE as ILIKE, XIN as IN, StringInput, NumberInput, ArrayStringInput } from 'mobx-orm'

import { observer } from 'mobx-react-lite'
import { useNavigate, useParams } from 'react-router'

import { BigModal, Button, FiltersContainer, Text, TextInput } from '@/components/base'
import { Layout } from '@/components/containers'
import { DistinctSelectInput } from '@/components/legacy/models/core/inputs/DistinctSelectInput'
import { isValidationError } from '@/http.service'
import { Asset } from '@/models/asset'
import {
    TrialBalanceTemplate,
    TrialBalanceTemplateBinding,
} from '@/models/trial_balance_template'
import {
    MappingTemplateApplyConfirmModal,
    MappingTemplateApplyTable,
} from '@/pages/MainPage/UserPages/MappingPage/MappingTemplatesPage/MappingTemplateEditPage'
import {
    MappingTemplateApplyCloseWarningModal,
} from '@/pages/MainPage/UserPages/MappingPage/MappingTemplatesPage/MappingTemplateEditPage/MappingTemplateApplyModal/MappingTemplateApplyCloseWarningModal'
import { notify } from '@/utils/notify'
import { getRoute } from '@/utils/routing/getRoute'

/**
 * @todo Move modal hardcode to the separate component
 */
export const MappingTemplateApplyModal = observer(() => {
    const { templateId } = useParams()
    const navigate = useNavigate()

    const [showCloseConfirmModal, setShowCloseConfirmModal] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [isSaving, setIsSaving] = useState(false)

    const template = useMemo(() => TrialBalanceTemplate.get(Number(templateId)) as TrialBalanceTemplate, [templateId])

    const searchByNameInput = useMemo(() => new StringInput({}), [])
    const ledgerInput = useMemo(() => new NumberInput({ value: Number(template.ledger_id) }), [])
    const templateIdInput = useMemo(() => new NumberInput({ value: Number(templateId) }), [])
    const managerInput = useMemo(() => new ArrayStringInput({
        syncURL: 'manager',
        options: Asset.getQueryXDistinct('property_manager_company', {
            filter: EQ('trial_balances__ledger_id', ledgerInput),
            autoupdate: true,
        }),
        autoReset: (input) => {
            input.set(input.value)
        },
    }), [])

    const assetQuery = useMemo(() => Asset.getQueryXPage({
        filter: AND(
            EQ('trial_balances__ledger_id', ledgerInput),
            ILIKE('name', searchByNameInput),
            IN('property_manager_company', managerInput),
        ),
        autoupdate: true,
    }), [])

    const trialBalanceTemplateBindingQuery = useMemo(() =>
        TrialBalanceTemplateBinding.getQueryX({
            filter: EQ('template_id', templateIdInput),
            autoupdate: true,
        }), [])

    // need to reset cache, before get new data
    useEffect(() => {
        TrialBalanceTemplateBinding.clearCache()
    }, [])

    const [bindings, setBindings] = useState(new Set<number>())

    const saveBindings = async () => {
        let saved = false
        setIsSaving(true)
        try {
            await Promise.all(assetQuery.items.map(
                (asset) => (
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
                    bindings.has(asset.id)
                        ? TrialBalanceTemplateBinding.enable(template, asset)
                        : TrialBalanceTemplateBinding.disable(template, asset)
                ),
            ))
            saved = true
        } catch (error) {
            if (error.isAxiosError && isValidationError(error) && error.response.data.non_field_errors?.length) {
                notify.error(error.response.data.non_field_errors[0])
            } else {
                notify.error('Got unexpected error when saving selected assets.')
            }
            await trialBalanceTemplateBindingQuery.load()
        }
        setIsSaving(false)
        return saved
    }

    const onCloseConfirmModal = () => {
        setShowCloseConfirmModal(true)
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        navigate(getRoute('ASSET_MAPPING_TEMPLATES_BY_ID', { templateId }))
    }

    const handleCloseClick = () => {
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        navigate(getRoute('ASSET_MAPPING_TEMPLATES_BY_ID', { templateId }))
    }

    const applyHintText = template.activeBindings.length
        ? `Selected: ${template.activeBindings.length} asset`
        : 'Select at least one asset to apply the template to it'

    return (
        <>
            <BigModal
                onOverlayClick={handleCloseClick}
                loading={!trialBalanceTemplateBindingQuery.is_ready || isSaving}
                open={!showConfirmModal && !showCloseConfirmModal}
            >
                <Text text='Select All Assets you want to apply the template to' variant='bodySemiboldDefault' color='colorsPrimaryGrey'/>
                <FiltersContainer gap={16} hideTitle={false}>
                    <TextInput
                        value={searchByNameInput.value}
                        onChange={(e) => searchByNameInput.set(e.target.value ?? undefined)}
                        placeholder='Search by Name'
                        icon='search'
                        maxWidth='399px'
                    />
                    <DistinctSelectInput
                        input={managerInput}
                        placeholder='Manager'
                        multiSelectPlaceholder='Managers'
                        multiSelect
                        selectAll
                        selectAllPlaceholder='All Managers'
                    />
                </FiltersContainer>
                <MappingTemplateApplyTable
                    template={template}
                    assets={assetQuery}
                    onChange={changedBindings => setBindings(changedBindings)}
                />
                <Layout align='center' gap={8} justify='space-between'>
                    <Button
                        text='Cancel'
                        theme='secondary'
                        onClick={handleCloseClick}
                    />
                    <Layout align='center' gap={8} justify='flex-end'>
                        <Text text={applyHintText} variant='labelMediumDefault' color='colorsSecondaryGrey400'/>
                        <Button
                            text='Save Selected Assets'
                            onClick={() => saveBindings()}
                        />
                        <Button
                            text='Apply Template'
                            onClick={async () => {
                                if (await saveBindings()) {
                                    setShowConfirmModal(true)
                                }
                            }}
                            disabled={!template.activeBindings.length && !bindings.size}
                        />
                    </Layout>
                </Layout>
            </BigModal>
            {showConfirmModal && (
                <MappingTemplateApplyConfirmModal
                    template={template}
                    onClose={() =>
                        setShowConfirmModal(false)
                    }
                    onConfirm={handleCloseClick}
                />
            )}
            {showCloseConfirmModal && (
                <MappingTemplateApplyCloseWarningModal
                    onConfirm={() => setShowCloseConfirmModal(false)}
                    onCancel={onCloseConfirmModal}
                />
            )}
        </>
    )
})
