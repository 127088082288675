import { useEffect, useState } from 'react'

import { EQ, Query, ValueType } from 'mobx-orm'

import { observer } from 'mobx-react-lite'
import { useParams } from 'react-router'

import { Loader, UserLabel } from '@/components/base'
import { ObjectFormLegacy } from '@/components/legacy/models/ObjectFormLegacy'
import { useMe } from '@/hooks/core/useMe'
import { AssetNote } from '@/models/asset'
import { isQueriesReady } from '@/utils/models'

import { AssetNoteComment } from './AssetNoteComment'
import { AssetNotesProps } from './AssetNotes.types'

import styles from './AssetNotes.module.scss'

export const AssetNotes = observer((props: AssetNotesProps) => {
    const {
        className,
        readOnly = false,
    } = props

    const { me } = useMe()

    const { assetId } = useParams()

    const baseAssetNoteParams = {
        asset_id: assetId,
        user_id: me.user.id,
    }

    // It works only edit modal, because create modal doesn't have assetId
    // TODO: Make it work for create modal
    const [assetNotesQuery] = useState(() =>
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        AssetNote.getQuery({ filter: EQ('asset_id', parseInt(assetId), ValueType.NUMBER) }) as Query<AssetNote>,
    )

    const [newNoteObj, setNewNoteObj] = useState(() => new AssetNote(baseAssetNoteParams))

    useEffect(() => {
        assetNotesQuery.load()
    }, [])

    if (!isQueriesReady(assetNotesQuery)) {
        return <Loader centered/>
    }

    return (
        <div className={className}>
            {!readOnly && (
                <div className={styles.createForm}>
                    <UserLabel
                        name={me.user.fullName}
                        // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'string | un... Remove this comment to see the full error message
                        time={null}
                    />
                    <ObjectFormLegacy
                        noCancelButton
                        spinnerOff
                        createButtonText='Add Note'
                        obj={newNoteObj}
                        fields={new Map([
                            ['text', { type: 'string', placeholder: 'Enter Text', title: '' }],
                        ])}
                        onSubmit={() => {
                            setNewNoteObj(new AssetNote(baseAssetNoteParams))
                        }}
                    />
                </div>
            )}
            {
                Boolean(assetNotesQuery.items.length && !readOnly) && (
                    <div className={styles.delimeter}/>
                )
            }
            <div className={styles.comments}>
                {
                    // Iterate assetNotesQuery.items from last to first
                    assetNotesQuery.items.slice().reverse().map((item, i) => (
                        <AssetNoteComment
                            key={i}
                            // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                            text={item.text}
                            userName={item.user?.fullName ?? 'No Name'}
                            // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                            createdTime={item.timestamp}
                            // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                            userId={item.user_id}
                            // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
                            noteId={item.id}
                        />
                    ))
                }
            </div>
        </div>
    )
})
