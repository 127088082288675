import { useMemo } from 'react'

import {
    autoUpdate,
    useFloating,
    offset as offsetMiddleware,
    flip,
    shift,
    useRole,
    useClick,
    useDismiss,
    useInteractions,
    FloatingFocusManager,
    useTransitionStyles,
    FloatingPortal,
} from '@floating-ui/react'

import { DEFAULT_MAX_WIDTH, DEFAULT_OFFSET, DEFAULT_PLACEMENT } from './Popover.constants'
import { PopoverProps } from './Popover.types'

import styles from './Popover.module.scss'

/**
 * Base component for Selects, Popovers, Cells etc.
 */

export const Popover = (props: PopoverProps) => {
    const {
        children,
        anchorEl,
        placement = DEFAULT_PLACEMENT,
        offset = DEFAULT_OFFSET,
        onClose,
        maxWidth = DEFAULT_MAX_WIDTH,
        minWidth,
    } = props

    const open = Boolean(anchorEl)

    const handleOpenChange = (isOpen: boolean) => {
        !isOpen && onClose()
    }

    const { refs, floatingStyles, context } = useFloating({
        open,
        onOpenChange: handleOpenChange,
        elements: { reference: anchorEl },
        whileElementsMounted: autoUpdate,
        placement,
        middleware: [offsetMiddleware(offset), flip(), shift({ padding: 8 })],
    })

    const { isMounted, styles: transitionStyles } = useTransitionStyles(context)

    const role = useRole(context)
    const click = useClick(context)
    const dismiss = useDismiss(context)

    const { getFloatingProps } = useInteractions([
        role,
        click,
        dismiss,
    ])

    const inlineStyles = useMemo(() => {
        const res = {
            ...floatingStyles,
            maxWidth,
            ...(isMounted ? transitionStyles : {}),
        }

        if (minWidth) {
            res.minWidth = minWidth
        }

        return res
    }, [floatingStyles, maxWidth, isMounted, transitionStyles])

    return open ? (
        <FloatingPortal>
            <FloatingFocusManager context={context}>
                <div
                    className={styles.popover}
                    style={inlineStyles}
                    ref={refs.setFloating}
                    data-testid='popover'
                    {...getFloatingProps()}
                >
                    {children}
                </div>
            </FloatingFocusManager>
        </FloatingPortal>
    ) : null
}
