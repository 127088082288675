import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Icon, Text } from '@/components/base'

import { LegacySelectItemProps } from './LegacySelectItem.types'

import styles from './LegacySelectItem.module.scss'

export const LegacySelectItem = observer((props: LegacySelectItemProps) => {
    const { className, active, onClick, data, dataTestId } = props

    const label = data.label.toString()

    return (
        <div
            className={cn(styles.selectItem, className)}
            onClick={onClick}
            data-testid={dataTestId}
        >
            <Text
                text={label}
                variant='smallTextMediumDefault'
                color={active ? 'primaryPurple' : 'secondaryBlack'}
                elementTitle={label}
                whiteSpace='nowrap'
                overflow='hidden'
                textOverflow='ellipsis'
            />
            <Icon
                className={styles.icon}
                name='check'
                // White color for no active for save space if no items selected
                color={active ? 'colorsPrimaryPurple' : 'colorsPrimaryWhite'}
            />
        </div>
    )
})
