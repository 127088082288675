import { useEffect, useState } from 'react'

import axios from 'axios'

import { useAuth0 } from '@auth0/auth0-react'
import * as Sentry from '@sentry/react'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { ContentWithMenu } from '@/components/containers/ContentWithMenu'
import { COMMON_ROUTES_CONFIG } from '@/core/routes'
import { useMe } from '@/hooks/core/useMe'
import { isAuthError } from '@/http.service'

import { AuthContainerProps } from './AuthContainer.types'

/**
 * Components which validates user auth and set request header
 */
export const AuthContainer = observer((props: AuthContainerProps) => {
    const { children } = props

    const navigate = useNavigate()

    const { me, logout, login } = useMe()

    const [isAuthReady, setIsAuthReady] = useState(false)
    const { isAuthenticated, isLoading, getAccessTokenSilently } = useAuth0()

    useEffect(() => {
        if (!me.hasAccessToPortal && me.isReady) {
            const redirectUrl = COMMON_ROUTES_CONFIG.NO_ACCESS_TO_PORTAL.path

            if (redirectUrl) {
                navigate(redirectUrl)
            } else {
                logout()
            }
        }
    }, [me.hasAccessToPortal, me.isReady])

    // Set interceptor for axios
    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            axios.interceptors.request.use(
                async (config) => {
                    // Not doing request here
                    const accessToken = await getAccessTokenSilently({
                        authorizationParams: {
                            audience: process.env.MFA_AUDIENCE,
                        },
                    })

                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    config.headers.Authorization = `Bearer ${accessToken}`

                    return config
                })

            axios.interceptors.response.use((response) => {
                return response
            }, (error) => {
                // Falback for auth error from our back
                if (isAuthError(error)) {
                    logout()
                    login()
                    return
                }
                throw error
            })

            // TODO: Remove after this task: WEBDEV-1004
            Sentry.configureScope(scope => {
                scope.setExtra('authInterceptorsSetTime', new Date().toISOString())
            })

            setIsAuthReady(true)
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (!isLoading && !isAuthenticated) {
            login()
        }
    }, [isLoading, isAuthenticated])

    if (!isAuthReady) {
        // Show empty wrapper as a fallback
        return <ContentWithMenu menu={null} content={null} loading/>
    }

    return children
})
