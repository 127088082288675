import { useContext, useEffect, useState } from 'react'

import { ValueType } from 'mobx-orm'

import { observer } from 'mobx-react-lite'

import { LegacySelect, LegacySelectItemData } from '@/components/base'
import { ModelFormContext } from '@/components/legacy/models/core/ModelFromContext'
import { TrialBalanceLedger } from '@/models/ledger'
import { ModelInput } from '@/utils/mobx-orm/ModelInput'

import { TrialBalanceLedgerInputProps } from './TrialBalanceLedgerInput.types'

/**
* @deprecated
*/
export const TrialBalanceLedgerInput = observer((props: TrialBalanceLedgerInputProps) => {
    const {
        obj, field = 'ledger_id',
        filter, options,
        syncURL, syncLocalStorage,
        label = 'Ledger',
        placeholder = 'Choose Ledger...',
        onChange,
        ...rest
    } = props
    const modelForm = useContext(ModelFormContext)
    const [modelInput] = useState(() => new ModelInput({
        obj: obj || modelForm.obj,
        field,
        filter,
        options,
        syncURL: syncURL || modelForm.syncURL,
        syncLocalStorage: syncLocalStorage || modelForm.syncLocalStorage,
        type: ValueType.NUMBER,
    }))

    // destructore
    useEffect(() => {
        return () => {
            modelInput.destroy()
        }
    }, [])

    const onChangeHandler = (option: LegacySelectItemData) => {
        modelInput.set(option.value)
        onChange?.(option)
    }

    return (
        <>
            <LegacySelect
                {...rest}
                label={label}
                // @ts-expect-error TS(2322) FIXME: Type '{ label: string | undefined; value: number |... Remove this comment to see the full error message
                options={modelInput.options.items.map((ledger: TrialBalanceLedger) => ({ label: ledger.name, value: ledger.id }))}
                customValue={modelInput.value}
                placeholder={modelInput.options.is_loading ? 'loading' : placeholder}
                onChange={onChangeHandler}
                error={modelInput.obj?.__errors?.[field]}
                hint={modelInput.obj?.__errors?.[field]}
            />
        </>
    )
})
