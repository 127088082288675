import React, { useState, useRef, FC } from 'react'

import Dialog from '@mui/material/Dialog'
import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { Button, Loader } from '@/components/base'

import { ObjectDeletePopupProps } from './ObjectDeletePopup.types'

import styles from './ObjectDeletePopup.module.scss'

const ObjectDeletePopup: FC<ObjectDeletePopupProps> = observer((props) => {
    const { obj, onClose, title, warningText, warningBodyComponent, closePath, onDeleted } = props

    const [isLoading, setIsLoading] = useState(false)

    const bodyRef = useRef<any>()
    const navigate = useNavigate()

    const handleClose = () => {
        onClose?.()
        closePath && (navigate(closePath))
    }

    const deleteObject = async () => {
        let deleted = false

        if (bodyRef.current && bodyRef.current.checkDelete() === false) {
            return
        }
        try {
            setIsLoading(true)
            await obj.delete()
            onDeleted?.()
            deleted = true
        } catch (err) {
            console.error('DELETION ERROR', err)
        } finally {
            setIsLoading(false)
        }

        onClose?.(deleted)
        closePath && (navigate(closePath))
    }

    return (
        <Dialog
            open
            style={{ backgroundColor: 'rgb(92, 92, 229, 0.05)' }}
            classes={{ paper: styles.dialogPaper }}
            onClose={handleClose}
        >
            <div className={styles.objectForm}>
                {
                    isLoading &&
                    <div className={styles.loadingOverlay}><div><Loader/></div></div>
                }
                {
                    title &&
                    <div className={styles.title}>{title}</div>
                }
                <div className={styles.warningText}>
                    {warningText}
                    {
                        warningBodyComponent &&
                        React.createElement(warningBodyComponent, { ref: bodyRef })
                    }
                </div>
                <div className={styles.buttons}>
                    <Button
                        theme='secondary'
                        onClick={handleClose}
                        text='Cancel'
                    />
                    <Button
                        text='Delete'
                        onClick={deleteObject}
                        theme='destructive'
                    />
                </div>
            </div>
        </Dialog>
    )
})

export default ObjectDeletePopup
