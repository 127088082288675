import { AxiosResponse } from 'axios'
import { Model, RawObject, Adapter, ASC, Selector, XFilter } from 'mobx-orm'

import * as Sentry from '@sentry/react'

import http from './http.service'

export class HttpAdapter<M extends Model> extends Adapter<M> {
    readonly endpoint: string

    constructor (model: any, endpoint?: string) {
        super(model)
        this.endpoint = endpoint + '/'
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    async __action (obj_id: number, name: string, kwargs: any): Promise<any> {
        const data = new FormData()
        for (const key in kwargs) {
            if (Object.prototype.hasOwnProperty.call(kwargs, key)) {
                const value = kwargs[key]
                if (value !== null && typeof value === 'object' && !(value instanceof File)) {
                    data.append(key, JSON.stringify(value))
                } else {
                    data.append(key, value)
                }
            }
        }
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        const response = await http.post(`${this.endpoint}${obj_id}/${name}/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return response.data
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    async __create (obj: RawObject): Promise<RawObject> {
        const data = new FormData()
        for (const key in obj) {
            if (Object.prototype.hasOwnProperty.call(obj, key)) {
                const value = obj[key]
                if (value !== null && typeof value === 'object' && !(value instanceof File)) {
                    data.append(key, JSON.stringify(value))
                } else {
                    data.append(key, value)
                }
            }
        }

        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        const response = await http.post(this.endpoint, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return response.data
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    async __update (obj_id: number, only_changed_raw_data: RawObject): Promise<RawObject> {
        const data = new FormData()
        for (const key in only_changed_raw_data) {
            if (Object.prototype.hasOwnProperty.call(only_changed_raw_data, key)) {
                const value = only_changed_raw_data[key]
                if (value !== null && typeof value === 'object' && !(value instanceof File)) {
                    data.append(key, JSON.stringify(value))
                } else {
                    data.append(key, value)
                }
            }
        }
        if (![...data].length) {
            return {}
        }
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        const response = await http.patch(`${this.endpoint}${obj_id}/`, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        return response.data
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    async __delete (obj_id: number): Promise<void> {
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        await http.delete(`${this.endpoint}${obj_id}/`)
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    async __get (obj_id: number): Promise<RawObject> {
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        const raw_obj = await http.get(`${this.endpoint}${obj_id}/`) // eslint-disable-line @typescript-eslint/naming-convention
        return raw_obj.data
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    async __find (): Promise<RawObject> {
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        const raw_obj = await http.get(this.endpoint) // eslint-disable-line @typescript-eslint/naming-convention
        return raw_obj
    }

    // eslint-disable-next-line @typescript-eslint/naming-convention
    async __load (selector, controller?): Promise<RawObject[]> {
        let queryParams = selector.URLSearchParams
        if (!queryParams) {
            queryParams = getFullFilter(selector)
        }
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        const response: AxiosResponse<any> = await http.get(
            this.endpoint + `?${queryParams.toString()}`,
            { signal: controller?.signal },
        )

        // TODO: Remove after this task: WEBDEV-1004
        if (!response) {
            Sentry.configureScope(scope => {
                scope.setExtra('noResponseIn', this.endpoint + `?${queryParams.toString()}`)
                scope.setExtra('noResponseTime', new Date().toISOString())
            })
        }

        return response.data
    }

    async getTotalCount (where?, controller?): Promise<number> {
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        const response = await http.get(
            this.endpoint + 'count/' + (where ? `?${where.URLSearchParams.toString()}` : ''),
            { signal: controller?.signal },
        )
        return response.data
    }

    async getDistinct (where: XFilter, field: string, controller?): Promise<any[]> {
        const searchParams = where ? where.URLSearchParams : new URLSearchParams()
        searchParams.set('__distinct', field)
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        const response = await http.get(
            `${this.endpoint}distinct/?${searchParams.toString()}`,
            { signal: controller?.signal },
        )
        return response.data
    }
}

export const getFullFilter = (selector: Selector): URLSearchParams => {
    const queryParams = selector.filter ? selector.filter.URLSearchParams : new URLSearchParams()
    const order_by = [] // eslint-disable-line @typescript-eslint/naming-convention
    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    for (const field of selector.order_by.keys()) {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        const value = selector.order_by.get(field)
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const _field = field.replace(/\./g, '__')
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        order_by.push(value === ASC ? `${_field}` : `-${_field}`)
    }
    if (order_by.length) queryParams.set('__order_by', order_by.join())
    if (selector.limit !== undefined) queryParams.set('__limit', selector.limit as any)
    if (selector.offset !== undefined) queryParams.set('__offset', selector.offset as any)
    if (selector.relations?.length) queryParams.set('__relations', selector.relations as any)
    if (selector.fields?.length) queryParams.set('__fields', selector.fields as any)
    if (selector.omit?.length) queryParams.set('__omit', selector.omit as any)
    return queryParams
}

// model decorator
export function api (endpoint: string) {
    return (cls: any) => {
        const adapter = new HttpAdapter(cls, endpoint)
        // eslint-disable-next-line no-proto
        cls.__proto__.__adapter = adapter
    }
}
