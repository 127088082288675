import cn from 'classnames'

import { BooleanParam, StringParam } from 'use-query-params'

import { AgGridTableProps } from '@/components/tables'
import {
    CustomMappingHeaderCell,
} from '@/pages/MainPage/UserPages/MappingPage/MappingRentRollPage/CustomMappingHeaderCell'

export const ACCOUNTS_FILTER_QUERY_PARAM = {
    name: 'accounts-search',
    type: StringParam,
    syncLocalStorage: false,
}

export const UNMAPPED_ONLY_QUERY_PARAM = {
    name: 'unmapped-only',
    type: BooleanParam,
    syncLocalStorage: false,
}

export const getMappingsTableGridOptions = ({ selectAccountCodeCellRenderer }): Omit<AgGridTableProps, 'items'> => ({
    rowHeight: 33,
    headerHeight: 33,
    defaultColDef: {
        resizable: true,
        headerClass: 'ag-grid-header',
        suppressHeaderMenuButton: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        headerComponentParams: {
            menuIcon: 'fa-bars',
        },
    },
    columnDefs: [
        {
            headerName: '',
            rightBorder: true,
            children: [
                {
                    field: 'asset_name',
                    headerName: 'Asset',
                    type: 'string',
                    flex: 1,
                    unSortIcon: true,
                    headerClass: 'ledger-border',
                    cellClass: 'ledger-border',
                },
            ],
        },
        {
            headerName: 'SOURCE',
            headerClass: cn('ledger-border'),
            rightBorder: true,
            children: [
                {
                    field: 'code',
                    headerName: 'Code',
                    type: 'string',
                    flex: 1,
                    unSortIcon: true,
                },
            ],
        },
        {
            headerGroupComponent: CustomMappingHeaderCell,
            children: [
                {
                    field: 'custom_account_code',
                    headerName: 'Code',
                    type: 'string',
                    flex: 1,
                    unSortIcon: true,
                    cellRenderer: selectAccountCodeCellRenderer,
                } as any,
            ],
        },
    ],
})
