import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Button, Text } from '@/components/base'
import { ElementsPerPageSelect } from '@/components/legacy/tables/AgGridTableLegacy/components/ElementsPerPageSelect'

import { AgGridTablePaginationRightPanelProps } from './AgGridTableLegacyPaginationRightPanel.types'

import styles from './AgGridTableLegacyPaginationRightPanel.module.scss'

export const AgGridTablePaginationRightPanel = observer((props: AgGridTablePaginationRightPanelProps) => {
    const { className, query } = props

    return (
        <div className={cn(styles.agGridTablePaginationRightPanel, className)}>
            <ElementsPerPageSelect query={query}/>
            <div className={styles.buttonsBox}>
                <Button
                    onClick={() => { query.goToPrevPage() }}
                    disabled={query.is_first_page}
                    theme='secondary'
                    icon='arrowLeft'
                    className={styles.arrowButton}
                    size='s'
                />
                <Text variant='labelMediumDefault' color='secondaryBlack'>{query.current_page} / {query.total_pages}</Text>
                <Button
                    onClick={() => { query.goToNextPage() }}
                    disabled={query.is_last_page}
                    theme='secondary'
                    icon='arrowRight'
                    className={styles.arrowButton}
                    size='s'
                />
            </div>
        </div>
    )
})
