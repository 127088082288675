import { ChangeEvent, FocusEvent, forwardRef, LegacyRef, useEffect, useRef, useState } from 'react'

import cn from 'classnames'

import { Icon } from '@/components/base'
import { InputBox } from '@/components/containers'
import { mixpanelTrack } from '@/utils/mixpanel'

import { TextInputProps } from './TextInput.types'

import styles from './TextInput.module.scss'

export const TextInput = forwardRef((props: TextInputProps, ref: LegacyRef<HTMLInputElement>) => {
    const {
        onChange,
        onFocus,
        onBlur,
        onClick,
        onKeyDown,
        onIconClick,
        value,
        size = 's',
        placeholder,
        icon,
        iconPos,
        label,
        hint,
        error = false,
        className,
        dataTestId,
        disabled = false,
        readonly = false,
        borderless = false,
        labelInside = false,
        maxWidth,
        maxLength,
        width = '100%',
        rotateIcon = false,
        flexGrow,
        minWidth,
        endEl = null,
    } = props

    const [isHovered, setIsHovered] = useState(false)
    const [isFocused, setIsFocused] = useState(false)
    const containerRef = useRef<HTMLDivElement>(null)

    const classNames = cn(styles.textInput, className)

    const containerClassNames = cn(styles.container, {
        [styles.container_iconPos_end]: iconPos === 'end',
    })

    const iconClassNames = cn(styles.icon, {
        [styles.iconRotate]: rotateIcon,
    })

    const isInputEventSent = useRef<boolean>(false)

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value

        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        if (!maxLength || (newValue.length <= maxLength) || (newValue.length < value.length)) {
            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onChange(e)
        }

        // Sent event once
        if (!isInputEventSent.current) {
            mixpanelTrack('Input Change', {
                label,
            })
            isInputEventSent.current = true
        }
    }
    const handleMouseEnter = () => {
        if (disabled) {
            return
        }

        setIsHovered(true)
    }

    const handleMouseLeave = () => {
        setIsHovered(false)
    }

    const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(true)
        onFocus?.(e)
    }

    const handleBlur = (e: FocusEvent<HTMLInputElement>) => {
        setIsFocused(false)
        onBlur?.(e)
    }

    const handleIconClick = onIconClick && (
        (event: React.MouseEvent) => {
            event.stopPropagation()
            event.preventDefault()
            onIconClick(event)
        }
    )

    /**
     * Fixing the date input bug on Windows 10 chrome
     * @see: WEBDEV-2105
     */
    useEffect(() => {
        if (!containerRef.current) return

        const gap = 12
        const iconWidth = icon ? 20 : 0
        const containerWidth = containerRef.current?.clientWidth
        const input = containerRef.current.querySelector('input')
        const inputWidth = containerWidth - iconWidth - gap
        if (input) {
            input.style.maxWidth = `${inputWidth}px`
        }
    }, [ref, containerRef])

    return (
        <InputBox
            disabled={disabled}
            label={label}
            hint={hint}
            error={error}
            maxWidth={maxWidth}
            onClick={onClick}
            className={classNames}
            size={size}
            borderless={borderless}
            labelInside={labelInside}
            width={width}
            minWidth={minWidth}
            flexGrow={flexGrow}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            <div className={containerClassNames} ref={containerRef}>
                {icon && (
                    <Icon
                        name={icon}
                        className={iconClassNames}
                        color={isHovered || isFocused ? 'colorsPrimaryPurple' : 'colorsSecondaryGrey600'}
                        onClick={handleIconClick}
                    />
                )}
                <input
                    ref={ref}
                    type='text'
                    className={styles.input}
                    placeholder={placeholder}
                    disabled={disabled}
                    value={value ?? ''}
                    onChange={handleChange}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                    onKeyDown={onKeyDown}
                    readOnly={readonly}
                    data-testid={dataTestId}
                />
                {endEl}
            </div>
        </InputBox>
    )
})
