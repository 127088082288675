import { useContext, useState } from 'react'

import { observer } from 'mobx-react-lite'

import { ButtonGroup } from '@/components/base'
import { ModelInput } from '@/utils/mobx-orm'

import { ModelFormContext } from '../../ModelFromContext'

import { ShowEmptyRowsInputProps } from './ShowEmptyRowsInput.types'

// TODO: remove hide/show keys, it should be boolean
export const ShowEmptyRowsInput = observer((props: ShowEmptyRowsInputProps) => {
    const { filter, syncURL, syncLocalStorage, label = 'Empty Rows' } = props
    const modelForm = useContext(ModelFormContext)

    const [modelInput] = useState(() => new ModelInput({
        filter,
        syncURL: syncURL || modelForm.syncURL,
        syncLocalStorage: syncLocalStorage || modelForm.syncLocalStorage,
    }))

    return (
        <ButtonGroup
            label={label}
            options={[
                { title: 'Hide', key: 'hide' },
                { title: 'Show', key: 'show' },
            ]}
            selected={modelInput.value ? 'show' : 'hide'}
            onSelect={(key) => modelInput.set(key === 'show' ? true : undefined)}
        />
    )
})
