import { forwardRef, LegacyRef } from 'react'

import cn from 'classnames'

import { textColors } from '@/components/base/Text/Text.constants'

import { TextProps } from './Text.types'

import styles from './Text.module.scss'

/**
 * Text
 */
export const Text = forwardRef((props: TextProps, ref?: LegacyRef<HTMLSpanElement>) => {
    const {
        children,
        text,
        variant = 'mediumDefault',
        color = 'secondaryBlack',
        className,
        block,
        whiteSpace,
        overflow,
        textOverflow,
        maxLines,
        style = {},
        elementTitle,
        dataTestId,
        centered,
        id,
        alignRight,
        alignCenter,
    } = props

    const textClassName = cn(styles.text, className, {
        [styles[`text_variant_${variant}`]]: variant,
        [styles.block]: block,
        [styles[`whiteSpace_${whiteSpace}`]]: whiteSpace,
        [styles[`overflow_${overflow}`]]: overflow,
        [styles[`textOverflow_${textOverflow}`]]: textOverflow,
        [styles.maxLines]: maxLines,
        [styles.centered]: centered,
        [styles.alignRight]: alignRight,
        [styles.alignCenter]: alignCenter,
    })

    const styleModified = {
        ...style,
        WebkitLineClamp: maxLines,
        color: textColors[color],
    }

    return (
        <span
            className={textClassName}
            ref={ref}
            style={styleModified}
            title={elementTitle}
            data-testid={dataTestId}
            id={id}
        >
            {text ?? children}
        </span>
    )
})
