import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { HeaderNavigation } from '@/components/base'
import { headerNavigationItems } from '@/store/headerNavigationItems'

import { HeaderProps } from './Header.types'
import { HeaderMenu } from './HeaderMenu'

import styles from './Header.module.scss'

export const Header = observer((props: HeaderProps) => {
    const { className } = props

    return (
        <div className={cn(styles.header, className)}>
            <div className={styles.leftContainer}>
                {
                    Boolean(headerNavigationItems.items.length) && (
                        <HeaderNavigation
                            items={headerNavigationItems.items}
                            baseRoute={headerNavigationItems.baseRoute}
                        />
                    )
                }
            </div>
            <HeaderMenu/>
        </div>
    )
})
