import { Model, model, field, foreign } from 'mobx-orm'

import { api } from '../../http-adapter'
import { Asset } from '../asset/Asset'

import { Account } from './Account'
import { RentRollLedger as Ledger } from './Ledger'
import { SourceAccountMap } from './SourceAccountMap'

@api('rent-roll-source-account')
@model
export class SourceAccount extends Model {
    @field asset_id?: number
    @field code?:     string
    @field name?:     string

    readonly maps: SourceAccountMap[]

    @foreign(Asset, 'asset_id') readonly asset: Asset

    // @ts-expect-error TS(2366) FIXME: Function lacks ending return statement and return ... Remove this comment to see the full error message
    getSourceAccountMap (activeLedger: Ledger): SourceAccountMap {
        // @ts-expect-error TS(2322) FIXME: Type 'undefined' is not assignable to type 'Source... Remove this comment to see the full error message
        if (!activeLedger) return
        for (const account_map of this.maps) { // eslint-disable-line @typescript-eslint/naming-convention
            if (account_map?.account?.ledger_id === activeLedger.id) {
                return account_map
            }
        }
    }

    getAccount (activeLedger: Ledger): Account {
        return this.getSourceAccountMap(activeLedger).account
    }
}
