import { CSSProperties } from 'react'

import cn from 'classnames'

import { IconProps, IconComponentByNameList, iconColors } from './Icon.types'
import AdminCenter from './icons/adminCenter.svg'
import AiSparklesIcon from './icons/AiSparkles.svg'
import ArrowDownIcon from './icons/arrowDown.svg'
import ArrowLeftIcon from './icons/arrowLeft.svg'
import ArrowRightIcon from './icons/arrowRight.svg'
import ArrowUpIcon from './icons/arrowUp.svg'
import Asset from './icons/asset.svg'
import Builder from './icons/builder.svg'
import CalendarIcon from './icons/calendar.svg'
import ChartOfAccounts from './icons/chartOfAccounts.svg'
import CheckIcon from './icons/check.svg'
import CollapseIcon from './icons/collapse.svg'
import CopyIcon from './icons/copy.svg'
import CrossIcon from './icons/cross.svg'
import CsvIcon from './icons/csv.svg'
import DeactivatedUserIcon from './icons/deactivatedUser.svg'
import DeleteIcon from './icons/delete.svg'
import DialogIcon from './icons/dialog.svg'
import DialogArrowLeft from './icons/dialogArrowLeft.svg'
import DocuentIcon from './icons/document.svg'
import Download from './icons/download.svg'
import DragIcon from './icons/drag.svg'
import EditIcon from './icons/edit.svg'
import ExpandIcon from './icons/expand.svg'
import EyeIcon from './icons/eye.svg'
import EyeWithCheckIcon from './icons/eyeWithCheck.svg'
import Folder from './icons/folder.svg'
import Gear from './icons/gear.svg'
import GridIcon from './icons/grid.svg'
import Info from './icons/info.svg'
import Insights from './icons/insights.svg'
import InsightsGoDown from './icons/insightsGoDown.svg'
import InsightsGoUp from './icons/insightsGoUp.svg'
import Loader from './icons/loader.svg'
import LogoutIcon from './icons/logout.svg'
import MailIcon from './icons/mail.svg'
import MoreHorizontal from './icons/moreHorizontal.svg'
import MoreVertical from './icons/moreVertical.svg'
import MinusIcon from './icons/munus.svg'
import NoteIcon from './icons/noteIcon.svg'
import OneColIcon from './icons/oneCol.svg'
import PencilIcon from './icons/pencil.svg'
import Plus from './icons/plus.svg'
import PlusCircle from './icons/plusCircle.svg'
import Portfolio from './icons/portfolio.svg'
import ProfileIcon from './icons/profile.svg'
import RefreshIcon from './icons/refresh.svg'
import ReplaceIcon from './icons/replace.svg'
import RotateIcon from './icons/rotate.svg'
import RowsIcon from './icons/rows.svg'
import SaveIcon from './icons/save.svg'
import Search from './icons/search.svg'
import SidemodalIcon from './icons/sidemodal.svg'
import StickyNoteIcon from './icons/stickyNote.svg'
import StopIcon from './icons/stop.svg'
import ThreeColsIcon from './icons/threeCols.svg'
import TreeMove from './icons/treeMove.svg'
import TreeNewSubcategory from './icons/treeNewSubcategory.svg'
import TreeRename from './icons/treeRename.svg'
import TwoColsIcon from './icons/twoCols.svg'
import UserIcon from './icons/user.svg'
import UsersIcon from './icons/users.svg'
import UserSettingsIcon from './icons/userSettings.svg'
import WaringIcon from './icons/waring.svg'
import WarningIcon from './icons/warning.svg'

import styles from './Icon.module.scss'

// Add new icons to the end for better testing diff
const ICON_COMPONENTS: IconComponentByNameList = {
    delete: DeleteIcon,
    check: CheckIcon,
    edit: EditIcon,
    warning: WarningIcon,
    waring: WaringIcon,
    user: UserIcon,
    eye: EyeIcon,
    eyeWithCheck: EyeWithCheckIcon,
    cross: CrossIcon,
    refresh: RefreshIcon,
    save: SaveIcon,
    users: UsersIcon,
    logout: LogoutIcon,
    profile: ProfileIcon,
    deactivatedUser: DeactivatedUserIcon,
    replace: ReplaceIcon,
    userSettings: UserSettingsIcon,
    arrowDown: ArrowDownIcon,
    arrowUp: ArrowUpIcon,
    arrowRight: ArrowRightIcon,
    arrowLeft: ArrowLeftIcon,
    portfolio: Portfolio,
    builder: Builder,
    adminCenter: AdminCenter,
    info: Info,
    search: Search,
    gear: Gear,
    folder: Folder,
    insights: Insights,
    download: Download,
    dialogArrowLeft: DialogArrowLeft,
    moreHorizontal: MoreHorizontal,
    moreVertical: MoreVertical,
    asset: Asset,
    insightsGoDown: InsightsGoDown,
    insightsGoUp: InsightsGoUp,
    treeNewSubcategory: TreeNewSubcategory,
    treeMove: TreeMove,
    treeRename: TreeRename,
    chartOfAccounts: ChartOfAccounts,
    loader: Loader,
    plus: Plus,
    plusCircle: PlusCircle,
    sidemodal: SidemodalIcon,
    expand: ExpandIcon,
    collapse: CollapseIcon,
    drag: DragIcon,
    calendar: CalendarIcon,
    pencil: PencilIcon,
    copy: CopyIcon,
    note: NoteIcon,
    rows: RowsIcon,
    minus: MinusIcon,
    mail: MailIcon,
    oneCol: OneColIcon,
    twoCols: TwoColsIcon,
    threeCols: ThreeColsIcon,
    stop: StopIcon,
    dialog: DialogIcon,
    AiSparkles: AiSparklesIcon,
    document: DocuentIcon,
    grid: GridIcon,
    csv: CsvIcon,
    stickyNote: StickyNoteIcon,
    rotate: RotateIcon,
}

/**
 * Component returns Icon by name and allows to add extra parameters to it
 */
export const Icon = (props: IconProps) => {
    const {
        name,
        style = {},
        className,
        color = null, // Color is inherited from parent by default
        width,
        height,
        onClick,
        dataTestId,
        size,
        ariaHidden = false,
    } = props

    const iconStyle: CSSProperties = { ...style }
    let iconSize = size

    // If no sizes than use default
    if (!width && !height && !iconSize) {
        iconSize = 'm'
    }

    width && (iconStyle.width = width)
    height && (iconStyle.height = height)
    color && (iconStyle.color = iconColors[color])

    // TODO: Stop using '.icon' classname here and get all external styles only through 'className' prop only
    const iconClassName = cn('icon', styles.icon, className, {
        [styles['size_' + iconSize]]: iconSize,
        [styles.clickable]: Boolean(onClick),
    })

    const IconComponent = ICON_COMPONENTS[name]

    return (
        <IconComponent
            className={iconClassName}
            style={iconStyle}
            onClick={onClick}
            data-testid={dataTestId}
            aria-hidden={ariaHidden}
        />
    )
}
