import { action } from 'mobx'
import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'
import { CompanyUser } from '../company'
import { TrialBalanceLedger } from '../ledger'

import { TrialBalanceTemplate } from './TrialBalanceTemplate'

export enum TrialBalanceTemplateUploadStatus {
    // Note: there are no UPLOADING and VALIDATING statuses because uploading file ends with VALID status
    UPLOADED = 'U', // the file was uploaded
    VALID    = 'V', // the file was validated
    CHECKING = 'C', // ledger was selected and we are checking compatibility
    READY    = 'R', // the ledger is good and we are ready to apply
    APPLYING = 'A',
    DONE     = 'D', // the file was applied to the mapping template
    ERROR    = 'E',
}

export enum LogType {
    TEXT    = 0,
    INFO    = 1,
    WARNING = 2,
    ERROR   = 3,
}

export interface TemplateUploadLog { level: LogType, text: string }

@api('trial-balance-template-upload')
@model
export class TrialBalanceTemplateUpload extends Model {
    @field file?:            string | File
    @field original_name?:   string
    @field created_at?:      string
    @field company_user_id?: number
    @field ledger_id?:       number
    @field template_id?:     number
    @field status?:          TrialBalanceTemplateUploadStatus
    @field logs?:            {
        validation: TemplateUploadLog[]
        checking:   TemplateUploadLog[]
        applying:   TemplateUploadLog[]
    }

    @foreign(CompanyUser) readonly company_user:    CompanyUser
    @foreign(TrialBalanceTemplate) readonly ledger: TrialBalanceTemplate

    @action resetFile () {
        this.file = undefined
        this.status = undefined
    }

    // NOTE: you need to poll updates from back to detect the process is finished
    @action async runCheckingCompatibilityWithLedger (ledger: TrialBalanceLedger): Promise<void> {
        this.ledger_id = ledger.id
        await this.save()
    }

    // NOTE: you need to poll updates from back to detect the process is finished
    @action async runApplyingFileToTemplate (template: TrialBalanceTemplate): Promise<void> {
        this.template_id = template.id
        await this.save()
    }
}
many(TrialBalanceTemplateUpload, 'template_id')(TrialBalanceTemplate, 'uploads')
