import React, { useMemo, useState } from 'react'

import { useTrialBalanceLedgerByIdQuery } from '@/api/ledger/trialBalanceLedger'
import { useRentRollLedgerByIdQuery } from '@/api/rentRoll/rentRollLedger'
import { useSourceAccountMapPredictionsQuery } from '@/api/trialBalance/sourceAccountMapPredictions'
import {
    BuiltInSearchInput,
    Button,
    Icon,
    Line,
    Loader,
    SelectOptionProps,
    SelectOptionsGroupTitle,
} from '@/components/base'
import popoverStyles from '@/components/base/Popover/Popover.module.scss'
import { ListHeader } from '@/components/base/Select/headers'
import { SelectOptionBase, SelectOptionWithLabel } from '@/components/base/Select/options'
import { SelectOptionListBase } from '@/components/base/Select/optionsList'
import { Layout, SideModalContainer } from '@/components/containers'
import {
    MappingEngineSourceAccounts,
    MappingEngineSuggest,
    MappingEngineMode,
} from '@/components/widgets/MappingEngineSideModal'
import {
    MAPPING_ENGINE_ICON_BY_MODE,
    MAPPING_ENGINE_MODE_LOCAL_STORAGE_KEY,
    MAPPING_ENGINE_ONE_MODULE_WIDTH,
} from '@/components/widgets/MappingEngineSideModal/MappingEngineSideModal.constants'
import {
    getAccountItemLabel,
    selectOptionsMappingLabelProps,
} from '@/components/widgets/MappingEngineSideModal/MappingEngineSideModal.utils'
import { useMe } from '@/hooks/core/useMe'
import { useLocalStorage } from '@/hooks/useLocalStorage'
import { Account as TrialBalanceAccount } from '@/models/ledger'
import { Account as RentRollAccount } from '@/models/rent_roll'

import { MappingEngineSideModalProps } from './MappingEngineSideModal.types'

type Account = RentRollAccount | TrialBalanceAccount

export const MappingEngineSideModal = (props: MappingEngineSideModalProps) => {
    const { accountType, onChange, accounts, customLedgerId, selectedAccount, onClose, haveMapping, sourceAccountData } = props

    const { me } = useMe()

    const [mode, setMode] = useLocalStorage<MappingEngineMode>(MAPPING_ENGINE_MODE_LOCAL_STORAGE_KEY, MappingEngineMode.twoCols)

    const isTrialBalance = accountType === 'trialBalance'

    const isAiSuggestTurnedOn = Boolean(me.company?.features?.ai_suggestions) && !haveMapping && isTrialBalance
    const isMappedSourceAccountsOn = Boolean(me.company?.features?.mapping_engine_source_accounts)

    // @ts-expect-error TS(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
    const [filterValue, setFilterValue] = useState<string>(null)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'Account | undefined' is not assi... Remove this comment to see the full error message
    const [newSelectedAccount, setNewSelectedAccount] = useState<Account | null>(selectedAccount)
    const [isSaving, setIsSaving] = useState<boolean>(false)

    // @ts-expect-error Won't be fetched if customLedgerId is empty
    const trialBalanceLegerQuery = useTrialBalanceLedgerByIdQuery(customLedgerId, {
        enabled: Boolean(isTrialBalance && customLedgerId),
    })

    // @ts-expect-error Won't be fetched if customLedgerId is empty
    const rentRollLegerQuery = useRentRollLedgerByIdQuery(customLedgerId, {
        enabled: Boolean(!isTrialBalance && customLedgerId),
    })

    const ledgerData = trialBalanceLegerQuery.data || rentRollLegerQuery.data

    const filterRegex = new RegExp(filterValue, 'i')
    const filteredOptions = accounts.filter((option) => !filterValue || filterRegex.test(getAccountItemLabel(option)))

    const sourceAccountMapPredictionsQuery = useSourceAccountMapPredictionsQuery({
        queryParams: {
            // Types are checked in 'enabled' prop
            source_account_id: sourceAccountData?.id as number,
            ledger_id: ledgerData?.id as number,
        },
    }, {
        enabled: isAiSuggestTurnedOn && Boolean(sourceAccountData?.id) && Boolean(ledgerData?.id),
    })

    const handleSave = async () => {
        setIsSaving(true)
        await onChange(newSelectedAccount, Boolean(selectedAccount))
        setIsSaving(false)
    }

    const handleSelect = (account: Account) => {
        const isAlreadySelected = account?.id === newSelectedAccount?.id
        const newValue = isAlreadySelected ? null : account
        setNewSelectedAccount(newValue)
    }

    const handleModeChange = () => {
        const supportedModesOrder: MappingEngineMode[] = [
            MappingEngineMode.oneCol,
        ]

        if (isMappedSourceAccountsOn) {
            supportedModesOrder.push(MappingEngineMode.twoCols)
        }

        const currentModeIndex = supportedModesOrder.indexOf(mode)
        const nextModeIndex = (currentModeIndex + 1) % supportedModesOrder.length
        setMode(supportedModesOrder[nextModeIndex])
    }

    const SelectOption = useMemo(() => (props: SelectOptionProps<Account>) => {
        const suggestedItem = sourceAccountMapPredictionsQuery.data?.find((item) => item.account.id === props.option.id)

        return (
            suggestedItem ? (
                <SelectOptionWithLabel
                    {...selectOptionsMappingLabelProps(suggestedItem)}
                    {...props}
                />
            ) : <SelectOptionBase {...props}/>
        )
    }, [sourceAccountMapPredictionsQuery.data])

    const modeIcon = MAPPING_ENGINE_ICON_BY_MODE[mode]

    const content = (
        <>
            <ListHeader
                content={[
                    // @ts-expect-error TS(2345) FIXME: Argument of type 'Pick<Account, "name" | "code" | ... Remove this comment to see the full error message
                    `Source Account: ${getAccountItemLabel(sourceAccountData) || '—'}`,
                    `Current mapping: ${newSelectedAccount ? getAccountItemLabel(newSelectedAccount) : 'unmapped'}`,
                ]}
                endContent={(
                    <Layout gap={8}>
                        {(modeIcon && isMappedSourceAccountsOn && isTrialBalance) && <Icon name={modeIcon} color='colorsSecondaryGrey600' onClick={handleModeChange}/>}
                        <Icon name='cross' onClick={onClose} color='colorsSecondaryGrey600'/>
                    </Layout>
                )}
            />
            {(isAiSuggestTurnedOn && !!sourceAccountMapPredictionsQuery.data?.length) && (
                <>
                    <SelectOptionsGroupTitle text='Suggested by Intelas'/>
                    <MappingEngineSuggest
                        sourceAccountData={sourceAccountData}
                        selected={newSelectedAccount}
                        onSelect={handleSelect}
                        getLabel={getAccountItemLabel}
                        suggestItems={sourceAccountMapPredictionsQuery.data}
                    />
                    <Line/>
                </>
            )}
            <SelectOptionsGroupTitle
                text={`All Target Accounts${ledgerData?.name ? ' in ' + ledgerData.name : ''}`}
            />
            <BuiltInSearchInput
                onChange={(value) => {
                    setFilterValue(value)
                }}
                value={filterValue}
                placeholder='Search'
                dataTestId='search'
            />
            <Layout flexGrow={1} scroll='y'>
                <SelectOptionListBase<Account>
                    width='100%'
                    optionComponent={SelectOption}
                    options={filteredOptions}
                    selected={newSelectedAccount ? [newSelectedAccount] : []}
                    labelFn={getAccountItemLabel}
                    idFn={(account) => account?.id?.toString() || ''}
                    onChange={handleSelect}
                />
            </Layout>
            <Line/>
            <Layout px={12} py={8} justify='space-between'>
                {(newSelectedAccount?.id !== selectedAccount?.id) && (
                    <Button
                        loading={isSaving}
                        text='Reset'
                        theme='flat'
                        disabled={isSaving}
                        onClick={() => {
                            // @ts-expect-error TS(2345) FIXME: Argument of type 'Account | undefined' is not assi... Remove this comment to see the full error message
                            setNewSelectedAccount(selectedAccount)
                        }}
                    />
                )}
                <Layout flexGrow={1}/>
                <Button
                    disabled={newSelectedAccount?.id === selectedAccount?.id}
                    loading={isSaving}
                    text='Save'
                    onClick={handleSave}
                />
            </Layout>
        </>
    )

    return (
        <SideModalContainer
            onBgClick={onClose}
        >
            <Layout
                gap={4}
                my={4}
                mr={4}
            >
                <Layout
                    className={popoverStyles.popover}
                    direction='column'
                    width={MAPPING_ENGINE_ONE_MODULE_WIDTH}
                    relative
                >
                    {
                        (!sourceAccountMapPredictionsQuery.isFetched && isAiSuggestTurnedOn) ? (
                            <Loader centered/>
                        ) : (
                            content
                        )
                    }
                </Layout>

                {
                    ((mode === MappingEngineMode.twoCols) && isMappedSourceAccountsOn && isTrialBalance) && (
                        <MappingEngineSourceAccounts
                            ledgerData={ledgerData}
                            // @ts-expect-error TS(2322) FIXME: Type 'Account | null' is not assignable to type 'A... Remove this comment to see the full error message
                            selectedAccount={newSelectedAccount}
                        />
                    )
                }
            </Layout>
        </SideModalContainer>
    )
}
