import { IGroupCellRendererCtrl } from '@ag-grid-community/core'
import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { CompanyUser } from '@/models/company'

import styles from './CompanyUserActiveStatusCell.module.scss'

export const CompanyUserActiveStatusCell = observer((props: IGroupCellRendererCtrl) => {
    const company_user: CompanyUser = (props as any).data // eslint-disable-line @typescript-eslint/naming-convention
    return (
        <div className={cn(styles.companyUserActiveStatusCell)}>
            <div className={cn(styles.item, company_user.is_active ? styles.active : styles.inactive)}>
                {company_user.is_active ? 'Active' : 'Inactive'}
            </div>
        </div>
    )
})
