import { Model, model, field, foreign } from 'mobx-orm'

import { api } from '../../http-adapter'

import { Asset } from './Asset'
import { SpaceType } from './SpaceType'

@api('space')
@model
export class Space extends Model {
    @field asset_id?:      number
    @field space_type_id?: number

    @foreign(Asset) readonly asset:          Asset
    @foreign(SpaceType) readonly space_type: SpaceType
}
