import { CompanyUserItem } from '@/api/user/companyUser'
import { CompanyUser } from '@/models/company'
import { User } from '@/models/core'

/**
 * Returns user's full name if it exists, otherwise returns null
 */
export const userFullname = (user: User | CompanyUser | CompanyUserItem | undefined): string | null => {
    if (!user) return null
    return [user?.first_name, user?.last_name].filter(el => Boolean(el)).join(' ').trim() || null
}
