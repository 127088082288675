import { RoutesConfigValue } from '@/core/routes'

export type UploadsRouteConfigKeys =
    'UPLOADS' |
    'UPLOADS_UPLOADS' |
    'UPLOADS_LOGS' |
    'UPLOADS_LOGS_LEGACY' |
    'UPLOADS_LOGS_ERRORS_BY_ID' |
    'UPLOADS_LOGS_ERRORS_BY_ID_LEGACY'

export type UploadsRoutesConfig = {
    [key in UploadsRouteConfigKeys]: RoutesConfigValue
}

export const UPLOADS_ROUTES_CONFIG: UploadsRoutesConfig = {
    UPLOADS: {
        title: 'Uploads',
        path: '/packages/uploads',
    },
    UPLOADS_UPLOADS: {
        title: 'Manual Upload',
        path: '/packages/uploads/uploads',
        metricData: {
            pageViewEventName: 'Submission View',
        },
    },
    UPLOADS_LOGS: {
        title: 'Submission Log',
        path: '/packages/uploads/submission-log',
        featureFlags: ['new_submission_log'],
        metricData: {
            pageViewEventName: 'Submission View',
            pageName: 'Submission Log',
        },
    },
    UPLOADS_LOGS_ERRORS_BY_ID: {
        title: 'Submission Log Errors',
        path: '/packages/uploads/submission-log/:dataPointId/errors',
    },
    UPLOADS_LOGS_LEGACY: {
        title: 'Submission Log',
        path: '/packages/uploads/submission-log-legacy',
        featureFlags: ['legacy_submission_log'],
    },
    UPLOADS_LOGS_ERRORS_BY_ID_LEGACY: {
        title: 'Submission Log Errors',
        path: '/packages/uploads/submission-log-legacy/:submittedFileId/errors',
    },
}
