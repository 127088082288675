import { useEffect, useState, useMemo, useCallback } from 'react'

import { QueryPage } from 'mobx-orm'

import { observer } from 'mobx-react-lite'
import { generatePath, useParams, useNavigate, useOutletContext } from 'react-router'

import { Alert, Confirm, SideModal } from '@/components/base'
import { ModelForm } from '@/components/legacy/models/core/ModelForm/ModelForm'
import { TrialBalanceTemplateUploadFileInput } from '@/components/legacy/models/trial-balance-template/TrialBalanceTemplateUploadFileInput'
import { CompanyUserMode } from '@/core/modes'
import {
    TrialBalanceTemplate,
    TrialBalanceTemplateMap,
    TrialBalanceTemplateUpload, TrialBalanceTemplateUploadStatus,
} from '@/models/trial_balance_template'
import { notify } from '@/utils/notify'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import { EDIT_TEMPLATE_MESSAGES } from '../MappingTemplateEditPage.constants'

import { UPLOAD_TEXT } from './MappingTemplateUploadMappingPage.constants'

import styles from './MappingTemplateUploadMappingPage.module.scss'
const returnPath = getRouteConfig('ASSET_MAPPING_TEMPLATES_BY_ID', CompanyUserMode.Owner).path

export const MappingTemplateUploadMappingPage = observer(() => {
    const navigate = useNavigate()
    const { templateId } = useParams()
    const { templateMapQuery } = useOutletContext<{ templateMapQuery: QueryPage<TrialBalanceTemplateMap> }>()
    const [isPopupOpen, setIsPopupOpen] = useState(false)
    const [templateUpload] = useState(new TrialBalanceTemplateUpload())

    const template = useMemo(() => TrialBalanceTemplate.get(Number(templateId)) as TrialBalanceTemplate, [templateId])
    const goToTemplatePage = useCallback(() => navigate(generatePath(returnPath, { templateId }), { replace: true }), [navigate, templateId])

    useEffect(() => {
        (async () => {
            if (templateUpload.status === TrialBalanceTemplateUploadStatus.VALID) {
                try {
                    await templateUpload.runCheckingCompatibilityWithLedger(template.ledger)
                } catch {}
            } else if (templateUpload?.status === TrialBalanceTemplateUploadStatus.DONE) {
                setTimeout(() => {
                    notify.success(EDIT_TEMPLATE_MESSAGES.changed)
                    templateMapQuery.load()
                    goToTemplatePage()
                })
            }
        })()
    }, [template, templateUpload, templateUpload.status, templateMapQuery, goToTemplatePage])

    const handleClose = () => {
        if (templateUpload.status) {
            setIsPopupOpen(true)
            return
        }
        goToTemplatePage()
    }

    const applyMappings = async () => {
        try {
            await templateUpload.runApplyingFileToTemplate(template)
        } catch {}
    }

    return (
        <>
            {isPopupOpen && (
                <Confirm
                    confirmText='Back to Uploading'
                    onConfirm={() => setIsPopupOpen(false)}
                    cancelText='Close without Saving'
                    onCancel={goToTemplatePage}
                    disableEscapeKeyDown
                    disableOverlayClick
                >
                    <Alert type='warning' content='You have unsaved changes'/>
                </Confirm>
            )}

            <SideModal
                width={528}
                title='Upload a mapping file'
                onClose={handleClose}
                submitButton={{
                    text: 'Add Mappings',
                    disabled: templateUpload?.status !== TrialBalanceTemplateUploadStatus.READY,
                    onClick: applyMappings,
                    loading: templateUpload?.status === TrialBalanceTemplateUploadStatus.APPLYING,
                }}
            >
                <div className={styles.uploadArea}>
                    <ModelForm obj={templateUpload}>
                        <TrialBalanceTemplateUploadFileInput verticalLayout/>
                    </ModelForm>
                </div>
                <div className={styles.uploadText}>{UPLOAD_TEXT}</div>
            </SideModal>
        </>
    )
})
