import cn from 'classnames'

import { Text } from '@/components/base'

import { ChartContainerProps } from './ChartContainer.types'

import styles from './ChartContainer.module.scss'

export const ChartContainer = (props: ChartContainerProps) => {
    const { title, children, className } = props

    return (
        <div className={cn(styles.container, className)}>
            <div className={styles.title}>
                <Text
                    variant='smallTextSemiboldDefault'
                    color='colorsSecondaryGrey600'
                    text={title}
                />
            </div>
            <div className={styles.chart}>
                {children}
            </div>
        </div>
    )
}
