import { useEffect, useMemo, useRef, useState } from 'react'

import format from 'date-fns/format'

import { AgGridReact } from '@ag-grid-community/react'
import { observer } from 'mobx-react-lite'

import { Switch } from '@/components/base'
import { AgGridTableLegacy, AgGridTableProps } from '@/components/legacy/tables/AgGridTableLegacy'
import { SwitchHeaderCell } from '@/components/tables'
import { DATE_FNS_DEFAULT_FORMAT_FULL_DATE, DATE_FNS_DEFAULT_FORMAT_MONTH_SHORT } from '@/constants/dates'
import { INTELAS_ADMIN_USER } from '@/constants/texts'
import { useQuery } from '@/hooks/useQuery'
import http from '@/http.service'
import { CompanyUser } from '@/models/company'
import { dateToMonthInd } from '@/utils/date/monthInd'
import { queryIdsString } from '@/utils/mobx-orm/queryIdsString'
import { userFullname } from '@/utils/mobx-orm/userFullname'

import {
    gridOptions,
} from './UploadsDataSyncTable.constants'
import { UploadsDataSyncTableProps } from './UploadsDataSyncTable.types'

interface TrialBalnceImportStateResponse { last_imported_at: string }

export const UploadsDataSyncTable = observer((props: UploadsDataSyncTableProps) => {
    const { selectedIds, toggleItem, query, date, assetTBSourceReadinessQuery } = props

    const [importedData, setImportedData] = useState<Record<string, TrialBalnceImportStateResponse>>({})
    const [isSelectAllDisabled, setIsSelectAllDisabled] = useState(false)

    const companyUserQuery = useQuery(CompanyUser)

    const assets = query.items

    const tableRef = useRef<AgGridReact>(null)

    useEffect(() => {
        // Need to have all available users in memory for show them in the table
        companyUserQuery.load()
    }, [])

    const isAllChecked = useMemo(() => {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
        return (Boolean(assets.length) && (assets.every(item => selectedIds.includes(item.id)) ?? false))
    }, [selectedIds.join(',')])

    const handleSelectAll = (checked: boolean) => {
        setIsSelectAllDisabled(true)
        assets.forEach(item => toggleItem(item, checked))
        /**
         *  Need to disable select all check box because of many clicks without delay can broke table
         *  @see WEBDEV-821
         */
        setTimeout(() => {
            setIsSelectAllDisabled(false)
        }, 500)
    }

    const handleRowClick = ({ data }) => {
        toggleItem(data)
    }

    const updatedGridOptions: Omit<AgGridTableProps, 'query' | 'items' | 'theme'> = useMemo(() => {
        const switchColumn = {
            headerComponent: SwitchHeaderCell,
            headerComponentParams: {
                onChange: handleSelectAll,
                checked: isAllChecked,
                disabled: isSelectAllDisabled,
            },
            minWidth: 130,
            cellContentAlignVertical: true,
            cellRendererSelector: ({ data }) => {
                return {
                    component: Switch,
                    params: {
                        checked: selectedIds.find(assetId => assetId === data?.id) !== undefined,
                        disabled: isSelectAllDisabled,
                    },
                }
            },
        }

        const dateColumn = {
            headerName: `Latest Sync for ${format(date, DATE_FNS_DEFAULT_FORMAT_MONTH_SHORT)}`,
            minWidth: 200,
            valueGetter: ({ data }) => {
                const selectedMonthInd = dateToMonthInd(date)
                const importedDate = importedData[data?.id]?.last_imported_at
                let dateFormatted = importedDate ? format(new Date(importedDate), DATE_FNS_DEFAULT_FORMAT_FULL_DATE) : 'n/a'
                const isPending = assetTBSourceReadinessQuery.items.find(item => item.asset_id === data?.id && item.month_ind === selectedMonthInd)?.is_ready ?? false

                if (isPending && importedDate) {
                    dateFormatted = `Pending (${dateFormatted})`
                } else if (isPending && !importedDate) {
                    dateFormatted = 'Pending'
                }

                return dateFormatted
            },
        }

        const personColumn = {
            headerName: 'Last Synced by',
            minWidth: 200,
            type: 'string',
            valueGetter: ({ data }) => {
                const userId = assetTBSourceReadinessQuery.items
                    .find(item => (item.asset_id === data?.id && item.month_ind === dateToMonthInd(date)))?.initiated_by

                const user = userId && companyUserQuery?.items?.find(item => item.id === userId)
                const label = (userId && user)
                    // User found. Meands it's not staff
                    ? (userFullname(user) || user.email)
                    // Have id, but user not found. Staff user of user form another company
                    : (userId && !user) ? INTELAS_ADMIN_USER
                        // No any user found
                        : '—'

                return label
            },
        }

        const updated = {
            ...gridOptions,
            columnDefs: [
                switchColumn,
                ...(gridOptions.columnDefs ?? []),
                dateColumn,
                personColumn,
            ],
            onRowClicked: handleRowClick,
        }

        return updated
    }, [
        queryIdsString(query),
        queryIdsString(assetTBSourceReadinessQuery),
        isAllChecked,
        selectedIds.join(','),
        importedData,
        date?.toISOString(),
        isSelectAllDisabled,
    ])

    useEffect(() => {
        (async () => {
            if (!date) return

            const monthInd = dateToMonthInd(date)

            // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            const importState = await http.get<Record<string, TrialBalnceImportStateResponse>>(`/trial-balance-import-state?month_ind=${monthInd}`)

            setImportedData(importState?.data)
        })()
    }, [queryIdsString(query), date?.toISOString()])

    return (
        <AgGridTableLegacy
            ref={tableRef}
            query={query}
            {...updatedGridOptions}
        />
    )
})
