import { useContext } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'
import { useQueryParams } from 'use-query-params'

import { Button, Text } from '@/components/base'
import { ElementsPerPageSelect } from '@/components/legacy/tables/PaginationPane/ElementsPerPageSelect'
import { TableContext } from '@/components/tables/AgGridTable'
import {
    getAgGridLimitQueryParam,
    getAgGridOffsetQueryParam,
} from '@/components/tables/AgGridTable/AgGridTable.constants'
import { findPageByOffset } from '@/utils/findPageByOffset'

import { AgGridTablePaginationRightPanelProps } from './AgGridTablePaginationRightPanel.types'

import styles from './AgGridTablePaginationRightPanel.module.scss'

export const AgGridTablePaginationRightPanel = observer((props: AgGridTablePaginationRightPanelProps) => {
    const { className } = props

    const { totalItemsCount, customOffsetQueryParamKey, customLimitQueryParamKey } = useContext(TableContext)

    // @ts-expect-error TS(2532) FIXME: customLimitQueryParamKey and customOffsetQueryParamKey is possibly 'undefined'.
    const [{ [customLimitQueryParamKey]: tablePageLimit, [customOffsetQueryParamKey]: tablePageOffset }, setQueryParams] = useQueryParams({
        ...getAgGridLimitQueryParam(customLimitQueryParamKey),
        ...getAgGridOffsetQueryParam(customOffsetQueryParamKey),
    })

    const isLastPage = !totalItemsCount || (tablePageOffset + tablePageLimit >= totalItemsCount)
    const isFirstPage = tablePageOffset === 0
    const tablePage = findPageByOffset(tablePageOffset, tablePageLimit)
    // @ts-expect-error TS(2532) FIXME: totalItemsCount is possibly 'undefined'.
    const totalPages = Math.ceil(totalItemsCount / tablePageLimit) || 1

    return (
        <div className={cn(styles.agGridTablePaginationRightPanel, className)}>
            {totalPages > 1 && (
                <ElementsPerPageSelect
                    // @ts-expect-error TS(2532) FIXME: customLimitQueryParamKey is possibly 'undefined'.
                    onPageSizeChanged={(tablePageLimit) => { setQueryParams({ [customLimitQueryParamKey]: tablePageLimit }) }}
                    pageSize={tablePageLimit}
                />
            )}
            <div className={styles.buttonsBox}>
                <Button
                    // @ts-expect-error TS(2532) FIXME: customOffsetQueryParamKey is possibly 'undefined'.
                    onClick={() => { setQueryParams({ [customOffsetQueryParamKey]: tablePageOffset >= tablePageLimit ? tablePageOffset - tablePageLimit : 0 }) }}
                    disabled={isFirstPage}
                    theme='secondary'
                    icon='arrowLeft'
                    className={styles.arrowButton}
                    size='s'
                />
                <Text variant='labelMediumDefault' color='secondaryBlack'>{tablePage} / {totalPages}</Text>
                <Button
                    // @ts-expect-error TS(2532) FIXME: customOffsetQueryParamKey is possibly 'undefined'.
                    onClick={() => { setQueryParams({ [customOffsetQueryParamKey]: tablePageOffset + tablePageLimit }) }}
                    disabled={isLastPage}
                    theme='secondary'
                    icon='arrowRight'
                    className={styles.arrowButton}
                    size='s'
                />
            </div>
        </div>
    )
})
