import { useMemo } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Text } from '@/components/base'
import { Layout } from '@/components/containers'
import { Table } from '@/components/legacy/tables/Table'

import { ChatMessageProps } from './ChatMessage.types'

import styles from './ChatMessage.module.scss'

export const ChatMessage = observer((props: ChatMessageProps) => {
    const {
        text,
        user,
        csv,
        img,
    } = props
    const wrappedText = useMemo(() => text?.replaceAll('\\n', '\n'), [text])
    const userColor = useMemo(() => user === 'You' ? 'primaryPurple' : 'secondaryLightBlack', [user])
    const csvTable = useMemo(() => {
        if (!csv) {
            return null
        }
        const lines = csv.split('\n')
        const header = lines[0].split(';')

        const data = []
        for (let i = 1; i < lines.length; i++) {
            const line = lines[i].split(';')
            if (line.length !== header.length) {
                continue
            }
            const dataLine = {}
            for (let j = 0; j < line.length; j++) {
                const value = line[j]
                const floatValue = parseFloat(value)
                dataLine[header[j]] = Number.isNaN(floatValue) ? value : floatValue
            }
            // @ts-expect-error TS(2345) FIXME: Argument of type '{}' is not assignable to paramet... Remove this comment to see the full error message
            data.push(dataLine)
        }

        return {
            columns: header.map((col) => ({
                field: col,
                headerName: col,
                sortable: true,
            })),
            data,
        }
    }, [csv])

    return (
        <Layout
            direction='column'
            gap={16}
            pr={16}
            pt={16}
        >
            <Text
                color={userColor}
                variant='subheader'
                text={`${user}:`}
            />
            <Text
                variant='bodyMedium'
                text={wrappedText}
                whiteSpace='preWrap'
            />
            {img && (
                <img
                    src={img}
                    alt='chart'
                    className={cn(styles.chart)}
                />
            )}
            {csv && (
                <div
                    className={cn(styles.tableContainer)}
                >
                    <Table
                        variant='1'
                        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                        rowData={csvTable.data}
                        // @ts-expect-error TS(2531) FIXME: Object is possibly 'null'.
                        columnDefs={csvTable.columns}
                    />
                </div>
            )}
            <div className={cn(styles.splitter)}/>
        </Layout>
    )
})
