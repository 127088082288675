import { MouseEvent, MutableRefObject, forwardRef } from 'react'

import cn from 'classnames'

import { Icon } from '@/components/base'
import { mixpanelTrack } from '@/utils/mixpanel'

import { ButtonTheme, ButtonVariant, ButtonProps } from './Button.types'

import styles from './Button.module.scss'

const BUTTON_VARIANT_BY_THEME: Record<ButtonTheme, ButtonVariant> = {
    primary: 'contained',
    secondary: 'outlined',
    flat: 'text',
    destructive: 'contained',
    alert: 'outlined',
    flatInverted: 'text',
    primaryInverted: 'contained',
    secondaryInverted: 'outlined',
}

// @ts-expect-error TS(2345) FIXME: Argument of type '(props: ButtonProps, ref?: Mutab... Remove this comment to see the full error message
export const Button = forwardRef((props: ButtonProps, ref?: MutableRefObject<HTMLButtonElement>) => {
    const {
        className,
        text,
        icon,
        endIcon = false,
        disabled = false,
        size = 's',
        theme = 'primary',
        onClick,
        loading = false,
        dataTestId,
        block,
    } = props

    let modifiedIcon = icon

    if (loading) {
        modifiedIcon = 'loader'
    }

    const isIconWithText = text?.length && modifiedIcon
    const iconOnly = !text?.length && modifiedIcon
    const variant = BUTTON_VARIANT_BY_THEME[theme]

    const buttonClassNames = cn(styles.button, className, {
        [styles['variant_' + variant]]: variant,
        [styles.disabled]: disabled,
        [styles['size_' + size]]: size,
        [styles['theme_' + theme]]: theme,
        [styles.iconWithText]: isIconWithText,
        [styles.iconOnly]: iconOnly,
        [styles.loading]: loading,
        [styles.block]: block,
    })

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        const buttonLabel = text?.length ? text : icon?.length ? 'Icon Button: ' + icon : 'Unknown'

        mixpanelTrack('Button Click', {
            label: buttonLabel,
        })

        onClick?.(e)
    }

    const iconProps = {
        className: styles.icon,
    }

    return (
        <button
            className={buttonClassNames}
            onClick={handleClick}
            data-testid={dataTestId}
            disabled={disabled || loading}
            inert={disabled ? '' : undefined}
            ref={ref}
        >
            {(modifiedIcon && !endIcon) && (
                <Icon
                    name={modifiedIcon}
                    {...iconProps}
                />
            )}
            {text}
            {(modifiedIcon && endIcon) && (
                <Icon
                    name={modifiedIcon}
                    {...iconProps}
                />
            )}
        </button>
    )
})
