import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'

import { CompanyInvite } from './CompanyInvite'

export enum CompanyInviteSendStatus {
    Sendign = 1,
    Sent = 2,
    Error = 3,
}

@api('company-invite-send')
@model
export class CompanyInviteSend extends Model {
    @field company_invite_id?: number
    @field timestamp?:         string
    @field email?:             string
    @field status:             CompanyInviteSendStatus
    @field details?:           string

    @foreign(CompanyInvite) readonly company_invite: CompanyInvite
}
many(CompanyInviteSend, 'company_invite_id')(CompanyInvite, 'sends')
