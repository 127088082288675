import cn from 'classnames'

import { Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { Icon } from '@/components/base'

import { LabelProps } from './Label.types'

import styles from './Label.module.scss'

export const Label = observer((props: LabelProps) => {
    const { className, text, color, width, iconName, onClick, detailsInSidemodal, testId } = props

    const labelClassNames = cn(styles.label, className, {
        [styles['color_' + color]]: color,
        [styles.clickable]: onClick,
    })

    return (
        <Tooltip
            title='Click for details'
            arrow
            enterNextDelay={500}
            enterDelay={500}
            disableHoverListener={!(onClick && detailsInSidemodal)}
        >
            <div
                onClick={onClick}
                className={labelClassNames}
                style={{ width }}
                data-testid={testId}
            >
                {(iconName) && (
                    <Icon
                        name={iconName}
                        size='s'
                    />
                )}
                {text && (
                    <span>
                        {text}
                    </span>
                )}
                {(onClick && detailsInSidemodal) && (
                    <Icon
                        name='sidemodal'
                        size='s'
                    />
                )}
            </div>
        </Tooltip>
    )
})
