import { model, field, foreign } from 'mobx-orm'

import http from '@/http.service'

import { api } from '../../http-adapter'
import { Company } from '../company'
import { AssetType, ASSET_TYPE_LABEL } from '../core/AssetType'
import { Model } from '../model'

import { AssetGroup } from './AssetGroup'
import { AssetInAssetGroup } from './AssetInAssetGroup'
import { CompanyUserGroupInAsset } from './CompanyUserGroupInAsset'
import { CompanyUserInAsset } from './CompanyUserInAsset'

// TODO: remove any
export enum AssetStatus {
    PENDING     = <any>'P', // don't delete <any>!
    CONNECTED   = <any>'C',
    PROCESSING  = <any>'D',
    ACTIVE      = <any>'A',
    INACTIVE    = <any>'I',
}

@api('asset')
@model
export class Asset extends Model {
    @field  idx?: number

    @field  company_id?:          number
    @field  name?:                string
    @field  city?:                string
    @field  state?:               string
    @field  address?:             string
    @field  status?:              AssetStatus
    @field  type?:                AssetType
    @field  fund_name?:           string
    @field asset_manager_name?:   string
    @field ownership_percentage?: number
    @field reporting_due_date?:   number
    @field owner_id?:             number

    @field acquisition_date?: string

    @field  property_manager_name?:    string
    @field  property_manager_email?:   string
    @field  property_manager_company?: string
    @field  sponsor_contact?:          string
    @field  sponsor_contact_email?:    string
    @field  software?:                 string
    @field  url?:                      string
    @field  username?:                 string
    @field  password?:                 string
    @field  other_login_criteria?:     string

    @field  custom_id1?: string
    @field  custom_id2?: string

    @field  units_count?: number
    @field  square_feet?: number

    // TODO: need to get from history model
    @field  last_updates?: string // Datetime in a string

    @foreign(Company) readonly company: Company

    readonly groups?:              AssetGroup[]
    readonly group_links:          AssetInAssetGroup[]
    readonly personal_permissions: CompanyUserInAsset[]
    readonly group_permissions:    CompanyUserGroupInAsset[]

    get typeTitle (): string {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetType | undefined' is not as... Remove this comment to see the full error message
        if (ASSET_TYPE_LABEL.has(this.type)) {
            // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            return ASSET_TYPE_LABEL.get(this.type)
        } else {
            return 'Unknown'
        }
    }

    get companyName (): string {
        // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
        return this.company?.name
    }

    static get availableTypes (): Set<AssetType> {
        const uniqueStatuses = new Set<AssetType>()
        for (const asset of Asset.__cache.values()) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetType | undefined' is not as... Remove this comment to see the full error message
            uniqueStatuses.add((asset as Asset).type)
        }
        return uniqueStatuses
    }

    get statusTitle (): string {
        const statuses = Asset.statusTitles
        if (statuses.has(this.status as any)) {
            // @ts-expect-error TS(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            return statuses.get(this.status as any)
        } else {
            return 'Unknown'
        }
    }

    // TODO: move it to const and export it like assetTypeTitles
    static get statusTitles () {
        return new Map([
            ['P', 'Pending'],
            ['C', 'Connected to Property Manager'],
            ['D', 'Data Processing'],
            ['A', 'Active'],
            ['I', 'Inactive'],
        ])
    }

    static get propertyManagerCompanies (): Promise<Set<string>> {
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        return http.get('/asset/property_manager_companies/').then(({ data }) => new Set(data.property_manager_companies))
    }

    // return this step of status is done or not
    // PENDING => CONNECTED => PROCESSING => ACTIVE
    statusCheckpoint (checkpoint: AssetStatus) {
        switch (checkpoint) {
            case AssetStatus.PENDING:
                // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetStatus | undefined' is not ... Remove this comment to see the full error message
                return [AssetStatus.PENDING, AssetStatus.CONNECTED, AssetStatus.PROCESSING, AssetStatus.ACTIVE].includes(this.status)
            case AssetStatus.CONNECTED:
                // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetStatus | undefined' is not ... Remove this comment to see the full error message
                return [AssetStatus.CONNECTED, AssetStatus.PROCESSING, AssetStatus.ACTIVE].includes(this.status)
            case AssetStatus.PROCESSING:
                // @ts-expect-error TS(2345) FIXME: Argument of type 'AssetStatus | undefined' is not ... Remove this comment to see the full error message
                return [AssetStatus.PROCESSING, AssetStatus.ACTIVE].includes(this.status)
            case AssetStatus.ACTIVE:
                return AssetStatus.ACTIVE === this.status
        }
        return false
    }
}
