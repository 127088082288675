import { useEffect, useState } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Icon, Text, LegacySelectPopover } from '@/components/base'
import { InputBox } from '@/components/containers'

import { LegacySelectProps } from './LegacySelect.types'
import { LegacySelectItemData } from './LegacySelectItem'

import styles from './LegacySelect.module.scss'

/**
 * @deprecated
 * Use {@link Select} instead
 */
export const LegacySelect = observer((props: LegacySelectProps) => {
    const {
        onClose,
        onChange,
        onMultiChange,
        options,
        testId = 'select',
        className,
        label,
        labelInside,
        customValue,
        placeholder,
        width,
        size = 's',
        hint,
        error,
        noItemsPlaceholder = 'No items found',
        icon,
        search = true,
        searchOptionsThreshold = 5,
        searchPlaceholder = 'Search',
        unselectOnClick = false,
        multiSelect = false,
        selectAll = false,
        selectAllPlaceholder = 'All',
        multiSelectCustomValues = [],
        multiSelectPlaceholder = 'Items',
        readonly = false,
        sorted = false,
        required = false,
        disabled = false,
        headerComponent,
        ...rest
    } = props

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    const [selectedValues, setSelectedValues] = useState([])

    // For trigger useEffect and avoid render loops if someone pass not memoized array
    const multiSelectValuesString = multiSelectCustomValues.join(',')

    // Sync values
    useEffect(() => {
        const updatedValue = multiSelect
            ? [...multiSelectCustomValues]
            : [customValue]

        // @ts-expect-error TS(2345) FIXME: Argument of type '(string | number | undefined)[]'... Remove this comment to see the full error message
        setSelectedValues(updatedValue)
    }, [customValue, multiSelect, multiSelectValuesString])

    const selectAllItem = { value: 'all', label: selectAllPlaceholder }

    const handleClick = (event: React.PointerEvent<HTMLElement>) => {
        if (readonly || disabled) {
            return
        }
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
        onClose?.()
    }

    const handleChange = (option: LegacySelectItemData) => {
        // @ts-expect-error TS(2322) FIXME: Type 'string | number' is not assignable to type '... Remove this comment to see the full error message
        setSelectedValues([option.value])
        onChange?.(option)
    }

    const handleMultiChange = (options: LegacySelectItemData[]) => {
        // @ts-expect-error TS(2345) FIXME: Argument of type '(string | number)[]' is not assi... Remove this comment to see the full error message
        setSelectedValues(options.map((option) => option.value))
        onMultiChange?.(options)
    }

    const open = Boolean(anchorEl)

    const getInputValue = () => {
        if (selectedValues.length === 1) {
            const selectedOption = options.find((option) => option.value === selectedValues[0])
            return selectedOption?.label?.toString()
        }
        if (multiSelect && selectedValues.length > 1) {
            const count = (selectedValues[0] !== selectAllItem.value) ? selectedValues.length : selectedValues.length - 1
            return `${multiSelectPlaceholder} (${count.toString()})`
        }
    }

    const inputValue = getInputValue()
    const haveValue = Boolean(inputValue)
    const inputClassNames = cn(styles.wrapper, className, { [styles.open]: open })
    const isSearchOn = search || (options.length > searchOptionsThreshold)

    return (
        <>
            <InputBox
                onClick={handleClick}
                label={label}
                isActive={open}
                labelInside={labelInside}
                size={size}
                className={inputClassNames}
                testId={testId}
                hint={hint}
                error={error}
                width={width}
                required={required}
                disabled={disabled}
                {...rest}
            >
                {icon && (
                    <Icon
                        name={icon}
                        className={styles.customIcon}
                        color='colorsSecondaryGrey600'
                    />
                )}
                {haveValue ? (
                    <Text
                        variant='smallTextMediumDefault'
                        text={inputValue}
                        color={open ? 'colorsPrimaryPurple' : 'secondaryBlack'}
                        whiteSpace='nowrap'
                        className={styles.contentText}
                        overflow='hidden'
                        textOverflow='ellipsis'
                    />
                ) : (
                    <Text
                        color='colorsSecondaryGrey600'
                        variant='smallTextMediumDefault'
                        text={placeholder}
                        whiteSpace='nowrap'
                        className={styles.contentText}
                        overflow='hidden'
                        textOverflow='ellipsis'
                    />
                )}
                {!readonly && (
                    <Icon
                        className={styles.icon}
                        name='arrowDown'
                        color={open ? 'colorsPrimaryPurple' : 'colorsSecondaryGrey600'}
                    />
                )}
            </InputBox>

            <LegacySelectPopover
                onClose={handleClose}
                onChange={handleChange}
                onMultiChange={handleMultiChange}
                anchorEl={anchorEl}
                options={options}
                customValue={customValue}
                multiSelectCustomValues={multiSelectCustomValues}
                testId={testId}
                noItemsPlaceholder={noItemsPlaceholder}
                selectAllPlaceholder={selectAllPlaceholder}
                selectAllItem={selectAllItem}
                searchPlaceholder={searchPlaceholder}
                multiSelect={multiSelect}
                selectAll={selectAll}
                unselectOnClick={unselectOnClick}
                search={isSearchOn}
                sorted={sorted}
                headerComponent={headerComponent}
            />
        </>
    )
})
