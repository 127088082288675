import { useEffect, useState } from 'react'

import format from 'date-fns/format'

import { observer } from 'mobx-react-lite'
import { useNavigate, useOutletContext, useParams } from 'react-router'

import { SideModal, Label, Text } from '@/components/base'
import { Table } from '@/components/legacy/tables/Table'
import { STRING_COMPARATOR } from '@/components/tables/AgGridTable/AgGridTable.constants'
import { CompanyUserMode } from '@/core/modes'
import { ADMIN_ROUTES_CONFIG, OWNER_ROUTES_CONFIG } from '@/core/routes'
import { useMe } from '@/hooks/core/useMe'
import http from '@/http.service'
import { Asset } from '@/models/asset'
import { DataPointType } from '@/models/data_pulling'

import { ResultsTableAssetData } from '../ResultsTable/ResultsTable.types'
import { getMonthDays } from '../ValidationTrackerPage'
import { ValidationTrackerOutletContext } from '../ValidationTrackerPage.types'

import styles from './MonthlyRentRollSideModal.module.scss'

const MonthlyRentRollSideModal = observer(() => {
    const navigate = useNavigate()
    const { reportAssetId, reportYear, reportMonth } = useParams()
    const { onLoad } = useOutletContext<ValidationTrackerOutletContext>()

    const { me } = useMe()

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const asset = Asset.get(parseInt(reportAssetId)) as Asset

    const PAGE_PATH = me.companyUserMode === CompanyUserMode.Admin
        ? ADMIN_ROUTES_CONFIG.VALIDATION_TRACKER.path
        : OWNER_ROUTES_CONFIG.VALIDATION_TRACKER.path

    if (!asset) {
        // If no asset found, redirect to validation tracker page
        // Example: user switched the company
        navigate(PAGE_PATH, { replace: true })
        return null
    }

    const [isReady, setIsReady] = useState(false)
    const [trackerData, setTrackerData] = useState<ResultsTableAssetData['dates']>({})

    const urlPageParams = new URLSearchParams(window.location.search)

    const loadData = async () => {
        const urlParams = new URLSearchParams()
        urlParams.set('companyId', urlPageParams.get('companyId') || '0')
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        urlParams.set('assetId', reportAssetId)
        urlParams.set('fileType', DataPointType.RENT_ROLL)
        urlParams.set('periodType', 'daily')
        urlParams.set('statusType', 'all')
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
        urlParams.set('errorCode', urlPageParams.get('errorCode'))
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        urlParams.set('currentYear', reportYear)
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        urlParams.set('currentMonth', reportMonth)
        urlParams.set('__offset', '0')
        urlParams.set('__limit', '10')

        const uri = `data-pulling/validation-tracker?${urlParams}`

        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        const response = await http.get(uri, me.companyUser ? { headers: { 'company-user-id': `${me.companyUser.id}` } } : undefined)

        setTrackerData(response.data.result[0]?.dates ?? {})
        setIsReady(true)
    }

    useEffect(() => {
        loadData().then(() => onLoad?.())
    }, [])

    const days = getMonthDays(reportYear, reportMonth)

    const tableData = days.map(date => {
        const dateData = trackerData[format(date, 'yyyy-MM-dd')]
        const dataPoint = dateData?.[DataPointType.RENT_ROLL]
        return {
            ...dataPoint,
            date: format(date, 'MM/dd/yyyy'),
        }
    })

    const doneCount = tableData.map(date_data => date_data.is_done ? 1 : 0).reduce((a, b) => a + b, 0)
    const processingCount = tableData.map(date_data => date_data.is_processing ? 1 : 0).reduce((a, b) => a + b, 0)
    const errorsCount = tableData.map(date_data => date_data.is_error ? 1 : 0).reduce((a, b) => a + b, 0)
    const notUploadedCount = tableData.map(date_data => date_data.initial_file_name ? 0 : 1).reduce((a, b) => a + b, 0)

    const detailsUrlParams = new URLSearchParams()
    detailsUrlParams.set('companyId', `${asset.company_id}`)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    detailsUrlParams.set('assetId', reportAssetId)
    detailsUrlParams.set('fileType', DataPointType.RENT_ROLL)
    detailsUrlParams.set('periodType', 'daily')
    detailsUrlParams.set('statusType', 'all')
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
    detailsUrlParams.set('errorCode', urlPageParams.get('errorCode'))
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    detailsUrlParams.set('currentYear', reportYear)
    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    detailsUrlParams.set('currentMonth', reportMonth)
    detailsUrlParams.set('__offset', '0')
    detailsUrlParams.set('__limit', '10')
    const detailsUrl = `${PAGE_PATH}?${detailsUrlParams}`

    return (
        <SideModal
            key='file-info'
            closePath={`${PAGE_PATH}?${new URLSearchParams(window.location.search)}`}
            width={700}
            haveCloseButton
            // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
            title={`${asset.name}, Rent Roll, ${format(new Date(Number(reportYear), parseInt(reportMonth) - 1, 1), 'MMMM yyyy')}`}
            titleComponent={(
                <div className={styles.head}>
                    <div className={styles.headRow}>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Uploaded, days:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>{(isReady && doneCount) || '—'}</Text>
                            </div>
                        </div>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>In processing, days:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>{(isReady && processingCount) || '—'}</Text>
                            </div>
                        </div>
                    </div>
                    <div className={styles.headRow}>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Errors, days:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>{(isReady && errorsCount) || '—'}</Text>
                            </div>
                        </div>
                        <div className={styles.headRowCell}>
                            <div className={styles.headRowCellTitle}>
                                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Not uploaded, days:</Text>
                            </div>
                            <div className={styles.headRowCellValue}>
                                <Text variant='smallTextSemiboldDefault' color='secondaryBlack'>{(isReady && notUploadedCount) || '—'}</Text>
                            </div>
                        </div>
                    </div>
                    <div>
                        <a
                            className={styles.goToDetailsLink}
                            href={detailsUrl}
                            rel='noreferrer'
                        >Go to daily view for more details</a>
                    </div>
                </div>
            )}
        >
            <Table
                variant='1'
                rowData={tableData}
                loading={!isReady}
                {...gridOptions}
                domLayout='autoHeight'
            />
        </SideModal>
    )
})

const gridOptions = {
    context: {},
    // rowHeight: 51,
    headerHeight: 49,
    columnTypes: {
        small: { width: 50 },
        status: {
            cellRenderer: (params) => {
                if (params.data.is_done && !params.data.is_done_with_alerts) {
                    return (
                        <Label
                            text='Uploaded'
                            iconName='check'
                            color='green'
                        />
                    )
                } else if (params.data.is_done_with_alerts) {
                    return (
                        <Label
                            text='Uploaded with Alerts'
                            iconName='warning'
                            color='blue'
                        />
                    )
                } else if (params.data.is_processing) {
                    return (
                        <Label
                            text='Processing'
                            color='orange'
                            iconName='refresh'
                        />
                    )
                } else if (params.data.is_error) {
                    return (
                        <Label
                            text='Error'
                            color='red'
                            iconName='info'
                        />
                    )
                } else {
                    return <div className={styles.dashCell}>—</div>
                }
            },
        },
        file_name: {
            valueGetter: (params) => {
                return params.data.initial_file_name || '—'
            },
        },
    },
    defaultColDef: {
        width: 100,
        resizable: true,
        suppressHeaderMenuButton: true,
        wrapText: true,
        autoHeight: true,
        comparator: STRING_COMPARATOR,
    },
    columnDefs: [
        { field: 'date', headerName: 'Date', type: 'small' },
        { headerName: 'File Name', type: 'file_name' },
        { headerName: 'Status', type: 'status' },
    ],
    sortModel: {},
}

export { MonthlyRentRollSideModal }
