import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { BaseModal } from '@/components/base'

import { ModalProps } from './Modal.types'

import styles from './Modal.module.scss'

export const Modal = observer((props: ModalProps) => {
    const {
        className,
    } = props

    return (
        <BaseModal
            {...props}
            className={cn(styles.modal, className)}
        />
    )
})
