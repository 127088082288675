import { useCallback, useEffect, useMemo, useState } from 'react'

import { Layout, LayoutSpace } from '@/components/containers'

import { Checkbox } from '../Checkbox'
import { Icon } from '../Icon'

import { FilterTreeItem, FilterTreeProps } from './FilterTree.types'
import { getSelectionInChildren } from './FilterTree.utils'

import styles from './FilterTree.module.scss'

export const FilterTree = (props: FilterTreeProps) => {
    const { items = [], selected = [], expandAll = false, onChange } = props

    const [expandedItems, setExpandedItems] = useState<Record<string, boolean>>({})
    const selectedIds = useMemo(() => new Set(selected), [selected])

    useEffect(() => setExpandedItems({}), [expandAll])

    const handleCheckboxChange = useCallback((item: FilterTreeItem, childrenSelection: -1 | 0 | 1) => {
        const hasChildren = Boolean(item.children?.length)
        const newSelected = new Set(selectedIds)

        if (!hasChildren) {
            if (selectedIds.has(item.id)) {
                newSelected.delete(item.id)
            } else {
                newSelected.add(item.id)
            }
            onChange?.([...newSelected])
            return
        }

        const changeChildrenSelection = (items: FilterTreeItem[], selectAll: boolean) => {
            items.forEach((item) => {
                if (item.children?.length) {
                    changeChildrenSelection(item.children, selectAll)
                } else {
                    if (selectAll) {
                        newSelected.add(item.id)
                    } else {
                        newSelected.delete(item.id)
                    }
                }
            })
        }

        if (childrenSelection === 1) {
            changeChildrenSelection(item.children ?? [], false)
        } else {
            changeChildrenSelection(item.children ?? [], true)
        }

        onChange?.([...newSelected])
    }, [selectedIds, onChange])

    const getItems = (items: FilterTreeItem[], level = 0) => {
        return items.map((item, index) => {
            const hasChildren = Boolean(item.children?.length)
            const isExpanded = expandedItems[item.id] ?? expandAll
            const isSelected = selectedIds.has(item.id) && !hasChildren
            const childrenSelection = getSelectionInChildren(item.children ?? [], selectedIds)
            const isIndeterminate = hasChildren && !childrenSelection

            return (
                <Layout
                    key={index}
                    direction='column'
                    pl={level ? 20 : undefined}
                >
                    <Layout gap={6 as LayoutSpace} py={4} align='center'>
                        {hasChildren ? (
                            <Icon
                                name={isExpanded ? 'arrowDown' : 'arrowRight'}
                                size='s'
                                onClick={() => setExpandedItems((state) => ({ ...state, [item.id]: !isExpanded }))}
                            />
                        ) : <span className={styles.offset}/>}
                        <Checkbox
                            size='s'
                            checked={isSelected || childrenSelection === 1}
                            indeterminate={isIndeterminate}
                            onChange={() => handleCheckboxChange(item, childrenSelection)}
                            dataTestId={`checkbox-${item.id}`}
                        />
                        <span className={styles.itemName} title={item.name}>{item.name}</span>
                    </Layout>
                    {hasChildren && isExpanded && getItems(item.children ?? [], level + 1)}
                </Layout>
            )
        })
    }

    return (
        <Layout direction='column' width='100%'>
            {getItems(items)}
        </Layout>
    )
}
