import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Text } from '@/components/base'

import { LinkProps } from './Link.types'

import styles from './Link.module.scss'

export const Link = observer((props: LinkProps) => {
    const {
        className,
        children,
        variant,
        color = 'primaryPurple',
        onClick,
        maxLines,
        tooltip,
        testId = 'link',
        asAnchor = false,
        href,
    } = props

    return (asAnchor
        ? (
            <a
                href={href}
                target='_blank'
                rel='noreferrer'
                className={cn(styles.link, className)}
                title={tooltip}
                data-testid={testId}
            >
                <Text
                    className={cn(styles.link, className)}
                    color={color}
                    variant={variant}
                    maxLines={maxLines}
                >
                    {children}
                </Text>
            </a>
        )
        : (
            <span onClick={onClick} title={tooltip} data-testid={testId}>
                <Text
                    className={cn(styles.link, className)}
                    color={color}
                    variant={variant}
                    maxLines={maxLines}
                >
                    {children}
                </Text>
            </span>
        )
    )
})
