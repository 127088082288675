import { useContext } from 'react'

import { useAuth0 } from '@auth0/auth0-react'

import { CompanyUserItem } from '@/api/user/companyUser'
import {
    meContext,
} from '@/components/containers'
import { Me } from '@/components/containers/MeContext'
import {
    LOCAL_STORAGE_COMPANY_USER_ID_KEY,
    LOCAL_STORAGE_COMPANY_USER_MODE_KEY,
} from '@/components/containers/MeContext/MeContext.constants'
import { CompanyUserMode } from '@/core/modes'
import { mixpanelTrack } from '@/utils/mixpanel'
import { notify } from '@/utils/notify'

interface UseMe {
    me: Me
    logout: (queryParams?: string) => void
    login: () => void
    switchMode: (mode: CompanyUserMode, redirectTo?: string) => void
    switchCompanyUser: (companyUser: CompanyUserItem) => void
}

/**
 * Use this hook only inside MeContext container
 */
export const useMe = (): UseMe => {
    const me = useContext(meContext)
    const { logout: auth0Logout, loginWithRedirect } = useAuth0()

    const logout = (queryParams?: string) => {
        mixpanelTrack('Logout')

        sessionStorage.clear()

        auth0Logout({
            logoutParams: {
                returnTo: window.location.origin + (queryParams ? `/${queryParams}` : ''),
            },
        })
    }

    const login = () => {
        loginWithRedirect({
            appState: {
                returnTo: window.location.pathname + window.location.search,
            },
        })
    }

    const switchMode = (mode: CompanyUserMode, redirectTo?: string) => {
        if (mode === CompanyUserMode.Admin) {
            if (!me.user.is_staff) {
                notify.error('No Access to Admin Interface')
                throw new Error('You have no permission to switch on Admin Mode')
            }
        }

        localStorage.setItem(LOCAL_STORAGE_COMPANY_USER_MODE_KEY, JSON.stringify(mode))

        // We have to reload page (reset all models cache)
        // Reset route to because it can be different for admin and user
        window.location.href = redirectTo || '/'
    }

    const switchCompanyUser = (companyUser: CompanyUserItem) => {
        if (companyUser.user_id !== me.user.id) {
            throw new Error('You can activate only your own Company User')
        }

        localStorage.setItem(LOCAL_STORAGE_COMPANY_USER_ID_KEY, `${companyUser.id}`)

        // we have to reload page (reset all models cache) after change the active company user
        window.location.href = '/'
    }

    return {
        me,
        logout,
        login,
        switchMode,
        switchCompanyUser,
    }
}
