import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'
import { CompanyUserGroup } from '../company'
import { AccessLevel } from '../core'

import { CompanyUserGroupInTemplateHistory } from './CompanyUserGroupInTemplateHistory'
import { Template } from './Template'

@api('company-user-group-in-csv-template')
@model
export class CompanyUserGroupInTemplate extends Model {
    @field template_id?:           string
    @field company_user_group_id?: number
    @field level?:                 AccessLevel

    @foreign(Template) readonly template:                   Template
    @foreign(CompanyUserGroup) readonly company_user_group: CompanyUserGroup

    readonly history: CompanyUserGroupInTemplateHistory[]

    get level_name (): string {
        // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return AccessLevel[this.level]
    }
}
many(CompanyUserGroupInTemplate, 'template_id')(Template, 'group_permissions')
many(CompanyUserGroupInTemplate, 'company_user_group_id')(CompanyUserGroup, 'template_permissions')
