import { useEffect, useState } from 'react'

import { runInAction } from 'mobx'
import { Query, ValueType } from 'mobx-orm'

import { observer } from 'mobx-react-lite'
import { useNavigate, useOutletContext } from 'react-router'

import { Button, Link, Text, Loader, BigModal } from '@/components/base'
import { Layout } from '@/components/containers'
import { StringInput } from '@/components/legacy/models/core/inputs/StringInput'
import { ModelForm } from '@/components/legacy/models/core/ModelForm/ModelForm'
import { ModelId } from '@/components/legacy/models/core/ModelId/ModelId'
import { TrialBalanceLedgerInput } from '@/components/legacy/models/trial-balance/inputs/TrialBalanceLedgerInput'
import { TrialBalanceTemplateUploadFileInput } from '@/components/legacy/models/trial-balance-template/TrialBalanceTemplateUploadFileInput'
import { CompanyUserMode } from '@/core/modes'
import { useMe } from '@/hooks/core/useMe'
import { TrialBalanceLedger } from '@/models/ledger'
import { TrialBalanceTemplate, TrialBalanceTemplateUpload, TrialBalanceTemplateUploadStatus } from '@/models/trial_balance_template'
import { getOrCreate, getValueFromURL } from '@/utils/mobx-orm'
import { notify } from '@/utils/notify'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import styles from './MappingTemplateCreatePage.module.scss'

const TEMPLATES_PATH = getRouteConfig('ASSET_MAPPING_TEMPLATES', CompanyUserMode.Owner).path

export const MappingTemplateCreatePage = observer(() => {
    const navigate = useNavigate()
    const { me } = useMe()
    const { templateQuery } = useOutletContext<any>()

    const [ledgerQuery] = useState(() => TrialBalanceLedger.getQuery() as Query<TrialBalanceLedger>)
    const [template, setTemplate] = useState<TrialBalanceTemplate>()
    const [templateUpload, setTemplateUpload] = useState<TrialBalanceTemplateUpload>()

    useEffect(() => {
        // init objects
        getOrCreate(TrialBalanceTemplate, getValueFromURL('template-id', ValueType.NUMBER), setTemplate)
        getOrCreate(TrialBalanceTemplateUpload, getValueFromURL('template-upload-id', ValueType.NUMBER), setTemplateUpload)
    }, [])

    useEffect(() => {
        (async () => {
            if (templateUpload?.id !== undefined &&
                templateUpload?.status !== undefined &&
                templateUpload?.status !== TrialBalanceTemplateUploadStatus.ERROR &&
                templateUpload?.status !== TrialBalanceTemplateUploadStatus.UPLOADED &&
                templateUpload?.status !== TrialBalanceTemplateUploadStatus.DONE &&
                template?.ledger !== undefined) {
                try {
                    await templateUpload.runCheckingCompatibilityWithLedger(template.ledger)
                } catch (e) {
                    // ignore, errors should be in templateUpload.__errors
                    // or templateUpload.logs and status should be ERROR
                }
            } else if (templateUpload?.status === TrialBalanceTemplateUploadStatus.DONE) {
                // TODO: should we clear the local storage?
                setTimeout(() => {
                    notify.success('Template was created')
                    templateQuery?.load()
                    navigate(TEMPLATES_PATH, { replace: true })
                })
            }
        })()
    }, [template?.ledger_id, templateUpload?.id, templateUpload?.status])

    const apply = async () => {
        if (!template) {
            return
        }
        try {
            runInAction(() => {
                template.company_id = me.companyUser.company_id
            })
            await template.save()
        } catch (e) {
            // ignore, errors should be in template.__errors
            return
        }
        try {
            await templateUpload?.runApplyingFileToTemplate(template)
        } catch (e) {
            // ignore, errors should be in templateUpload.__errors or templateUpload.logs with status ERROR
            // TODO: who to hable the 500 error? http service should do it? what should we show on the page?
        }
    }

    const cancel = () => {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        template.cancelLocalChanges()
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        templateUpload.cancelLocalChanges()
        navigate(TEMPLATES_PATH, { replace: true })
    }

    const readyToApply =
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        template?.name?.length > 0 &&
        template?.ledger_id !== undefined &&
        templateUpload?.status === TrialBalanceTemplateUploadStatus.READY
    const isApplying = templateUpload?.status === TrialBalanceTemplateUploadStatus.APPLYING

    return (
        <BigModal className={styles.modal}>
            <div className={styles.header}>
                <Layout mb={4}>
                    <Text text='Template Creation' variant='bodySemiboldDefault'/>
                </Layout>
                <Text variant='labelMediumDefault' color='colorsSecondaryGrey400'>
                    You can upload a file to create your mapping template in&nbsp;
                    <Link
                        href='/samples/Sample Mapping Template.csv'
                        variant='labelMediumDefault'
                        asAnchor
                    >this format</Link>
                    .
                </Text>
            </div>

            {!template || !templateUpload ? (<Loader/>) : (
                <>
                    <div className={styles.content}>
                        <ModelForm obj={template} syncLocalStorage='template-'>
                            <ModelId syncURL='template-'/>
                            <Layout mb={24}>
                                <StringInput
                                    field='name'
                                    label='Mapping Template Name'
                                    disabled={isApplying}
                                />
                            </Layout>
                            <Layout block mb={36}>
                                <TrialBalanceLedgerInput
                                    label='Choose Target Ledger'
                                    options={ledgerQuery}
                                    disabled={templateUpload.status === TrialBalanceTemplateUploadStatus.CHECKING}
                                />
                            </Layout>
                        </ModelForm>
                        <div className={styles.uploadBlock}>
                            <ModelForm obj={templateUpload}>
                                <ModelId syncURL='template-upload-'/>
                                <TrialBalanceTemplateUploadFileInput verticalLayout/>
                            </ModelForm>
                        </div>
                    </div>

                    <div className={styles.footer}>
                        <Button
                            onClick={cancel}
                            text='Cancel'
                            theme='secondary'
                            disabled={isApplying}
                        />
                        <Text
                            text='You’ll be able to link assets to the template after the creation'
                            variant='labelMediumDefault'
                            color='colorsSecondaryGrey400'
                            className={styles.footerText}
                        />
                        <Button
                            onClick={apply}
                            text='Create Template'
                            disabled={!readyToApply}
                            loading={isApplying}
                        />
                    </div>
                </>
            )}
        </BigModal>
    )
})
