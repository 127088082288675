import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'
import { CompanyUser } from '../company'
import { AccessLevel } from '../core'

import { CompanyUserInTemplateHistory } from './CompanyUserInTemplateHistory'
import { Template } from './Template'

@api('company-user-in-csv-template')
@model
export class CompanyUserInTemplate extends Model {
    @field template_id?:     number
    @field company_user_id?: number
    @field level?:           AccessLevel // TODO: move it to class inheritance

    @foreign(Template) readonly template:        Template
    @foreign(CompanyUser) readonly company_user: CompanyUser

    readonly history: CompanyUserInTemplateHistory[]

    get level_name (): string { // TODO: move it to class inheritance
        // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return AccessLevel[this.level]
    }
}
many(CompanyUserInTemplate, 'template_id')(Template, 'personal_permissions')
many(CompanyUserInTemplate, 'company_user_id')(CompanyUser, 'template_personal_permissions')
