import React from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Icon } from '@/components/base'
import { Layout } from '@/components/containers'

import { UploadedFileBaseProps } from './UploadedFileBase.types'

import styles from './UploadedFileBase.module.scss'

export const UploadedFileBase = observer((props: UploadedFileBaseProps) => {
    const { onDelete, children, isUpdating, type } = props

    return (
        <Layout
            align='center'
            className={cn(styles.uploadedFileBase, { [styles['type_' + type]]: type })}
        >
            <Layout
                px={16}
                py={8}
                flexGrow={1}
            >
                {children}
            </Layout>
            {isUpdating
                ? (
                    <Layout className={styles.button}>
                        <Icon name='loader' size='m'/>
                    </Layout>
                )
                : (
                    <Layout className={styles.button} onClick={onDelete} data-testid='delete-button'>
                        <Icon name='delete' size='m'/>
                    </Layout>
                )}
        </Layout>
    )
})
