import { useState } from 'react'

import { Downloader } from '@ag-grid-community/csv-export'
import { AxiosError } from 'axios'
import { AND, EQ, Filter, SingleFilter } from 'mobx-orm'

import { useMutation, useQuery, useQueryClient, UseQueryOptions } from '@tanstack/react-query'

import { UseQueryCustomParams } from '@/api/api.types'
import http from '@/http.service'
import { fsDownloadEvent } from '@/utils/fullstory/fsDownloadEvent'
import { notify } from '@/utils/notify'

/**
 * No message for fetch because there is a risk to spam user with error messages, and we have screens for crashes
 * Other error messages are optional if required in specific endpoint, in other cases no message would be shown
 */
interface GetRequestQueriesParams<ResultItem> {
    url: string
    errorMessageFetchCSV?: string
    errorMessageUpdate?: string
    errorMessageCreate?: string
    errorMessageDelete?: string
    /**
     * Function to extract items from responce
     */
    extractItemsFn?: (data: unknown) => ResultItem[]
    /**
     * List of urls to invalidate after update
     */
    invalidateAfterUpdate?: string[]
}

interface FetchItemsParams<T> {
    queryParams?: T
    filterString?: string
    pageLimit?: number
    pageOffset?: number
    id?: number | string
}
export interface UpdateByIdParams<T> {
    id: number | string
    updatedItemData: Partial<T>
    config?: {
        suppressErrorToast?: boolean
    }
}
interface DownloadCSVDataParams<T> {
    queryParams?: T
    filter?: Filter
    id?: string | number
    fileName?: string
    /**
     * Name for loggs in FullStory
     */
    fsEventName: string
}

interface UseItemUpdateMutationParams {
    /**
     * Disable triggering refetch for items query and all items list update
     */
    disableItemsInvalidation?: boolean
}

/**
 * @todo: Add 'id' to the items interface
 * @todo: Prop command for generating api requests
 * @todo: Validate closing slash '/' in the end of url
 * @todo: Turn off autoupdate by default form make migration more easy
 * @todo: Add flag "isModel" or something like this. For endpoints which is build on database table we have more default functionality.
 * @todo: Implement linking with other fetched data?
 * @todo: How to load items in background without triggering loaders?
 * @todo: Cache works not as expected sometimes and hooks doing requests even if data is cached
 * @todo: Stop doing autoupdate if user logged out or token expired if property "refetchInterval" is used
 * @todo: Sorting props (for tables)
 * @todo: Create hook
 * @todo: Migrate 'filrtersString' to object (it can me new method fro filters 'toObject')
 * @todo: Pause react-query refetch in background when tab is not active
 * @todo: Specify in types that array will be returned by default (not undefined or array)
 * @todo: Make compatible with object response (and remove hacks with 'extractItemsFn')
 * @todo: Invalidation individual items after mutation and replacing without refetching all items (https://tanstack.com/query/v4/docs/framework/react/guides/updates-from-mutation-responses)
 * @todo: Take items by id from cache if possible (https://tanstack.com/query/v4/docs/framework/react/guides/initial-query-data#initial-data-from-the-cache-with-initialdataupdatedat)
 */
export const getRequestQueries = <ResultItem, GetItemsQueryParams>(params: GetRequestQueriesParams<ResultItem>) => {
    const { url, extractItemsFn, errorMessageFetchCSV, errorMessageUpdate, errorMessageDelete, errorMessageCreate, invalidateAfterUpdate = [] } = params

    const commonHeaders = {
        'react-query': 'true',
    }

    async function fetchItems ({ queryParams, filterString, pageLimit, pageOffset, id }: FetchItemsParams<GetItemsQueryParams>) {
        const paginationParams: Record<string, number> = { }

        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        if (pageOffset >= 0 && pageLimit >= 0) {
            // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
            paginationParams.__limit = pageLimit
            // @ts-expect-error TS(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
            paginationParams.__offset = pageOffset
        }

        let requestUrl = `${url}/${id ? `${id}/` : ''}`
        requestUrl = filterString ? `${requestUrl}?${filterString}` : requestUrl

        const response = await http.get!<ResultItem[]>(requestUrl, {
            params: {
                ...queryParams,
                ...paginationParams,
            },
            headers: commonHeaders,
        })

        // Extract data from response if needed
        const resultData = typeof extractItemsFn === 'function' ? extractItemsFn(response.data) : response.data

        // Wrapping in 'Array' for make typings compatible for case with 'id' and without
        return id ? [resultData] as unknown as ResultItem[] : resultData
    }

    async function fetchItemById ({ id }) {
        const requestUrl = `${url}/${id}/`

        const response = await http.get!<ResultItem>(requestUrl, {
            headers: commonHeaders,
        })

        return response.data
    }

    async function downloadCSVData ({ queryParams, filter, id, fileName }: DownloadCSVDataParams<GetItemsQueryParams>) {
        const additionalPath = id ? `${id}/csv` : 'csv'
        const filterString = filter?.URLSearchParams.toString() ?? ''
        const requestUrl = `${url}/${additionalPath}/?${filterString}`
        const fullFileName = fileName ?? `${url}.csv`

        const { data } = await http.get!<string>(requestUrl, {
            params: {
                ...queryParams,
            },
            meta: {
                errorToast: errorMessageFetchCSV,
            },
            headers: commonHeaders,
        })

        if (!data) {
            notify.error('No data to download')
        } else {
            const packagedFile = new Blob([data], { type: 'text/plain' })
            Downloader.download(fullFileName, packagedFile)
        }
    }

    function useDownloadCSV ({ queryParams, filter, id, fileName, fsEventName }: DownloadCSVDataParams<GetItemsQueryParams>) {
        const [isDownloading, setIsDownloading] = useState(false)
        return {
            downloadCSV: async function () {
                setIsDownloading(true)
                try {
                    await downloadCSVData({ queryParams, filter, id, fileName, fsEventName })
                    fsDownloadEvent(fsEventName)
                } finally {
                    setIsDownloading(false)
                }
            },
            isDownloading,
        }
    }

    // TODO: Suppress error toast for this fetch?
    async function fetchItemsCount ({ queryParams, filterString }: FetchItemsParams<GetItemsQueryParams>) {
        const paginationParams: Record<string, number> = { }

        const requestUrl = `${url}/count/?${filterString}`

        const response = await http.get!<number>(requestUrl, {
            params: {
                ...queryParams,
                ...paginationParams,
            },
            headers: commonHeaders,
        })

        return response.data
    }

    async function updateItemById ({ id, updatedItemData, config }: UpdateByIdParams<ResultItem>) {
        const { suppressErrorToast = false } = config ?? {}

        const requestUrl = `${url}/${id}/`

        const response = await http.patch!<ResultItem>(
            requestUrl,
            updatedItemData,
            {
                meta: {
                    errorToast: suppressErrorToast ? undefined : errorMessageUpdate,
                },
                headers: commonHeaders,
            },
        )

        return response.data
    }

    function useItemsQuery (
        customParams?: UseQueryCustomParams<GetItemsQueryParams>,
        queryOptions?: Omit<UseQueryOptions<Promise<ResultItem[]>, AxiosError, ResultItem[]>, 'queryKey'>,
    ) {
        const {
            pageKey,
            queryParams,
            filter,
            pageLimit,
            pageOffset,
            id,
            sortBy = [],
        } = customParams ?? {}

        const queryOptionsUpdated: Omit<UseQueryOptions<Promise<ResultItem[]>, AxiosError, ResultItem[]>, 'queryKey'> = {
            // @ts-expect-error Can't figure out how to solve types conflict in this case
            initialData: [],
            /**
             * Don't retry by default
             */
            retry: false,
            /**
             * If 0 every new component mout it will be refetched
             * Different from cacheTime
             */
            staleTime: 60000,
            /**
             * Ignores staleTime for the first fetch
             */
            initialDataUpdatedAt: 0,
            ...(queryOptions ?? {}),
        }

        const filtersArr: Array<Filter | SingleFilter> = sortBy?.map((sort) => EQ('__order_by', sort)) ?? []
        if (filter) filtersArr.push(filter)
        const filtersCombined = filtersArr.length ? AND(...filtersArr) : undefined
        const filterString = filtersCombined?.URLSearchParams.toString() ?? ''

        return useQuery <Promise<ResultItem[]>, AxiosError, ResultItem[]>({
            queryKey: [url, pageKey, queryParams, filterString, pageOffset, pageLimit],
            queryFn: () => fetchItems({ queryParams, filterString, pageOffset, pageLimit, id }),
            ...queryOptionsUpdated,
        })
    }

    function useItemByIdQuery (
        id: string | number | undefined,
        queryOptions?: Omit<UseQueryOptions<Promise<ResultItem>, AxiosError, ResultItem>, 'queryKey'>,
    ) {
        const queryOptionsUpdated: Omit<UseQueryOptions<Promise<ResultItem>, AxiosError, ResultItem>, 'queryKey' > = {
            ...(queryOptions ?? {}),
        }

        return useQuery <Promise<ResultItem>, AxiosError, ResultItem>({
            queryKey: [url, id],
            queryFn: () => fetchItemById({ id }),
            enabled: !!id, // For the case if id asynchronous
            ...queryOptionsUpdated,
        })
    }

    function useItemsCountQuery (
        customParams?: UseQueryCustomParams<GetItemsQueryParams>,
        // TODO: Don't duplicate types
        queryOptions?: Omit<UseQueryOptions<Promise<number>, AxiosError, number> | undefined, 'queryKey'>,
    ) {
        const { pageKey, queryParams, filter } = customParams ?? {}

        const queryOptionsUpdated: UseQueryOptions<Promise<number>, AxiosError, number> = {
        // @ts-expect-error Can't figure out how to solve types conflict in this case
            initialData: 0,
            ...(queryOptions ?? {}),
        }

        const filterString = filter?.URLSearchParams.toString() ?? ''

        return useQuery <Promise<number>, AxiosError, number>({

            // @ts-expect-error TS(2783) FIXME: 'queryKey' is specified more than once, so this us... Remove this comment to see the full error message
            queryKey: ['count', url, pageKey, queryParams, filter, filterString],
            queryFn: () => fetchItemsCount({ queryParams, filterString }),
            ...queryOptionsUpdated,
        })
    }

    const useItemUpdateMutation = ({ disableItemsInvalidation = false }: UseItemUpdateMutationParams = {}) => {
        const queryClient = useQueryClient()

        return useMutation({
            mutationFn: updateItemById,
            onSuccess: () => {
                if (!disableItemsInvalidation) {
                    queryClient.invalidateQueries({ queryKey: disableItemsInvalidation ? [] : [url] })
                    queryClient.invalidateQueries({ queryKey: invalidateAfterUpdate })
                }
            },
        })
    }

    const createItem = async (data: Partial<ResultItem>) => {
        const response = await http.post!<ResultItem>(
            url + '/',
            data,
            {
                meta: {
                    errorToast: errorMessageCreate,
                },
                headers: commonHeaders,
            },
        )

        return response.data
    }

    const useItemCreateMutation = () => {
        const queryClient = useQueryClient()

        return useMutation({
            mutationFn: createItem,
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [url] })
                queryClient.invalidateQueries({ queryKey: invalidateAfterUpdate })
            },
        })
    }

    async function deleteItemById ({ id }: { id: string | number }) {
        const requestUrl = `${url}/${id}/`
        await http.delete?.(requestUrl, {
            headers: commonHeaders,
            meta: {
                errorToast: errorMessageDelete,
            },
        })
    }

    const useItemDeleteMutation = () => {
        const queryClient = useQueryClient()

        return useMutation({
            mutationFn: deleteItemById,
            onSuccess: () => {
                queryClient.invalidateQueries({ queryKey: [url] })
                queryClient.invalidateQueries({ queryKey: invalidateAfterUpdate })
            },
        })
    }

    return {
        useItemsCountQuery,
        useItemsQuery,
        useItemByIdQuery,
        useItemDeleteMutation,
        useItemUpdateMutation,
        useItemCreateMutation,
        useDownloadCSV,
        fetchItems,
    }
}
