import { Model, model, field, foreign } from 'mobx-orm'

import { AccountingSystem } from '@/constants/accountingSystems'
import { Asset } from '@/models/asset'
import { CompanyUser } from '@/models/company'

import { api } from '../../http-adapter'

import { SubmittedFileError } from './SubmittedFileError'

export enum SubmittedFileType {
    ASSETS =                          'A',
    TRIAL_BALANCE_CHART_OF_ACCOUNTS = 'C',
    TRIAL_BALANCE_ASSET_MAPPING =     'M',
    TRIAL_BALANCE_DATA =              'T',
    TRIAL_BALANCE_BUDGET =            'B',
    RENT_ROLL_CHART_OF_ACCOUNTS =     'Z',
    RENT_ROLL_ASSET_MAPPING =         'X',
    RENT_ROLL_DATA =                  'V',
    UNRECOGNIZED =                    'U',
}

export enum SubmittedFileStatus {
    INITIAL =      'I',
    INITIAL_ETL =  'N',
    PARSING =      'P',
    PARSING_ETL =  'T',
    DONE =         'D',
    WRONG_FORMAT = 'F',
    WRONG_DATA =   'X',
    ERROR =        'E',
    CANCELED =     'C',
}

/**
 * For use without mobx-orm model
 */
export const isSubmittedFileProcessingFinished = (status: SubmittedFileStatus) => {
    return (status === SubmittedFileStatus.DONE ||
            status === SubmittedFileStatus.WRONG_FORMAT ||
            status === SubmittedFileStatus.WRONG_DATA ||
            status === SubmittedFileStatus.ERROR)
}

@api('data-submission')
@model
export class SubmittedFile extends Model {
    @field initial_file_name?:     string
    @field type?:                  SubmittedFileType
    @field source?:                string
    @field status?:                SubmittedFileStatus
    @field error_body?:            string
    @field created_at:             string
    @field asset_id?:              number
    @field company_user_id:        number
    @field report_date?:           string
    @field errors_num?:            number
    @field submission_errors_num?: number
    @field quality_errors_num?:    number
    @field data_point_id?:         number
    @field note?:                  string
    @field parent_id?:             number

    @foreign(Asset) readonly asset:              Asset
    @foreign(CompanyUser) readonly company_user: CompanyUser

    errors: SubmittedFileError[]

    static DATA_TYPES = [
        SubmittedFileType.TRIAL_BALANCE_DATA,
        SubmittedFileType.RENT_ROLL_DATA,
    ]

    static TYPE_VERBOSE = {
        [SubmittedFileType.ASSETS]:                          'Assets',
        [SubmittedFileType.TRIAL_BALANCE_CHART_OF_ACCOUNTS]: 'Trial balance: Chart of accounts',
        [SubmittedFileType.TRIAL_BALANCE_ASSET_MAPPING]:     'Trial balance: Asset mapping',
        [SubmittedFileType.TRIAL_BALANCE_DATA]:              'Trial balance: Data',
        [SubmittedFileType.TRIAL_BALANCE_BUDGET]:            'Trial balance: Budget',
        [SubmittedFileType.RENT_ROLL_CHART_OF_ACCOUNTS]:     'Rent roll: Chart of accounts',
        [SubmittedFileType.RENT_ROLL_ASSET_MAPPING]:         'Rent roll: Asset mapping',
        [SubmittedFileType.RENT_ROLL_DATA]:                  'Rent roll: Data',
    }

    static DATA_TYPE_VERBOSE = {
        [SubmittedFileType.TRIAL_BALANCE_DATA]: 'Trial Balance',
        [SubmittedFileType.RENT_ROLL_DATA]:     'Rent Roll',
        [SubmittedFileType.UNRECOGNIZED]:       'Other',
    }

    static getDataTypeVerbose (type: SubmittedFileType) {
        return this.DATA_TYPE_VERBOSE[type] ?? type
    }

    static getTypeVerbose (type: SubmittedFileType) {
        return this.TYPE_VERBOSE[type] ?? type
    }

    static ERROR_STATUSES = [
        SubmittedFileStatus.WRONG_FORMAT,
        SubmittedFileStatus.WRONG_DATA,
        SubmittedFileStatus.ERROR,
    ]

    static VALIDATION_ERROR_STATUSES = [
        SubmittedFileStatus.WRONG_FORMAT,
        SubmittedFileStatus.WRONG_DATA,
    ]

    static PROCESSING_STATUSES = [
        SubmittedFileStatus.INITIAL,
        SubmittedFileStatus.PARSING,
        SubmittedFileStatus.PARSING_ETL,
    ]

    static STATUS_VERBOSE = {
        [SubmittedFileStatus.INITIAL]:      'Initial',
        [SubmittedFileStatus.INITIAL_ETL]:  'Preprocessing (etl)',
        [SubmittedFileStatus.PARSING]:      'Processing',
        [SubmittedFileStatus.PARSING_ETL]:  'Processing (etl)',
        [SubmittedFileStatus.DONE]:         'Done',
        [SubmittedFileStatus.WRONG_FORMAT]: 'Error',
        [SubmittedFileStatus.WRONG_DATA]:   'Error',
        [SubmittedFileStatus.ERROR]:        'Internal error',
        [SubmittedFileStatus.CANCELED]:     'Canceled',
    }

    static getStatusVerbose (status: SubmittedFileStatus) {
        return this.STATUS_VERBOSE[status] ?? status
    }

    static SOURCE = {
        REAL_PAGE:   '3',
        YARDI:       '4',
        FLAT_FORMAT: '11',
        MRI:         '12',
        ENTRATA:     '13',
    }

    static SOURCE_VERBOSE = {
        [SubmittedFile.SOURCE.REAL_PAGE]:   AccountingSystem.RealPage,
        [SubmittedFile.SOURCE.YARDI]:       AccountingSystem.Yardi,
        [SubmittedFile.SOURCE.MRI]:         AccountingSystem.MRI,
        [SubmittedFile.SOURCE.ENTRATA]:     AccountingSystem.Entrata,
        [SubmittedFile.SOURCE.FLAT_FORMAT]: 'Flat File',
    }

    static get_source_verbose (source) {
        return source in SubmittedFile.SOURCE_VERBOSE ? SubmittedFile.SOURCE_VERBOSE[source] : source
    }

    is_processing_finished () {
        return (this.status === SubmittedFileStatus.DONE ||
            this.status === SubmittedFileStatus.WRONG_FORMAT ||
            this.status === SubmittedFileStatus.WRONG_DATA ||
            this.status === SubmittedFileStatus.ERROR)
    }

    is_done () {
        return this.status === SubmittedFileStatus.DONE
    }

    is_validation_error () {
        // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
        return SubmittedFile.VALIDATION_ERROR_STATUSES.includes(this.status)
    }

    get_data_submission_errors_num () {
        return this.submission_errors_num
    }

    get have_internal_error_status () {
        return this.status === SubmittedFileStatus.ERROR
    }

    get_data_quality_errors_num () {
        return this.quality_errors_num
    }
}

foreign(SubmittedFile, 'parent_id')(SubmittedFile.prototype, 'parent')
