import { useEffect, useState } from 'react'

import { EQ, Query, ValueType } from 'mobx-orm'

import { observer } from 'mobx-react-lite'
import { generatePath, useNavigate, useOutletContext, useParams } from 'react-router'

import { Alert, Confirm, SideModal } from '@/components/base'
import { Layout } from '@/components/containers'
import { StringInput } from '@/components/legacy/models/core'
import { ModelForm } from '@/components/legacy/models/core/ModelForm/ModelForm'
import { TrialBalanceAccountInput } from '@/components/legacy/models/trial-balance/inputs/account'
import { CompanyUserMode } from '@/core/modes'
import { Account as TrialBalanceAccount } from '@/models/ledger'
import { TrialBalanceTemplate, TrialBalanceTemplateMap } from '@/models/trial_balance_template'
import { notify } from '@/utils/notify'
import { getRouteConfig } from '@/utils/routing/getRouteConfig'

import { EDIT_TEMPLATE_MESSAGES } from '../MappingTemplateEditPage.constants'

const returnPath = getRouteConfig('ASSET_MAPPING_TEMPLATES_BY_ID', CompanyUserMode.Owner).path

export const MappingTemplateAddMappingPage = observer(() => {
    const navigate = useNavigate()
    const { templateId, mappingId } = useParams()
    const { templateMapQuery } = useOutletContext<any>()

    const [isSaving, setIsSaving] = useState(false)
    const [isPopupOpen, setIsPopupOpen] = useState(false)

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const template = TrialBalanceTemplate.get(parseInt(templateId)) as TrialBalanceTemplate
    // @ts-expect-error TS(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
    const [templateMap, setTemplateMap] = useState<TrialBalanceTemplateMap>(undefined)

    const [accountQuery] = useState(() => TrialBalanceAccount.getQuery(
        { filter: EQ('ledger_id', template.ledger_id, ValueType.NUMBER) },
    ) as Query<TrialBalanceAccount>)

    const isNew = mappingId === 'new'
    const goToTemplatePage = () => navigate(generatePath(returnPath, { templateId }), { replace: true })

    useEffect(() => {
        (async () => {
            if (isNew) {
                const tMap = new TrialBalanceTemplateMap()
                // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
                tMap.template_id = parseInt(templateId)
                setTemplateMap(tMap)
            } else {
                // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
                const tMap = await TrialBalanceTemplateMap.findById(parseInt(mappingId)) as TrialBalanceTemplateMap
                setTemplateMap(tMap)
            }
        })()
    }, [])

    const isSubmitDisabled = !(templateMap?.account_id && templateMap?.source_account_code && templateMap?.source_account_name)
    const isSaveDisabled = !templateMap?.is_changed

    const saveMapping = async () => {
        try {
            setIsSaving(true)
            await templateMap.save()
            notify.success(isNew ? EDIT_TEMPLATE_MESSAGES.added : EDIT_TEMPLATE_MESSAGES.changed)
            templateMapQuery.load()
            goToTemplatePage()
        } catch (e) {
            // TODO: show errors in form
            // templateMap.__errors?.code
            // templateMap.__errors?.__all__
        }
        setIsSaving(false)
    }

    const resetChanges = () => {
        templateMap.source_account_code = templateMap.__init_data.source_account_code
        templateMap.source_account_name = templateMap.__init_data.source_account_name
        templateMap.account_id = templateMap.__init_data.account_id
        goToTemplatePage()
    }

    const handleClose = () => {
        const isChanged = isNew
            ? templateMap?.account_id || templateMap?.source_account_code || templateMap?.source_account_name
            : templateMap.is_changed

        if (isChanged) {
            setIsPopupOpen(true)
            return
        }
        goToTemplatePage()
    }

    return (
        <>
            {isPopupOpen && (
                <Confirm
                    confirmText='Back to Editing'
                    onConfirm={() => setIsPopupOpen(false)}
                    cancelText='Close without Saving'
                    onCancel={resetChanges}
                    disableEscapeKeyDown
                    disableOverlayClick
                >
                    <Alert type='warning' content='You have unsaved changes'/>
                </Confirm>
            )}

            <SideModal
                isLoading={!templateMap}
                width={528}
                title={isNew ? 'Add New Account Mapping' : 'Edit Mapping'}
                onClose={handleClose}
                submitButton={{
                    text: isNew ? 'Add Mapping' : 'Save Changes',
                    loading: isSaving,
                    disabled: isNew ? isSubmitDisabled : isSaveDisabled,
                    onClick: saveMapping,
                }}
                haveCloseButton={templateMap?.is_changed && !isNew}
                cancelButton={{
                    text: 'Reset Changes',
                    disabled: isSaving,
                    onClick: resetChanges,
                }}
            >
                <ModelForm obj={templateMap}>
                    <Layout mb={24}>
                        <StringInput field='source_account_code'label='Source Account Code'/>
                    </Layout>
                    <Layout mb={24}>
                        <StringInput field='source_account_name'label='Source Account Name'/>
                    </Layout>
                    <TrialBalanceAccountInput
                        options={accountQuery}
                        label='Map to Target Account'
                        placeholder='Unmapped'
                    />
                </ModelForm>
            </SideModal>
        </>
    )
})
