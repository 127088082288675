import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Alert, Button, Icon, Text } from '@/components/base'
import { Layout } from '@/components/containers'
import { LogType, TrialBalanceTemplateUploadStatus, TemplateUploadLog } from '@/models/trial_balance_template'

import { UPLOAD_STATUS_TEXT } from './TBTemplateUploadStatus.constants'
import { TBTemplateUploadStatusProps } from './TBTemplateUploadStatus.types'

import styles from './TBTemplateUploadStatus.module.scss'

export const TBTemplateUploadStatus = observer((props: TBTemplateUploadStatusProps) => {
    const { obj } = props
    const { status, logs } = obj

    const fileName = typeof obj.file === 'string' ? obj.original_name : 'filename'

    const isValidating = status === TrialBalanceTemplateUploadStatus.UPLOADED
    const isChecking = status === TrialBalanceTemplateUploadStatus.CHECKING
    const isApplying = status === TrialBalanceTemplateUploadStatus.APPLYING
    const isSuccessful = status === TrialBalanceTemplateUploadStatus.VALID ||
        status === TrialBalanceTemplateUploadStatus.CHECKING ||
        status === TrialBalanceTemplateUploadStatus.READY ||
        status === TrialBalanceTemplateUploadStatus.APPLYING ||
        status === TrialBalanceTemplateUploadStatus.DONE

    const alertType = isSuccessful ? 'success' : 'error'
    const buttonText = isSuccessful ? 'Choose Different File' : 'Replace File'
    const inProgressText = isValidating ? UPLOAD_STATUS_TEXT[status] : 'Checking the file for compliance with the ledger,'

    const fileNameText = (
        <Text
            text={fileName}
            variant='labelSemiboldDefault'
            color='colorsSecondaryGrey400'
            block
        />
    )

    const getFileLogs = () => {
        let uploadLogs: TemplateUploadLog[] = []

        if (status === TrialBalanceTemplateUploadStatus.ERROR) {
            Object.keys(logs ?? {}).forEach(step => {
                uploadLogs = [
                    ...uploadLogs,
                    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                    ...(logs[step].filter((log: TemplateUploadLog) => log.level === LogType.ERROR)),
                ]
            })
        } else if (status === TrialBalanceTemplateUploadStatus.VALID) {
            uploadLogs = logs?.validation ?? []
        } else if (status === TrialBalanceTemplateUploadStatus.READY) {
            uploadLogs = logs?.checking ?? []
        } else {
            uploadLogs = logs?.applying ?? []
        }

        return uploadLogs.map((log, index) => {
            const color = log.level === LogType.ERROR
                ? 'colorsTetrialRed400'
                : log.level === LogType.WARNING ? 'colorsTetrialOrange400' : 'colorsSecondaryGrey400'

            return (
                <Text
                    key={index}
                    text={log.text}
                    block
                    color={color}
                    variant='labelMediumDefault'
                />
            )
        })
    }

    const inProgressBlock = (
        <>
            <Layout mb={16}>
                <Icon name='loader' size='m'/>
            </Layout>
            <Text
                text={fileName}
                variant='labelSemiboldDefault'
                color='primaryPurple'
                alignCenter
            />
            <Text
                text={inProgressText}
                variant='labelMediumDefault'
                color='primaryPurple'
                alignCenter
            />
            <Text
                text='Please Wait'
                variant='labelMediumDefault'
                color='primaryPurple'
                alignCenter
            />
        </>
    )

    return (
        <div className={cn(styles.container, { [styles.isValidating]: isValidating })}>
            {isValidating ? (inProgressBlock) : (
                <>
                    <Alert type={alertType} content={status && UPLOAD_STATUS_TEXT[status]} className={styles.alert}/>
                    <Button
                        onClick={() => { obj.resetFile() }}
                        theme='secondary'
                        text={buttonText}
                        disabled={isApplying}
                    />

                    <div className={cn(styles.logs, { [styles.isChecking]: isChecking })}>
                        {isChecking ? (inProgressBlock) : (
                            <>
                                {fileNameText}
                                <br/>
                                {getFileLogs()}
                            </>
                        )}
                    </div>
                </>
            )}
        </div>
    )
})
