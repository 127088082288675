import { Model } from 'mobx-orm'

import { observer } from 'mobx-react-lite'

import { Icon, IconName, Text } from '@/components/base'

import './multi-select-input.scss'

interface MultiSelectValuesProps<T extends Model> {
    ids: number[]
    model: any // TODO: should be a class of T
    label: (obj: T) => string
    onClick: (event) => void
    all_values_str: string
    icon?: IconName
}

const MultiSelectValues = observer(<T extends Model>(props: MultiSelectValuesProps<T>) => {
    const { ids, model, label, onClick, all_values_str, icon } = props
    let title
    if (ids.length === 0) {
        title = all_values_str
    } else if (ids.length > 1) {
        title = ids.length + ' filters'
    } else if (ids.length === 1) {
        const obj = model.get(ids[0])
        if (obj) title = label(obj)
    }

    return (
        <div className='intelas-multiselect-input' onClick={onClick}>
            <div className='current-value'>
                {!!icon && <Icon name={icon} style={{ width: 20, height: 16 }}/>}
                <Text
                    block
                    className='value_container'
                    color='secondaryBlack'
                    textOverflow='ellipsis'
                    elementTitle={title}
                >
                    {title}
                </Text>
                <div className='sep'/>
                <Icon name='arrowDown' style={{ width: 20, height: 20 }}/>
            </div>
        </div>
    )
})

export default MultiSelectValues
