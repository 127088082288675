import { model, field, many, foreign } from 'mobx-orm'

import { HistoryModel } from '@/models/core/HistoryModel'

import { api } from '../../http-adapter'
import { CompanyUser } from '../company'
import { AccessLevel } from '../core'

import { CompanyUserInTemplate } from './CompanyUserInTemplate'
import { Template } from './Template'

@api('company-user-in-csv-template-history')
@model
export class CompanyUserInTemplateHistory extends HistoryModel {
    @field readonly company_user_in_template_id: number
    @field readonly template_id:                 number
    @field readonly level:                       number
    @field readonly company_user_id:             number

    @foreign(Template) readonly template:        Template
    @foreign(CompanyUser) readonly company_user: CompanyUser

    get level_name (): string {
        return AccessLevel[this.level]
    }
}
many(CompanyUserInTemplateHistory, 'company_user_in_template_id')(CompanyUserInTemplate, 'history')
