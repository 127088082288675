import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Button, Text, TextColor } from '@/components/base'

import ExternalGradient1 from './assets/bgGradient_1.svg'
import ExternalGradient2 from './assets/bgGradient_2.svg'
import { ErrorPageBaseProps, ErrorPageBaseType } from './ErrorPageBase.types'

import styles from './ErrorPageBase.module.scss'

const titleColorByType: Record<ErrorPageBaseType, TextColor> = {
    external: 'secondaryWhite',
    internal: 'secondaryBlack',
}

const textColorByType: Record<ErrorPageBaseType, TextColor> = {
    external: 'secondaryGray',
    internal: 'secondaryLightBlack',
}

export const ErrorPageBase = observer((props: ErrorPageBaseProps) => {
    const {
        className,
        errorText,
        ErrorIcon,
        title,
        text,
        onButtonClick,
        buttonText,
        errorName,
        rounded = false,
        type = 'internal',
        noBackground = false,
    } = props

    const errorPageClassName = cn(styles.errorPageBase, className, {
        [styles['type_' + type]]: type,
        [styles.rounded]: rounded,
        [styles.noBackground]: noBackground,
    })

    const infoBlock = (
        <div className={styles.info}>
            <Text
                text={errorName}
                variant='smallTextSemiboldDefault'
                color='primaryPurple'
            />
            <Text
                text={title}
                color={titleColorByType[type]}
                variant='header'
            />
            <Text
                variant='bodyMedium'
                color={textColorByType[type]}
                text={text}
            />
            {(onButtonClick && buttonText) && (
                <Button
                    text={buttonText}
                    onClick={onButtonClick}
                    size='l'
                />
            )}
        </div>
    )

    const imageBlock = (
        <span className={styles.number}>
            {
                errorText || (ErrorIcon ? (
                    <ErrorIcon
                        className={styles.icon}
                    />
                ) : null)
            }
        </span>
    )

    return (
        <div className={errorPageClassName}>
            {
                type === 'external' && (
                    <>
                        <ExternalGradient1 className={styles.externalGradient_1}/>
                        <ExternalGradient2 className={styles.externalGradient_2}/>
                    </>
                )
            }
            <div className={styles.content}>
                {
                    type === 'internal' ? (
                        [infoBlock, imageBlock]
                    ) : (
                        [imageBlock, infoBlock]
                    )
                }
            </div>
        </div>
    )
})
