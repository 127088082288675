import { useEffect, useRef } from 'react'

import { BaseModal, Button, Icon, Loader, Text } from '@/components/base'

import { popupTestIds } from './Popup.constants'
import { PopupProps } from './Popup.types'

import styles from './Popup.module.scss'

/**
 * Classic Popup with title, content and buttons
 * (by default, close on overlay click and escape key press)
 */
export const Popup = (props: PopupProps) => {
    const {
        content,
        maxWidth,
        onClose,
        onConfirm,
        title = null,
        confirmText = 'Confirm',
        cancelText = 'Cancel',
        isLoading = false,
        noButtons = false,
        noCancel = false,
        noControls = false,
        disableEscapeKeyDown = false,
        disableOverlayClick = false,
        open = true,
    } = props

    const confirmButtonRef = useRef<HTMLButtonElement>(null)

    useEffect(() => {
        const handleKeyDown = (e: KeyboardEvent) => {
            if (e.key === 'Escape' && !disableEscapeKeyDown) {
                onClose?.()
            }
        }

        document.addEventListener('keydown', handleKeyDown)

        return () => {
            document.removeEventListener('keydown', handleKeyDown)
        }
    }, [])

    useEffect(() => {
        if (confirmButtonRef.current) {
            confirmButtonRef.current.focus()
        } else if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur()
        }
    }, [])

    if (!open) {
        return null
    }

    return (
        <BaseModal
            className={styles.popup}
            maxWidth={maxWidth}
            onOverlayClick={disableOverlayClick ? undefined : onClose}
        >
            <div className={styles.container}>
                {isLoading && (
                    <div className={styles.loadingOverlay}>
                        <Loader/>
                    </div>
                )}
                {!noControls && (
                    <div className={styles.head}>
                        <Text
                            text={title}
                            variant='subheader'
                            color='secondaryBlack'
                            className={styles.title}
                        />
                        <button
                            className={styles.closeButton}
                            onClick={onClose}
                            data-testid={popupTestIds.cross}
                        >
                            <Icon name='cross' size='l'/>
                        </button>
                    </div>
                )}

                {typeof content === 'string'
                    ? (
                        <Text
                            block
                            text={content}
                            variant='bodyMedium'
                            color='secondaryLightBlack'
                            className={styles.text}
                        />
                    )
                    : content
                }

                {!(noButtons || noControls) && (
                    <div className={styles.buttons}>
                        {!noCancel && (
                            <Button
                                text={cancelText}
                                onClick={onClose}
                                dataTestId={popupTestIds.cancel}
                                theme='secondary'
                            />
                        )}
                        <Button
                            text={confirmText}
                            onClick={onConfirm}
                            dataTestId={popupTestIds.confirm}
                            ref={confirmButtonRef}
                        />
                    </div>
                )}
            </div>
        </BaseModal>
    )
}
