import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'

import * as Sentry from '@sentry/react'

import { IntelasStatusCode } from '@/constants/intelasStatusCodes'
import { notify } from '@/utils/notify'

interface CustomAxiosRequestConfig<T = undefined> extends AxiosRequestConfig<T> {
    meta?: {
        /**
         * Alternative message instead of default backend message
         */
        errorToast?: string
    }
}

export const isAuthError = (error: AxiosError): boolean => {
    return (
        error.response?.status === 401 ||
        error.response?.status === 403 ||
        // Django REST framefork does not return 401 if credentials were not privided, it returns special message into data detail
        error.response?.data?.detail === 'Authentication credentials were not provided.'
    )
}

export const isValidationError = (error: AxiosError): boolean => {
    return error.response != null &&
        error.response.status === 400 &&
        parseInt(error.response.headers['intelas-status-code']) === IntelasStatusCode.formValidationError
}

const isCancelError = (error: AxiosError): boolean => {
    // TODO: should we check status?
    return error.message === 'canceled'
}

// TODO: Add custom error message
axios.defaults.xsrfHeaderName = 'X-CSRFToken'
axios.defaults.xsrfCookieName = 'csrftoken'
axios.defaults.withCredentials = true
axios.defaults.baseURL = '/api'
axios.interceptors.request.use((config: CustomAxiosRequestConfig) => {
    config.meta = config.meta || {}
    return config
})
axios.interceptors.response.use(
    response => {
        return response
    },
    // TODO: Refactore this error handling. Looks really messy now.

    error => {
        if (!isAuthError(error) && !isCancelError(error)) {
            const toastMessage = error.config?.meta?.errorToast
            const errorMessage = error.message?.length ? error.message : 'Request failed'

            /**
             * This error can happen after coming back to old tab after some time
             * We need to wait and see if this case will be reproduced in Sentry by flag 'Auth0RefreshTokenProblem'
             * Because updates in Auth0 configuration can fix it as well
             * If no errors after 01.12.23 we can remove this flag
             * @see WEBDEV-915
            */
            const isAuth0Error = errorMessage.includes('Missing Refresh Token')
            const isNetworkError = errorMessage === 'Network Error' && error?.isAxiosError

            Sentry.configureScope(function (scope) {
                scope.setTag('httpServiceError', true)
                scope.setTag('Auth0RefreshTokenProblem', isAuth0Error)
                scope.setContext('errorInfo', {
                    toastMessage,
                    isAxiosError: JSON.stringify(!!error?.isAxiosError),
                    responseConfig: JSON.stringify(error?.response?.config),
                    responseData: JSON.stringify(error?.response?.data),
                })
                if (isNetworkError) {
                    scope.setTag('isNetworkError', isNetworkError)
                    scope.setTag('isValidationError', isValidationError(error))
                }
            })

            if (isAuth0Error) {
                window.location.reload()
            } else {
                if (toastMessage) {
                    notify.error(toastMessage)
                }
            }
        }
        throw error
    })

/**
 * Alternative extended interface for axios methods
 */
interface CustomAxiosStatic extends AxiosInstance {
    get: <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>) => Promise<R>
    delete: <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>) => Promise<R>
    post: <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>) => Promise<R>
    patch: <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>) => Promise<R>
}

/**
 * Wrappers with extra parameters
 */
const http: Partial<CustomAxiosStatic> = {
    ...axios,
    get: <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>): Promise<R> => {
        return axios.get<T, R>(url, config)
    },
    post: <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R> => {
        return axios.post<T, R>(url, data, config)
    },
    patch: <T = any, R = AxiosResponse<T>, D = any>(url: string, data?: D, config?: CustomAxiosRequestConfig<D>): Promise<R> => {
        return axios.patch<T, R>(url, data, config)
    },
    delete: <T = any, R = AxiosResponse<T>, D = any>(url: string, config?: CustomAxiosRequestConfig<D>): Promise<R> => {
        return axios.delete<T, R>(url, config)
    },
}
export default http
