import { CompanyUserActiveStatusCell } from '@/components/legacy/models/company/table/CompanyUserActiveStatusCell'
import { AgGridTableProps } from '@/components/tables'

import { CompanyMembersSubpageActionsCell } from './CompanyMembersSubpageActionsCell'

import styles from './CompanyMembersSubpage.module.scss'

export const gridOptions: Omit<AgGridTableProps, 'query' | 'items'> = {
    rowClass: styles.tableRow,
    columnDefs: [
        {
            field: 'name',
            headerName: 'Name',
            type: 'string',
            valueGetter: (params) => {
                const name = [params.data.first_name, params.data.last_name].filter(el => Boolean(el)).join(' ')

                return name.length ? name : '—'
            },
        },
        {
            field: 'email',
            type: 'string',
            headerName: 'Email',
        },
        // {
        //     field: 'role',
        //     headerName: 'Role',
        //     cellRenderer: PermissionsCellComponent,
        //     cellRendererParams: {
        //         value: AccessLevel.Admin
        //     }
        // },
        {
            field: 'is_active',
            headerName: 'Status',
            cellRenderer: CompanyUserActiveStatusCell,
        },
        {
            type: 'actions',
            headerName: 'Actions',
            cellRenderer: CompanyMembersSubpageActionsCell,
        },
    ],
}
