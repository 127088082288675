import React, { useEffect, useMemo, useState } from 'react'

import subMonths from 'date-fns/subMonths'
import { AND, ILIKE, ValueType } from 'mobx-orm'

import { Tooltip } from '@mui/material'
import { observer } from 'mobx-react-lite'

import { Button, DatePicker, Icon, Text } from '@/components/base'
import { Layout, PageLayout } from '@/components/containers'
import { DEFAULT_FILTER_MAX_WIDTH } from '@/constants/filters'
import { useQuery } from '@/hooks/useQuery'
import { Asset } from '@/models/asset'
import { AssetTBSourceReadiness } from '@/models/asset_trial_balance/AssetTBSourceReadiness'
import { UploadsDataSyncFilters, UploadsDataSyncTable } from '@/pages/UploadsPage'
import { arrayRmDuplicates } from '@/utils/array/ArrayRmDuplicates'
import { dateToMonthInd } from '@/utils/date/monthInd'
import { notify } from '@/utils/notify'

export const UploadsDataSyncPage = observer(() => {
    const filterAssetsByName = useMemo(() => ILIKE('name', undefined, ValueType.STRING), [])

    const assetsFilter = useMemo(() => AND(
        filterAssetsByName,
    ), [])

    const assetsQuery = useQuery(Asset, { filter: assetsFilter }, { isReady: true })
    const assetTBSourceReadinessQuery = useQuery(AssetTBSourceReadiness)

    const [selectedIds, setSelectedIds] = useState<number[]>([])
    const [date, setDate] = useState<Date>(() => subMonths(new Date(), 1))
    const [submitPending, setSubmitPending] = useState(false)

    useEffect(() => {
        assetTBSourceReadinessQuery.load()

        return () => {
            setSelectedIds([])
        }
    }, [])

    const handleToggle = (asset: Asset, checked?: boolean) => {
        if (checked === undefined) {
            // @ts-expect-error TS(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            checked = !selectedIds.includes(asset.id)
        }

        if (checked) {
            // @ts-expect-error TS(2769) FIXME: No overload matches this call.
            setSelectedIds(prev => arrayRmDuplicates(prev.concat(asset.id)))
        } else {
            setSelectedIds(prev => arrayRmDuplicates(prev.filter(item => item !== asset.id)))
        }
    }

    const handleSubmit = async () => {
        const idsToModify: number[] = []

        // Filter already created
        const filteredIds = selectedIds.filter(assetId => {
            const isExist = !assetTBSourceReadinessQuery.items.find(item => (
                item.asset_id === assetId && item.month_ind === dateToMonthInd(date)
            ))

            if (!isExist) {
                idsToModify.push(assetId)
            }

            return isExist
        })

        const promisesModified = idsToModify.map(assetId => {
            const obj = assetTBSourceReadinessQuery.items.find((item) => item.asset_id === assetId && item.month_ind === dateToMonthInd(date))

            if (!obj) {
                return Promise.resolve()
            } else {
                obj.is_ready = true
                return obj.save()
            }
        })

        const promisesCreated = filteredIds.map(assetId => (
            new AssetTBSourceReadiness({
                asset_id: assetId,
                month_ind: dateToMonthInd(date),
                is_ready: true,
            }).save()
        ))

        setSubmitPending(true)
        try {
            await Promise.all([...promisesCreated, ...promisesModified])
            setSelectedIds([])
            notify.success(<>Data sync has been scheduled.<br/> Full processing may take up to 12 hours depending on your accounting system</>)
        } catch (error) {
            notify.error('Unexpected error. Try again later.')
        } finally {
            setSubmitPending(false)
        }
    }

    return (
        <PageLayout>
            <Layout
                justify='space-between'
                align='center'
                gap={32}
            >
                <Layout direction='column' gap={8}>
                    <Text variant='header' color='colorsPrimaryGrey'>Trial Balance Data</Text>
                    <Text
                        color='colorsSecondaryGrey400'
                        variant='labelMediumDefault'
                        style={{ maxWidth: 460 }}
                    >
                    Trial balance data will be synced from your accounting system to Intelas. Select the appropriate <b>assets</b> and <b>reporting period</b> to sync
                    </Text>
                </Layout>
                <Layout gap={16} align='center'>
                    <Layout align='center'>
                        <Tooltip
                            arrow
                            title='The reporting period is related to the specific month for which the trial balance is being synced. A monthly trial balance should only be synced once books have been closed and all entries reviewed'
                            placement='left-start'
                        >
                            <span style={{ display: 'flex' }}>
                                <Icon name='info' color='colorsPrimaryPurple' size='l'/>
                            </span>
                        </Tooltip>
                        <Layout ml={8}>
                            <DatePicker
                                date={date}
                                onChange={(date) => date && setDate(date)}
                                type='month'
                                labelInside
                                label='Reporting Period'
                                width={DEFAULT_FILTER_MAX_WIDTH}
                                icon='arrowDown'
                                rotateIcon
                                maxDate={subMonths(new Date(), 1)}
                                minDate={subMonths(new Date(), 3)}
                            />
                        </Layout>
                    </Layout>
                    <Button
                        theme='primary'
                        onClick={handleSubmit}
                        text='Start Sync'
                        disabled={!selectedIds.length}
                        loading={submitPending}
                    />
                </Layout>
            </Layout>
            <Layout mt={16} mb={16}>
                <UploadsDataSyncFilters filterAssetsByName={filterAssetsByName}/>
            </Layout>
            <UploadsDataSyncTable
                assetTBSourceReadinessQuery={assetTBSourceReadinessQuery}
                date={date}
                query={assetsQuery}
                selectedIds={selectedIds}
                toggleItem={handleToggle}
            />
        </PageLayout>
    )
})
