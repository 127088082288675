import { RoutesConfigValue } from '@/core/routes'

export type TablesRouteConfigKeys =
      'TABLES' |
      'TABLES_LIST' |
      'TABLE_BUILDER' |
      // temporary routes
      'TABLES_PIVOT_TB' |
      'TABLES_PIVOT_RR'

export type TablesRoutesConfig = {
    [key in TablesRouteConfigKeys]: RoutesConfigValue
}

export const TABLES_ROUTES_CONFIG: TablesRoutesConfig = {
    TABLES: {
        title: 'Tables',
        path: '/tables',
        featureFlags: ['table_builder_for_owners'],
        ignoreFeatureFlagsInAdminMode: true,
    },
    TABLES_LIST: {
        title: 'Tables',
        path: '/tables/list',
        featureFlags: ['table_builder_for_owners'],
        ignoreFeatureFlagsInAdminMode: true,
        metricData: {
            pageViewEventName: 'Table Builder View',
            pageName: 'Table Builder',
        },
    },
    TABLE_BUILDER: {
        title: 'Table Builder',
        path: '/tables/table-builder/:id',
        featureFlags: ['table_builder_for_owners'],
        ignoreFeatureFlagsInAdminMode: true,
        metricData: {
            pageViewEventName: 'Table Builder View',
            pageName: 'Table Builder Open Table',
        },
    },
    // Temporary routes
    TABLES_PIVOT_TB: {
        title: 'TB Facts',
        path: '/tables/pivot-tb',
    },
    TABLES_PIVOT_RR: {
        title: 'RR Facts',
        path: '/tables/pivot-rr',
    },
}
