import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'
import { AccountInGroup } from '../ledger'

import { AccountCategory } from './AccountCategory'
import { RentRollLedger as Ledger } from './Ledger'

@api('rent-roll-account')
@model
export class Account extends Model {
    @field  code?:        string // Name stored in this field now
    @field  ledger_id?:   number
    @field  category_id?: number
    @field  is_default?:  boolean

    @foreign(Ledger)          readonly ledger:   Ledger
    @foreign(AccountCategory) readonly category: AccountCategory

    readonly group_links?: AccountInGroup[]

    get fullName () {
        return this.code
    }

    get categories (): AccountCategory[] {
        return this.category ? [...this.category.parents.reverse(), this.category] : []
    }
}

many(Account, 'category_id')(AccountCategory, 'accounts')
