import React from 'react'

import { observer } from 'mobx-react-lite'

import { Icon, Text } from '@/components/base'

import { SelectTriggerCellProps } from './SelectTriggerCell.types'

import styles from './SelectTriggerCell.module.scss'

/**
 * Base trigger for table cell drop down
 * In the future can be extended to looks more like 'SelectTriggerBase'
 *
 * @todo fix text-ellipsis overflow
 */
export const SelectTriggerCell = observer((props: SelectTriggerCellProps) => {
    const {
        open = false,
        content = '',
        onClick,
        hideArrow = false,
    } = props

    return (
        <div className={styles.cell} onClick={onClick}>
            <Text
                className={styles.text}
                elementTitle={content}
                maxLines={1}
            >
                {content}
            </Text>
            {!hideArrow && (
                <Icon
                    className={styles.icon}
                    name='arrowDown'
                    color={open ? 'colorsPrimaryPurple' : 'colorsSecondaryGrey600'}
                />
            )}
        </div>
    )
})
