import { useContext, useEffect } from 'react'

import { runInAction } from 'mobx'

import { useAuth0 } from '@auth0/auth0-react'
import { observer } from 'mobx-react-lite'

import { Text, UploadArea } from '@/components/base'
import { Layout } from '@/components/containers'
import { TrialBalanceTemplateUpload, TrialBalanceTemplateUploadStatus } from '@/models/trial_balance_template'
import { setIntervalUntilAuthenticated } from '@/utils/setInterval'

import { ModelFormContext } from '../../core/ModelFromContext'

import { TBTemplateUploadStatus } from './TBTemplateUploadStatus'
import { TrialBalanceTemplateUploadFileInputProps } from './TrialBalanceTemplateUploadFileInput.types'

export const TrialBalanceTemplateUploadFileInput = observer((props: TrialBalanceTemplateUploadFileInputProps) => {
    let { obj, onFileSelect, ...rest } = props

    // TODO: don't use specific auth0 hook here, use some abstract service, like AuthService (or "me"?)
    let { isAuthenticated } = useAuth0()
    isAuthenticated = true

    const modelForm = useContext(ModelFormContext)
    if (obj === undefined) {
        obj = modelForm.obj as TrialBalanceTemplateUpload
    }

    useEffect(() => {
        // watch for changes from backend
        const idInterval = setIntervalUntilAuthenticated(() => {
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            if (obj.id !== undefined &&
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                obj.status !== undefined &&
               // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
               (obj.status === TrialBalanceTemplateUploadStatus.UPLOADED ||
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                obj.status === TrialBalanceTemplateUploadStatus.CHECKING ||
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                obj.status === TrialBalanceTemplateUploadStatus.APPLYING)) {
                // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
                obj.refresh()
            }
        }, 3000, isAuthenticated)
        return () => {
            clearInterval(idInterval)
        }
    }, [obj, obj.id, obj.status])

    const onFileSelectHandler = async (files: File[]) => {
        // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
        runInAction(() => { obj.file = files[0] })
        onFileSelect?.(files)
        try {
            // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
            await obj.save()
        } catch (e) {
            // ignore, errors should be in templateUpload.__errors or templateUpload.logs with status ERROR
            // TODO: who to hable the 500 error? http service should do it? what should we show on the page?
        }
    }

    return (
        <>
            {obj.status === undefined ? (
                <UploadArea
                    allowedFormats={['xlsx', 'csv']}
                    onFileSelect={onFileSelectHandler}
                    // TODO: where to put the error? obj.__errors['file']?
                    onError={() => {}}
                    inProgress={!!obj.status}
                    singleFile
                    fileTypesLabelInside
                    hideFileConstrains
                    {...rest}
                />
            ) : (
                <TBTemplateUploadStatus obj={obj}/>
            )}
            {/* TODO: finish the error message */}
            {obj.__errors?.file && (
                <Layout mt={4}>
                    <Text
                        text={obj?.__errors?.file}
                        variant='smallTextMediumDefault'
                        color='colorsTetrialRed600'
                        block
                    />
                </Layout>
            )}
        </>
    )
})
