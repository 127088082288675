import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'
import { CompanyUser } from '../company'
import { AccessLevel } from '../core'

import { Asset } from './Asset'

@api('company-user-in-asset')
@model
export class CompanyUserInAsset extends Model {
    @field company_user_id?: number
    @field asset_id?:        number
    @field level?:           AccessLevel

    @foreign(Asset) readonly asset:              Asset
    @foreign(CompanyUser) readonly company_user: CompanyUser

    get level_name (): string {
        // @ts-expect-error TS(2538) FIXME: Type 'undefined' cannot be used as an index type.
        return AccessLevel[this.level]
    }
}
many(CompanyUserInAsset, 'asset_id')(Asset, 'personal_permissions')
many(CompanyUserInAsset, 'company_user_id')(CompanyUser, 'asset_personal_permissions')
