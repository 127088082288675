import { Model, model, field, foreign } from 'mobx-orm'

import { api } from '../../http-adapter'
import { Account as TrialBalanceAccount } from '../ledger/Account'

import { TrialBalanceTemplate } from './TrialBalanceTemplate'

@api('trial-balance-template-map')
@model
export class TrialBalanceTemplateMap extends Model {
    @field template_id?:         number
    @field account_id?:          number
    @field source_account_code?: string
    @field source_account_name?: string

    @foreign(TrialBalanceTemplate) readonly template: TrialBalanceTemplate
    @foreign(TrialBalanceAccount) readonly account:   TrialBalanceAccount
}
