import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Icon } from '@/components/base'

import { iconNameByType } from './Alert.constants'
import { AlertProps } from './Alert.types'

import styles from './Alert.module.scss'

export const Alert = observer((props: AlertProps) => {
    const {
        className,
        contentClassName = null,
        content,
        type = 'info',
        size = 'l',
        contentCentered = false,
        children,
        flexGrow = undefined,
    } = props

    const iconSize = size === 'l' ? 'l' : 'm'

    const alertClassNames = cn(
        className,
        styles.alert,
        styles[`size_${size}`],
        styles[`type_${type}`],
        { [styles.centered]: contentCentered },
    )

    const contentClassNames = cn(
        contentClassName,
        styles.content,
    )

    return (
        <div className={alertClassNames} style={{ flexGrow }}>
            {iconNameByType[type] && (
                <Icon
                    className={styles.icon}
                    name={iconNameByType[type]}
                    size={iconSize}
                />
            )}
            <div className={contentClassNames}>{content ?? children}</div>
        </div>
    )
})
