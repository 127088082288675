import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'

import { AccountCategory } from './AccountCategory'
import { AccountInGroup } from './AccountInGroup'
import { TrialBalanceLedger as Ledger } from './Ledger'

/**
 * @todo: Rename to TrialBalanceAccount
 */
@api('account')
@model
export class Account extends Model {
    @field  code?:        string
    @field  name?:        string
    @field  ledger_id?:   number
    @field  category_id?: number

    @foreign(Ledger)          readonly ledger?:   Ledger
    @foreign(AccountCategory) readonly category?: AccountCategory

    readonly group_links?: AccountInGroup[]

    get fullName () {
        return `(${this.code}) ${this.name}`
    }

    get categories (): AccountCategory[] {
        return this.category ? [...this.category.parents.reverse(), this.category] : []
    }
}

many(Account, 'category_id')(AccountCategory, 'accounts')
