import React, { useState } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { useCompanyUserQuery } from '@/api/user/companyUser'
import { Text, Icon, UserLabel, LegacyPopover } from '@/components/base'
import { userFullname } from '@/utils/mobx-orm/userFullname'

import { UserNoteIconProps } from './UserNoteIcon.types'

import styles from './UserNoteIcon.module.scss'

export const UserNoteIcon = observer((props: UserNoteIconProps) => {
    const {
        className,
        icon = 'search',
        companyUserId,
        dateTime,
        text,
        testId = 'usernoteicon',
    } = props

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const companyUserQuery = useCompanyUserQuery()
    const companyUser = companyUserQuery.data?.find(el => el.id === companyUserId)

    const name = companyUser ? userFullname(companyUser) : ''

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleClose = () => {
        setAnchorEl(null)
    }

    const open = Boolean(anchorEl)

    return (
        <>
            <Icon
                name={icon}
                color='colorsSecondaryGrey600'
                onClick={handleClick}
                dataTestId={testId}
            />
            <LegacyPopover
                // Height of 10 items
                maxWidth={400}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
            >
                <div className={cn(styles.userNoteComment, className)}>
                    <div className={styles.header}>
                        <UserLabel name={name || ''} time={dateTime}/>
                    </div>
                    <Text
                        variant='smallTextMediumDefault'
                        text={text}
                    />
                </div>
            </LegacyPopover>
        </>
    )
})
