import { Text } from '@/components/base'
import { Layout } from '@/components/containers'

import { ListHeaderProps } from './ListHeader.types'

import styles from './ListHeader.module.scss'

export const ListHeader = (props: ListHeaderProps) => {
    const { content = [], endContent = null } = props

    return (
        <Layout
            className={styles.header}
            gap={8}
            px={12}
            py={12}
        >
            <Layout direction='column' flexGrow={1}>
                {content.map((text, index) => (
                    <Text
                        key={index}
                        text={text}
                        variant='labelMediumDefault'
                        color='colorsSecondaryGrey400'
                        block
                        elementTitle={text}
                        maxLines={1}
                    />
                ))}
            </Layout>
            {endContent}
        </Layout>
    )
}
