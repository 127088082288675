import { Button, Popup } from '@/components/base'

import { ConfirmProps } from './Confirm.types'

import styles from './Confirm.module.scss'

export const Confirm = (props: ConfirmProps) => {
    const {
        content = null,
        children = null,
        confirmText,
        cancelText,
        onConfirm,
        onCancel,
        isLoading = false,
        disableEscapeKeyDown = false,
        disableOverlayClick = false,
        open = true,
        buttonsGap = 'huge',
    } = props

    const popupContent = (
        <div className={styles.content}>
            <div
                className={styles.slot}
                style={{
                    marginBottom: buttonsGap === 'huge' ? 68 : buttonsGap === 'small' ? 16 : 0,
                }}
            >
                {children || content}
            </div>
            <div className={styles.buttons}>
                {Boolean(confirmText) && (
                    <Button
                        text={confirmText}
                        onClick={onConfirm}
                        loading={isLoading}
                        block
                    />
                )}
                {Boolean(cancelText) && (
                    <Button
                        text={cancelText}
                        onClick={onCancel}
                        theme='secondary'
                        disabled={isLoading}
                        block
                    />
                )}
            </div>
        </div>
    )

    return (
        <Popup
            open={open}
            content={popupContent}
            noControls
            maxWidth={648}
            disableEscapeKeyDown={disableEscapeKeyDown}
            disableOverlayClick={disableOverlayClick}
            onClose={onCancel}
        />
    )
}
