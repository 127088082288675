import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'
import { AccessLevel } from '../core'

import { CompanyUser } from './CompanyUser'
import { CompanyUserGroup } from './CompanyUserGroup'

/**
 * Information about users in groups
 */
@api('company-user-in-group')
@model
export class CompanyUserInCompanyUserGroup extends Model {
    @field company_user_group_id?: number
    @field company_user_id?:       number
    @field level:                  AccessLevel

    // order is important
    @foreign(CompanyUserGroup) readonly company_user_group: CompanyUserGroup
    @foreign(CompanyUser) readonly company_user:            CompanyUser

    get level_name (): string {
        return AccessLevel[this.level]
    }
}

many(CompanyUserInCompanyUserGroup, 'company_user_id')(CompanyUser, 'links')
many(CompanyUserInCompanyUserGroup, 'company_user_group_id')(CompanyUserGroup, 'links')
