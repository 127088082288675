import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Alert, Button, Loader, TextInput } from '@/components/base'
import { Layout, PageLayout } from '@/components/containers'
import http from '@/http.service'

import { ChatMessage, ChatMessageProps } from './ChatMessage'

import styles from './AiChatPage.module.scss'

export const AiChatPage = observer(() => {
    const [loadingResponse, setLoadingResponse] = useState<boolean>(false)
    const [conversationId, setConversationId] = useState<number>(-1)
    const [messages, setMessages] = useState<ChatMessageProps[]>([])

    const sendQuestion = useCallback((question: string) => {
        setLoadingResponse(true)
        // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
        http.post(
            'chat/',
            {
                question,
                ...(conversationId > 0 && { conversation_id: conversationId }),
            },
        ).then((response) => {
            setConversationId(response.data.conversation_id)
            setMessages((prev_messages) => [
                ...prev_messages,
                {
                    text: response.data.message,
                    user: 'AI Assistant',
                    ...(response.data.csv && { csv: response.data.csv }),
                    ...(response.data.img && { img: response.data.img }),
                },
            ])
        }).finally(() => {
            setLoadingResponse(false)
        })
    }, [JSON.stringify(messages), setMessages, conversationId, setConversationId, setLoadingResponse])

    const [inputText, setInputText] = useState<string>('')
    const onInputChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
        setInputText(e.target.value)
    }, [setInputText])
    const addUserMessage = useCallback(() => {
        setMessages([
            ...messages,
            {
                text: inputText,
                user: 'You',
            },
        ])
        sendQuestion(inputText)
        setInputText('')
    }, [JSON.stringify(messages), setMessages, inputText, setInputText, sendQuestion])
    const onInputKeyDown = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            addUserMessage()
        }
    }, [addUserMessage])
    const onSendClick = useCallback(() => {
        addUserMessage()
    }, [addUserMessage])

    const messagerContainerRef = useRef<HTMLDivElement>(null)
    const messagesLayout = useMemo(() => (
        <div ref={messagerContainerRef} className={cn(styles.messagesContainer)}>
            <Layout
                direction='column'
                gap={16}
            >
                {messages.map((message, index) => (
                    <ChatMessage
                        key={index}
                        {...message}
                    />
                ))}
                <Layout
                    className={cn(styles.loaderContainer)}
                >
                    {loadingResponse &&
                        <Loader/>
                    }
                </Layout>
            </Layout>
        </div>
    ), [JSON.stringify(messages), loadingResponse])

    const alertsLayout = useMemo(() => (
        <Layout
            direction='column'
            gap={16}
        >
            <Alert
                type='info'
                size='s'
                content='Monthly revenue increased by 12% compared to the budget, possibly due to higher than expected occupancy rates and rental increases.'
            />
            <Alert
                type='warning'
                size='s'
                content='Monthly expenses were 7% higher than budget, primarily due to unexpected maintenance costs and property management fees.'
            />
            <Alert
                type='info'
                size='s'
                content='Despite higher expenses this month, the unexpected increase in income resulted in a NOI 5% higher than budgeted.'
            />
            <Alert
                type='info'
                size='s'
                content='Due to the increase in income, cash flow is positive and 10% higher than expected for the month.'
            />
        </Layout>
    ), [])

    useEffect(() => {
        messagerContainerRef.current?.scrollTo({
            top: messagerContainerRef.current.scrollHeight,
            behavior: 'smooth',
        })
    }, [messagesLayout, messagerContainerRef.current])

    return (
        <PageLayout>
            <Layout
                direction='column'
                maxWidth={700}
                justify='space-between'
                className={cn(styles.chatMainContainer)}
            >
                {messages.length > 0 ? messagesLayout : alertsLayout}
                <Layout
                    gap={16}
                >
                    <TextInput
                        placeholder='Send message'
                        iconPos='end'
                        size='l'
                        value={inputText}
                        onChange={onInputChange}
                        onKeyDown={onInputKeyDown}
                        disabled={loadingResponse}
                    />
                    <Button
                        icon='arrowRight'
                        onClick={onSendClick}
                        theme='primaryInverted'
                        disabled={inputText.length === 0}
                        loading={loadingResponse}
                    />
                </Layout>
            </Layout>
        </PageLayout>
    )
})
