import { useEffect, useState } from 'react'

import { EQ } from 'mobx-orm'

import { observer } from 'mobx-react-lite'
import { useNavigate, useOutletContext, useParams } from 'react-router'

import { SideModal, Tabs } from '@/components/base'
import { DataPoint, DataPointType } from '@/models/data_pulling'
import { FileHistory } from '@/pages/MainPage/CommonPages/ValidationTrackerPage/DataPointSideModal/FileHistory'
import { FileInfo } from '@/pages/MainPage/CommonPages/ValidationTrackerPage/DataPointSideModal/FileInfo'
import { formatDate, formatMonthFull } from '@/utils/date/formatDate'
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate'
import { notify } from '@/utils/notify'

import { ValidationTrackerOutletContext } from '../ValidationTrackerPage.types'

import styles from './DataPointSideModal.module.scss'

const DataPointSideModal = observer(() => {
    const { dataPointId } = useParams()
    const navigate = useNavigate()
    const { onLoad } = useOutletContext<ValidationTrackerOutletContext>()

    // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
    const [dataPointQuery] = useState(() => DataPoint.getQuery({ filter: EQ('id', parseInt(dataPointId)) }))
    const [selectedTab, setSelectedTab] = useState('currentFile')

    useEffect(() => {
        dataPointQuery.load().then(() => onLoad?.())
    }, [])

    const onChange = (value) => {
        setSelectedTab(value)
    }

    let dataPoint
    let title = ''
    if (dataPointQuery.is_ready) {
        if (dataPointQuery.items.length === 0) {
            notify.error('Submission not found')
            navigate(`..?${new URLSearchParams(window.location.search)}`)
            return null
        }

        dataPoint = dataPointQuery.items[0] as DataPoint
        if (dataPoint.type === DataPointType.TRIAL_BALANCE) {
            title = `Trial Balance report for ${formatMonthFull(normalizeShortBackDate(dataPoint.report_date))}`
        } else {
            title = `Rent Roll report for ${formatDate(normalizeShortBackDate(dataPoint.report_date))}`
        }
    }

    return (
        <SideModal
            key='file-info'
            closePath={`..?${new URLSearchParams(window.location.search)}`}
            isLoading={!dataPointQuery.is_ready}
            width={700}
            haveCloseButton
            title={title}
            bodyNoPadding
        >
            <Tabs
                options={new Map([
                    ['currentFile', 'Current File'],
                    ['history', 'Submission History'],
                ])}
                value={selectedTab}
                onChange={onChange}
                className={styles.tabs}
            />
            {dataPointQuery.is_ready && selectedTab === 'currentFile' && <FileInfo dataPoint={dataPoint}/>}
            {dataPointQuery.is_ready && selectedTab === 'history' && <FileHistory dataPoint={dataPoint}/>}
        </SideModal>
    )
})

export { DataPointSideModal }
