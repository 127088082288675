import React, { createContext, useEffect, useRef, useState } from 'react'

import isNaN from 'lodash/isNaN'
import { AND, EQ } from 'mobx-orm'

import { getCurrentScope } from '@sentry/react'
import { useNavigate } from 'react-router'

import { useCompanyQuery } from '@/api/company/company'
import { useSettingsQuery } from '@/api/settings'
import { CompanyUserItem, useCompanyUserQuery } from '@/api/user/companyUser'
import { useMeQuery } from '@/api/user/me'
import { ContentWithMenu } from '@/components/containers/ContentWithMenu'
import {
    LOCAL_STORAGE_COMPANY_USER_ID_KEY,
    LOCAL_STORAGE_COMPANY_USER_MODE_KEY,
} from '@/components/containers/MeContext/MeContext.constants'
import { CompanyUserMode } from '@/core/modes'
import { COMMON_ROUTES_CONFIG } from '@/core/routes'
import http from '@/http.service'
import { User } from '@/models/core'

import { Me, MeContextProps } from './MeContext.types'

const initialState: Me = {
    availableCompanyUsersForPortal: [],
    availableModes: [],
    companyFeatures: {},
    isReady: false,
    hasError: false,
    hasAccessToPortal: true,
    isManagerMode: false,
    isOwnerMode: false,
    isAdministratorMode: false,
    // To make typings simpler and avoid unnecessary validation
} as unknown as Me

export const meContext = createContext<Me >(initialState)

/**
 *  @deprecated use 'useMe' hook instead
 */
export let me: Me = {} as Me

/**
 * All core user login related data
 *
 * Potentially functionlity can be splitted to smaller utils
 *
 * Testcases for this file:
 *
 * Switch mode:
 * - Switch to admin mode
 * - Switch to manager mode
 * - Switch to owner mode
 *
 * Local storage:
 * - After reloading company must be same
 * - Valid LOCAL_STORAGE_COMPANY_USER_ID_KEY value
 * - Invalid LOCAL_STORAGE_COMPANY_USER_ID_KEY value
 * - Valid LOCAL_STORAGE_COMPANY_USER_MODE_KEY value
 * - Invalid LOCAL_STORAGE_COMPANY_USER_MODE_KEY value
 *
 * Access error:
 * - Loading manager mode with no manager mode available for company user
 * - Loading owner mode with no owner mode available for company user
 * - No companies in response
 * - No company users in response
 * - No company users for portal
 */
export const MeContext = (props: MeContextProps) => {
    const [meObj, setMeObj] = useState<Me>(initialState)
    const [loading, setLoading] = useState(true)

    // Temporary oblect for collecting data between funcions and avoid re-renders
    // TODO: Refactor to more obvious logic
    const tempMe = useRef<Me>(initialState)

    const navigate = useNavigate()

    const companyQuery = useCompanyQuery({
        filter: EQ('__relations', 'features'),
    })
    const meQuery = useMeQuery()

    const user = meQuery.data?.[0]

    const companyUserQuery = useCompanyUserQuery({
        filter: AND(
            EQ('user_id', user?.id),
            EQ('is_active', true),
        ),
    }, {
        enabled: meQuery.isFetched,
    })

    const settingsQuery = useSettingsQuery({}, {
        // Need to have headers set before this request
        enabled: !loading,
    })

    const saveCompanyUserAvailableModes = (user: User | undefined): CompanyUserMode[] => {
        const modes: CompanyUserMode[] = []

        const haveOwnerMode = companyUserQuery.data?.some(companyUser => companyUser.is_owner)
        if (haveOwnerMode) {
            modes.push(CompanyUserMode.Owner)
        }

        const haveManagerMode = companyUserQuery.data?.some(companyUser => companyUser.is_property_manager)
        if (haveManagerMode) {
            modes.push(CompanyUserMode.Manager)
        }

        // Admin mode available for users with other modes
        if (modes.length && user?.is_staff) {
            modes.push(CompanyUserMode.Admin)
        }

        tempMe.current.availableModes = modes

        return modes
    }

    const validateModes = (companyUserMode) => {
        let isValid = true

        if (!tempMe.current.availableModes?.length) {
            console.error('Request has empty available modes')
            isValid = false
        }

        if (!isNaN(companyUserMode) && !tempMe.current.availableModes?.includes(companyUserMode)) {
            console.error('User has no requested mode in available list')
            isValid = false
        }

        if (isNaN(companyUserMode)) {
            isValid = false
        }

        return isValid
    }

    const saveCompanyUserMode = () => {
        // -1 is invalid mode
        let companyUserMode = parseInt(localStorage.getItem(LOCAL_STORAGE_COMPANY_USER_MODE_KEY) ?? '-1')

        const isValidMode = validateModes(companyUserMode)

        if (!isValidMode) {
            companyUserMode = tempMe.current.availableModes?.[0] as number
            localStorage.setItem(LOCAL_STORAGE_COMPANY_USER_MODE_KEY, JSON.stringify(companyUserMode))
        }

        tempMe.current.isAdministratorMode = companyUserMode === CompanyUserMode.Admin
        tempMe.current.companyUserMode = companyUserMode
        tempMe.current.isOwnerMode = companyUserMode === CompanyUserMode.Owner
        tempMe.current.isManagerMode = companyUserMode === CompanyUserMode.Manager
    }

    const saveAvailableCompanyUsersForPortal = () => {
        const companyUserMode = tempMe.current.companyUserMode
        const isManager = companyUserMode === CompanyUserMode.Manager
        const isOwner = companyUserMode === CompanyUserMode.Owner
        const isAdmin = companyUserMode === CompanyUserMode.Admin

        const availableCompanyUserItmes = (companyUserQuery?.data ?? [])
            // Filtering manually because we need to save all available company users for other cases
            .filter((companyUser) => {
                if (isManager) {
                    return companyUser.is_property_manager
                } else if (isOwner) {
                    return companyUser.is_owner
                } else if (isAdmin) {
                    return true
                }

                return false
            })

        tempMe.current.availableCompanyUsersForPortal = availableCompanyUserItmes
    }

    const saveCompanyUser = () => {
        // -1 is invalid mode
        let companyIserId: number | undefined = parseInt(localStorage.getItem(LOCAL_STORAGE_COMPANY_USER_ID_KEY) ?? '-1')

        const isValidNumber = !isNaN(companyIserId)
        const companyUserFromLocalStorage = companyUserQuery.data?.find(companyUser =>
            (companyUser.id === companyIserId),
        )

        const isCompanyUserHaveAccessToMode = (
            (companyUserFromLocalStorage?.is_property_manager && tempMe.current.isManagerMode) ||
            (companyUserFromLocalStorage?.is_owner && tempMe.current.isOwnerMode) ||
            (companyUserFromLocalStorage?.is_staff && tempMe.current.isAdministratorMode)
        )

        // User from local storage valid and have access to mode
        if (isValidNumber && companyUserFromLocalStorage?.id && isCompanyUserHaveAccessToMode) {
            companyIserId = companyUserFromLocalStorage.id
        } else {
            companyIserId = tempMe.current.availableCompanyUsersForPortal[0].id
        }

        localStorage.setItem(LOCAL_STORAGE_COMPANY_USER_ID_KEY, `${companyIserId}`)

        const companyUser = companyUserQuery.data?.find(el => el.id === companyIserId) as CompanyUserItem
        const company = companyQuery.data?.find(el => el.id === companyUser.company_id)

        if (!company || !companyUser) {
            throw new Error('Company or company user not found')
        }

        // set company-user-id header
        if (!tempMe.current.isAdministratorMode && http.defaults) {
            http.defaults.headers.common['company-user-id'] = companyUser.id
        }

        tempMe.current.companyUser = companyUser
        tempMe.current.company = company
        tempMe.current.companyFeatures = company?.features ?? {}
    }

    const isDataReady = companyQuery.isFetched && meQuery.isFetched && companyUserQuery.isFetched
    const hasError: boolean = companyQuery.isError || meQuery.isError || companyUserQuery.isError

    useEffect(() => {
        if (hasError) {
            getCurrentScope().setContext('errorMessages', {
                companyMessage: companyQuery.error?.message,
                meMessage: meQuery.error?.message,
                companyUserMessage: companyUserQuery.error?.message,
            })

            throw new Error('Core user info loading error')
        }

        if (!isDataReady) {
            return
        }

        const availableModes = saveCompanyUserAvailableModes(user)

        if (user) {
            tempMe.current.user = user
        }

        if (!companyUserQuery.data?.length || !availableModes.length || !user) {
            tempMe.current.hasAccessToPortal = false
        } else {
            saveCompanyUserMode()
            saveAvailableCompanyUsersForPortal()

            if (!tempMe.current.availableCompanyUsersForPortal?.length) {
                tempMe.current.hasAccessToPortal = false
            } else {
                saveCompanyUser()
            }
        }

        tempMe.current.isReady = true

        if (!tempMe.current.hasAccessToPortal) {
            navigate(COMMON_ROUTES_CONFIG.NO_ACCESS_TO_PORTAL.path)
        }

        setMeObj(tempMe.current)
        me = tempMe.current

        setLoading(false)
    }, [isDataReady])

    if (loading || !settingsQuery.isFetched) {
        return <ContentWithMenu menu={null} content={null} loading/>
    }

    return (
        <meContext.Provider value={meObj}>{props.children}</meContext.Provider>
    )
}
