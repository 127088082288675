import { useCallback, useEffect, useMemo, useState } from 'react'

import cn from 'classnames'
import endOfMonth from 'date-fns/endOfMonth'
import endOfQuarter from 'date-fns/endOfQuarter'
import endOfYear from 'date-fns/endOfYear'
import format from 'date-fns/format'
import isAfter from 'date-fns/isAfter'
import isBefore from 'date-fns/isBefore'
import startOfMonth from 'date-fns/startOfMonth'
import startOfQuarter from 'date-fns/startOfQuarter'
import startOfYear from 'date-fns/startOfYear'

import { Icon, DatePicker, Text } from '@/components/base'
import { InputBox } from '@/components/containers'

import { DATE_FORMATS, DATE_PICKER_LABELS } from '../DatePicker/DatePicker.constants'

import { DateRangePickerProps } from './DateRangePicker.types'

import styles from './DateRangePicker.module.scss'

export const DateRangePicker = (props: DateRangePickerProps) => {
    const {
        className,
        label,
        labelInside,
        size,
        minDate,
        maxDate,
        dateTo,
        dateFrom,
        onChange,
        hint,
        error,
        width = 'min-content',
        type = 'day',
        rootId,
    } = props

    const [isDateFromOpen, setIsDateFromOpen] = useState(false)
    const [isDateToOpen, setIsDateToOpen] = useState(false)
    const [dateFromValue, setDateFromValue] = useState<Date | undefined>(dateFrom)
    const [dateToValue, setDateToValue] = useState<Date | undefined>(dateTo)

    useEffect(() => setDateFromValue(dateFrom), [dateFrom])
    useEffect(() => setDateToValue(dateTo), [dateTo])

    const getFormattedValue = useCallback((date: Date) => format(date, DATE_FORMATS[type]), [type])

    const labelDateFrom = useMemo(() => (
        <span className={cn(styles.label, { [styles.label_active]: isDateFromOpen })} data-testid='dateFromLabel'>
            {dateFromValue ? getFormattedValue(dateFromValue) : `Start ${DATE_PICKER_LABELS[type]}`}
        </span>
    ), [dateFromValue, getFormattedValue, isDateFromOpen, type])

    const labelDateTo = useMemo(() => (
        <span className={cn(styles.label, { [styles.label_active]: isDateToOpen })} data-testid='dateToLabel'>
            {dateToValue ? getFormattedValue(dateToValue) : `End ${DATE_PICKER_LABELS[type]}`}
        </span>
    ), [dateToValue, getFormattedValue, isDateToOpen, type])

    const getDate = useCallback((date: Date, moment: 'start' | 'end') => {
        const start = moment === 'start'
        switch (type) {
            case 'year':
                return start ? startOfYear(date) : endOfYear(date)
            case 'quarter':
                return start ? startOfQuarter(date) : endOfQuarter(date)
            case 'month':
                return start ? startOfMonth(date) : endOfMonth(date)
            default:
                return date
        }
    }, [type])

    const getDateToValue = useCallback((date: Date) => {
        if (!dateToValue) { return dateToValue }
        const newDateTo = isAfter(date, dateToValue) ? date : dateToValue
        return getDate(newDateTo, 'end')
    }, [dateToValue, getDate])

    const getDateFromValue = useCallback((date: Date) => {
        if (!dateFromValue) { return dateFromValue }
        const newDateFrom = isBefore(date, dateFromValue) ? date : dateFromValue
        return getDate(newDateFrom, 'start')
    }, [dateFromValue, getDate])

    return (
        <InputBox
            className={cn(styles.inputBox, className)}
            label={label}
            labelInside={labelInside}
            size={size}
            isActive={isDateFromOpen || isDateToOpen}
            hint={hint}
            error={error}
            width={width}
        >
            <DatePicker
                rootId={rootId}
                customLabel={labelDateFrom}
                date={dateFrom}
                onChange={(date) => {
                    if (!date) { return }
                    setDateFromValue(date)
                    if (dateToValue && isAfter(date, dateToValue)) {
                        setDateToValue(date)
                    }
                    onChange?.({
                        dateFrom: getDate(date, 'start'),
                        dateTo: getDateToValue(date),
                    })
                }}
                onOpenChange={setIsDateFromOpen}
                type={type}
                minDate={minDate}
                maxDate={maxDate}
                offset={[0, 0]}
            />
            <Text
                className={styles.dash}
                text='—'
                color='secondaryBlack'
                variant='smallTextSemiboldDefault'
            />
            <DatePicker
                customLabel={labelDateTo}
                date={dateTo}
                onChange={(date) => {
                    if (!date) { return }
                    setDateToValue(date)
                    if (dateFromValue && isBefore(date, dateFromValue)) {
                        setDateFromValue(date)
                    }

                    onChange?.({
                        dateFrom: getDateFromValue(date),
                        dateTo: getDate(date, 'end'),
                    })
                }}
                onOpenChange={setIsDateToOpen}
                type={type}
                minDate={minDate}
                maxDate={maxDate}
                offset={[0, 0]}
            />
            <Icon
                name='calendar'
                color='colorsSecondaryGrey600'
                className={styles.icon}
            />
        </InputBox>
    )
}
