import cn from 'classnames'

import ListItemText from '@mui/material/ListItemText'
import MenuItem from '@mui/material/MenuItem'
import { observer } from 'mobx-react-lite'

import { Text } from '@/components/base'

import { CompaniesMenuItemProps } from './CompaniesMenuItem.types'

import styles from './CompaniesMenuItem.module.scss'

export const CompaniesMenuItem = observer((props: CompaniesMenuItemProps) => {
    const { className, text, onClick, isActive } = props

    return (
        <MenuItem
            selected={isActive}
            classes={{
                root: cn(styles.companiesMenuItem, className, { [styles.companiesMenuItem_active]: isActive }),
                selected: styles.companiesMenuItem_active,
            }}
            onClick={() => {
                // @ts-expect-error TS(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                onClick()
            }}
        >
            <ListItemText>
                <Text
                    variant='smallTextMediumDefault'
                    color={isActive ? 'primaryPurple' : 'secondaryLightBlack'}
                >
                    {text}
                </Text>
            </ListItemText>
        </MenuItem>
    )
})
