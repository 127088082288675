import { useState } from 'react'

import { runInAction } from 'mobx'

import { observer } from 'mobx-react-lite'

import { Text } from '@/components/base'
import { Layout } from '@/components/containers'
import MultiSelectPopover from '@/components/legacy/inputs/multi-select-popover'
import MultiSelectValues from '@/components/legacy/inputs/multi-select-values'
import { Asset } from '@/models/asset'

import { AssetMultiSelectProps } from './AssetMultiSelect.types'

export const AssetMultiSelect = observer((props: AssetMultiSelectProps) => {
    const { input } = props
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
    return (
        <div className='filter-container' style={{ width: 250 }}>
            <Layout mb={4}>
                <Text variant='smallTextMediumDefault' color='secondaryLightBlack'>Asset Filter</Text>
            </Layout>
            <MultiSelectValues
                ids ={input.value}
                model ={Asset}
                icon ='asset'
                all_values_str ='All Assets'
                label = {(obj: Asset) => { return obj ? `${obj.name}` : '-' }}
                onClick={(event) => setAnchorEl(event.currentTarget)}
            />
            <MultiSelectPopover
                auto_apply
                init ={input.value}
                options ={input.options as any}
                sorted_by ='name'
                anchor ={anchorEl}
                label ={(obj: Asset) => `${obj.name}`}
                onChange ={(ids) => runInAction(() => {
                    input.set(ids)
                })}
                onClose ={() => setAnchorEl(null)}
            />
        </div>
    )
})
