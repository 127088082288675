import mixpanel from 'mixpanel-browser'

import { isStorybookBuild } from '@/globals'

const allowMixpanel = !isStorybookBuild

const env = process.env.NODE_ENV

export const mixpanelTrack = (event: string, properties?: Record<string, any>) => {
    if (allowMixpanel) {
        mixpanel.track(event, {
            page: document.title,
            ...(properties || {}),
        })
    } else {
        // eslint-disable-next-line no-console
        console.warn('mixpanel event', event, 'skipped for environment', env)
    }
}

export const mixpanelTrackPage = (page: string) => {
    if (allowMixpanel) {
        mixpanel.track_pageview({
            page,
        })
    } else {
        // eslint-disable-next-line no-console
        console.warn('mixpanel event skipped for environment', env)
    }
}
