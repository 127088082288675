import React, { useMemo } from 'react'

import { SourceAccountMapPredictionsItem } from '@/api/trialBalance/sourceAccountMapPredictions'
import { SelectOptionWithLabel, SelectOptionWithLabelProps } from '@/components/base/Select/options'
import { SelectOptionListBase } from '@/components/base/Select/optionsList'
import { Layout } from '@/components/containers'
import { selectOptionsMappingLabelProps } from '@/components/widgets/MappingEngineSideModal/MappingEngineSideModal.utils'
import { fsEvent } from '@/utils/fullstory/fsEvent'

import { MappinEngineSuggestProps } from './MappingEngineSuggest.types'

import styles from './MappingEngineSuggest.module.scss'

export const MappingEngineSuggest = (props: MappinEngineSuggestProps) => {
    const { selected, onSelect, suggestItems, getLabel } = props

    const SelectOption = useMemo(() => (props: SelectOptionWithLabelProps<SourceAccountMapPredictionsItem>) => (
        <SelectOptionWithLabel
            {...selectOptionsMappingLabelProps(props.option)}
            {...props}
        />
    ), [])

    return (
        <Layout className={styles.optionsContainer}>
            <SelectOptionListBase<SourceAccountMapPredictionsItem>
                noItemsPlaceholder='No Suggestions Found'
                width='100%'
                optionComponent={SelectOption}
                options={suggestItems}
                // @ts-expect-error TS(2322) FIXME: Type 'SourceAccountMapPredictionsResponse | undefi... Remove this comment to see the full error message
                selected={[suggestItems.find((item) => item.account.id === selected?.id)]}
                labelFn={(item) => getLabel(item.account)}
                idFn={(data) => data?.account?.id?.toString()}
                onChange={(data) => {
                    fsEvent({
                        name: 'Mapping Engine: AI Suggestions Selected',
                        properties: {
                            confidence: data.confidence_score,
                        },
                    })
                    onSelect(data.account)
                }}
            />
        </Layout>
    )
}
