import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'

import { Asset } from './Asset'
import { AssetGroup } from './AssetGroup'

/**
 * Asset In Groups
 */
@api('asset-in-group')
@model
export class AssetInAssetGroup extends Model {
    @field asset_id?:       number
    @field asset_group_id?: number

    @foreign(Asset) readonly asset:            Asset
    @foreign(AssetGroup) readonly asset_group: AssetGroup
}
many(AssetInAssetGroup, 'asset_id')(Asset, 'group_links')
many(AssetInAssetGroup, 'asset_group_id')(AssetGroup, 'links')
