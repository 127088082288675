import { useCallback, useEffect, useMemo, useState } from 'react'

import cn from 'classnames'

import { Icon, Text } from '@/components/base'

import { AccordionContainerProps } from './AccordionContainer.types'

import styles from './AccordionContainer.module.scss'

export const AccordionContainer = (props: AccordionContainerProps) => {
    const { children, open = false, className, header, onOpen, onClose } = props

    const [isOpen, setIsOpen] = useState(open)

    useEffect(() => setIsOpen(open), [open])

    const classNames = useMemo(
        () => cn(className, styles.wrapper, { [styles.isOpen]: isOpen }),
        [className, isOpen],
    )

    const iconClassNames = useMemo(
        () => cn(styles.icon, { [styles.icon_rotated]: isOpen }),
        [isOpen],
    )

    const handleClick = useCallback(() => {
        setIsOpen(currentOpen => {
            !currentOpen && onOpen?.()
            currentOpen && onClose?.()
            return !currentOpen
        })
    }, [onClose, onOpen])

    return header
        ? (
            <div>
                <button
                    className={styles.header}
                    onClick={handleClick}
                >
                    <Icon
                        name='arrowRight'
                        color='colorsPrimaryGrey'
                        className={iconClassNames}
                        size='l'
                    />
                    <Text variant='smallTextMediumDefault' color='colorsPrimaryGrey' text={header}/>
                </button>
                <div className={classNames} inert={isOpen ? undefined : ''}>
                    <div className={styles.content}>{children ?? null}</div>
                </div>
            </div>
        )
        : (
            <div className={classNames} inert={isOpen ? undefined : ''}>
                <div className={styles.content}>{children ?? null}</div>
            </div>
        )
}
