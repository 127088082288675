import { observer } from 'mobx-react-lite'
import { useNavigate } from 'react-router'

import { Button } from '@/components/base'

import { NavigateButtonCellProps } from './NavigateButtonCell.types'

import styles from './NavigateButtonCell.module.scss'

export const NavigateButtonCell = observer((props: NavigateButtonCellProps) => {
    const { data, path, colDef, getPath, type = 'link', buttonText } = props

    const isLink = type === 'link'

    const navigate = useNavigate()

    const handleClick = () => {
        let generatedPath = path

        if (getPath && !path) {
            generatedPath = getPath(data)
        }

        if (generatedPath) {
            navigate(generatedPath)
        }
    }

    // @ts-expect-error TS(2571) FIXME: Object is of type 'unknown'.
    const title = data[colDef.field]

    return isLink ? (
        <div
            onClick={handleClick}
            title={title}
            className={styles.navigateButtonCell}
        >
            {title}
        </div>
    ) : (
        <Button onClick={handleClick} text={buttonText}/>
    )
})
