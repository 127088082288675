import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'

import { Account } from './Account'
import { TrialBalanceLedger as Ledger } from './Ledger'

@api('account-category')
@model
export class AccountCategory extends Model {
    @field name?:                string
    @field ledger_id?:           number
    @field parent_id?:           number
    @field lft?:                 number
    @field rght?:                number
    @field tree_id?:             number
    @field level?:               number
    @field is_default?:          boolean
    @field has_frozen_children?: boolean

    @foreign(Ledger) readonly ledger: Ledger

    parent:   AccountCategory
    children: AccountCategory[]
    accounts: Account[]

    get parents () {
        const parents: AccountCategory[] = []
        let current: AccountCategory = this // eslint-disable-line @typescript-eslint/no-this-alias
        while (current.parent) {
            parents.push(current.parent)
            current = current.parent
        }
        return parents
    }
}
foreign(AccountCategory, 'parent_id')(AccountCategory.prototype, 'parent')
many(AccountCategory, 'parent_id')(AccountCategory, 'children')
