import { useState } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Button, Text, UserLabel } from '@/components/base'
import { useMe } from '@/hooks/core/useMe'
import { AssetNote } from '@/models/asset'

import { AssetNoteCommentProps } from './AssetNoteComment.types'

import styles from './AssetNoteComment.module.scss'

export const AssetNoteComment = observer((props: AssetNoteCommentProps) => {
    const { className, text, userName, createdTime, noteId } = props

    const { me } = useMe()

    const [isDeleting, setIsDeleting] = useState(false)

    const noteObj = AssetNote.get(noteId) as AssetNote
    const isMyNote = me.user?.id === noteObj?.user_id

    const handleDelete = () => {
        setIsDeleting(true)
        noteObj.delete().finally(() => {
            setIsDeleting(false)
        })
    }

    return (
        <div className={cn(styles.assetNoteComment, className)}>
            <div className={styles.header}>
                <UserLabel name={userName} time={createdTime}/>
                {isMyNote && (
                    <Button
                        onClick={handleDelete}
                        icon='delete'
                        theme='secondary'
                        loading={isDeleting}
                    />
                )}
            </div>
            <Text
                variant='smallTextMediumDefault'
                text={text}
            />
        </div>
    )
})
