import { FC, useState } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Icon } from '@/components/base'
import { AccountCategory as TrialBalanceAccountCategory } from '@/models/ledger'

import { AccountCategoryInputProps } from '../AccountCategoryInput.types'

import { AccountCategoryNodeProps } from './AccountCategoryPicker.types'

import styles from '../AccountCategoryInput.module.scss'

const AccountCategoryPicker: FC<AccountCategoryInputProps> = observer((props) => {
    const { value, onChange, exclude } = props
    const parents = [value, ...value.parents]

    return (
        <div className={styles.picker}>
            <AccountCategoryNode
                node={parents[parents.length - 1]}
                // @ts-expect-error TS(2322) FIXME: Type 'null' is not assignable to type 'number | un... Remove this comment to see the full error message
                level={null}
                onCategorySelected={onChange}
                openedByDefault={parents}
                value={value}
                exclude={exclude || []}
            />
        </div>
    )
})

const AccountCategoryNode: FC<AccountCategoryNodeProps> = observer((props) => {
    const { node, level, onCategorySelected, openedByDefault, value, exclude } = props

    const isTrialBalanceCategory = node instanceof TrialBalanceAccountCategory
    const isItemDisabled = isTrialBalanceCategory && node.has_frozen_children

    // @ts-expect-error TS(2532) FIXME: Object is possibly 'undefined'.
    const next_level = level === null ? 1 : level + 1 // eslint-disable-line @typescript-eslint/naming-convention
    const [isOpened, setIsOpened] = useState(() => level === null || !!openedByDefault.find(x => x.id === node.id))
    const getLevelPadding = (level) => 20 * (level || 0)

    const children = node.children.filter((child) => !exclude.includes(child))

    const children_jsx = children.map((child, key) => { // eslint-disable-line @typescript-eslint/naming-convention
        return (
            <AccountCategoryNode
                node={child}
                level={next_level}
                key={`child-${key}`}
                onCategorySelected={onCategorySelected}
                openedByDefault={openedByDefault}
                value={value}
                exclude={exclude}
            />
        )
    })

    return (
        <div className={styles.pickerItem}>
            <div
                // eslint-disable-next-line eqeqeq
                className={cn(styles.pickerItemName, { [styles.pickerItemSelected]: node == value, [styles.pickerItemName_disabled]: isItemDisabled })}
                style={{ paddingLeft: getLevelPadding(level) }}
                onClick={() => {
                    setIsOpened(!isOpened)
                    if (!isItemDisabled) {
                        onCategorySelected(node)
                    }
                }}
                data-testid={`category-${node.id}`}
            >
                {
                    children.length > 0 &&
                    <Icon name={isOpened ? 'arrowDown' : 'arrowRight'} className={styles.pickerItemIcon}/>
                }
                {
                    children.length === 0 &&
                    <div className={styles.bullet}>–</div>
                }
                <div>{node.name}</div>
            </div>
            <div style={{ display: isOpened ? 'block' : 'none' }}>
                {children_jsx}
            </div>
        </div>
    )
})

export default AccountCategoryPicker
