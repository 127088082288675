/* eslint-disable indent */
import cn from 'classnames'

import { observer } from 'mobx-react-lite'

import { Asset } from '@/models/asset'

import styles from './AssetTypeCell.module.scss'

interface AssetTypeCellProps {
    data?: Asset
    /**
     * Need this because can't rewrite data in ag-grid if use not Asset model
     */
    customData?: Asset
}

export const AssetTypeCell = observer((props: AssetTypeCellProps) => {
    return (
        <div className={cn(styles.assetStatusCell)}>
            {(props.customData ?? props.data)?.typeTitle}
        </div>
    )
})
