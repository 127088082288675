import { RoutesConfigValue } from '@/core/routes'

export type DataSubmissionRouteConfigKeys =
    'DATA_SUBMISSION' |
    'VALIDATION_TRACKER' |
    'VALIDATION_TRACKER_ERRORS' |
    'VALIDATION_TRACKER_MONTHLY_RENT_ROLL'

export type DataSubmissionRoutesConfig = {
    [key in DataSubmissionRouteConfigKeys]: RoutesConfigValue
}

export const DATA_SUBMISSION_ROUTES_CONFIG: DataSubmissionRoutesConfig = {
    DATA_SUBMISSION: {
        title: 'Submissions',
        path: '/packages/data-submission',
        metricData: {
            pageViewEventName: 'Submission View',
        },
    },
    VALIDATION_TRACKER: {
        title: 'Submission Tracker',
        path: '/packages/data-submission/validation-tracker',
        metricData: {
            pageViewEventName: 'Submission View',
            pageName: 'Submissions Tab',
        },
    },
    VALIDATION_TRACKER_ERRORS: {
        title: 'Submission Tracker Day',
        path: '/packages/data-submission/validation-tracker/:dataPointId',
    },
    VALIDATION_TRACKER_MONTHLY_RENT_ROLL: {
        title: 'Submission Tracker Monthly Rent Roll',
        path: '/packages/data-submission/validation-tracker/rr/:reportAssetId/:reportYear/:reportMonth',
    },
}
