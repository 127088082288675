import { useMemo } from 'react'

import endOfMonth from 'date-fns/endOfMonth'
import startOfMonth from 'date-fns/startOfMonth'
import { DateInput, NumberInput, StringInput, XAND, XEQ, XGTE, XLTE } from 'mobx-orm'

import { observer } from 'mobx-react-lite'

import { AgGridTableLegacy } from '@/components/legacy/tables/AgGridTableLegacy'
import { useInput, useQueryXPage } from '@/hooks'
import { DataPoint, DataPointType } from '@/models/data_pulling'
import {
    gridOptions,
} from '@/pages/MainPage/CommonPages/ValidationTrackerPage/DataPointSideModal/FileHistory/FileHistory.constants'
import {
    FileHistoryProps,
} from '@/pages/MainPage/CommonPages/ValidationTrackerPage/DataPointSideModal/FileHistory/FileHistory.types'
import { normalizeShortBackDate } from '@/utils/date/normalizeShortBackDate'

import styles from './FileHistory.module.scss'

const FileHistory = observer(({ dataPoint }: FileHistoryProps) => {
    const typeInput = useInput(() => new StringInput({
        value: dataPoint.type,
    }))
    const assetIdInput = useInput(() => new NumberInput({
        value: dataPoint.asset_id,
    }))
    const reportDateFilter = useMemo(() => {
        if (dataPoint.type === DataPointType.TRIAL_BALANCE) { // Trial Balance history is for the whole month
            const reportDate = normalizeShortBackDate(dataPoint.report_date ?? '')
            return XAND(
                XGTE('report_date', new DateInput({ value: startOfMonth(reportDate) })),
                XLTE('report_date', new DateInput({ value: endOfMonth(reportDate) })),
            )
        }
        return XEQ('report_date', new StringInput({ value: dataPoint.report_date }))
    }, [dataPoint])

    const historyQuery = useQueryXPage(DataPoint, {
        filter: XAND(
            XEQ('type', typeInput),
            XEQ('asset_id', assetIdInput),
            reportDateFilter,
        ),
        autoupdate: true,
    })

    return (
        <div className={styles.container}>
            <AgGridTableLegacy
                query={historyQuery}
                {...gridOptions}
            />
        </div>
    )
})

export { FileHistory }
