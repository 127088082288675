import { useMemo } from 'react'

import cn from 'classnames'

import { observer } from 'mobx-react-lite'
import { matchPath, useLocation, useNavigate } from 'react-router'

import { Icon, Text } from '@/components/base'
import { getAvailablePaths } from '@/core/routes/routes.utils'

import { SideMenuItemProps } from './SideMenuItem.types'

import styles from './SideMenuItem.module.scss'

export const SideMenuItem = observer((props: SideMenuItemProps) => {
    const {
        onToggleGroup,
        isOpened,
        itemConfig,
        childrenItems,
        isMenuExpanded,
    } = props

    const navigate = useNavigate()
    const location = useLocation()

    const isChildActive = useMemo(() =>
        Boolean(itemConfig.children?.length) &&
            itemConfig.children?.filter(child => child.path)
                .some((child) => matchPath({ path: child.path as string, end: false }, location.pathname))
    , [location.pathname, childrenItems])

    const isActive = useMemo(() =>
        (
            (itemConfig.path ? matchPath({ path: itemConfig.path, end: false }, location.pathname) : false) ||
            (isChildActive && !isOpened) ||
            (isChildActive && !isMenuExpanded)
        ),
    [location.pathname, itemConfig.path, isChildActive, isOpened, isMenuExpanded],
    )

    const availablePaths = useMemo(() => getAvailablePaths(), [])

    const isItemAvailableForUser = useMemo(() =>
        itemConfig.externalLink ||
        itemConfig.children?.length ||
        (itemConfig.path && availablePaths.includes(itemConfig.path))
    , [itemConfig, availablePaths])

    if (!isItemAvailableForUser) {
        return null
    }

    const handleItemClick = (event: React.MouseEvent) => {
        event.preventDefault()

        onToggleGroup?.(itemConfig.title)

        if (itemConfig.path && !itemConfig.externalLink) {
            navigate(itemConfig.path)
        } else if (itemConfig.externalLink) {
            window.open(itemConfig.externalLink, '_blank')
        }
    }

    const groupArrowClassName = cn(styles.groupArrow, {
        [styles.groupArrow_active]: isOpened,
    })

    const childrenWrapperClassName = cn(styles.childrenWrapper, {
        [styles.childrenWrapper_visible]: isOpened,
    })

    const itemClassName = cn(styles.item, {
        [styles.item_active]: isActive,
    })

    const textColor = (isActive) ? 'colorsPrimaryPurple' : 'colorsSecondaryGrey600'

    return (
        <>
            <a className={itemClassName} data-testid={itemConfig.testId} onClick={handleItemClick}>
                {itemConfig.icon && (
                    <Icon
                        className={styles.itemIcon}
                        size='l'
                        color={textColor}
                        name={itemConfig.icon}
                    />
                )}
                {isMenuExpanded && (
                    <Text
                        variant='smallTextMediumDefault'
                        className={styles.itemText}
                        color={textColor}
                        style={{ flexGrow: 1 }}
                    >
                        {itemConfig.title}
                    </Text>
                )}
                {(Boolean(childrenItems?.length && isMenuExpanded)) && (
                    <Icon
                        className={groupArrowClassName}
                        size='m'
                        color={textColor}
                        name='arrowDown'
                    />
                )}
            </a>
            {(Boolean(childrenItems?.length) && isMenuExpanded) && (
                <div className={childrenWrapperClassName}>
                    {childrenItems}
                </div>
            )}
        </>
    )
})
