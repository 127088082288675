import { useState, useRef } from 'react'

import { observer } from 'mobx-react-lite'

import { Icon, LegacySelectPopover } from '@/components/base'

import { SelectCellProps } from './SelectCell.types'

import styles from './SelectCell.module.scss'

/**
 * @todo Use "SelectTriggerCell" inside
 */
export const SelectCell = observer((props: SelectCellProps) => {
    const {
        content = null,
        options,
        customValue,
        onChange,
        headerComponent,
        searchPlaceholder,
        search,
    } = props

    const cellContainerRef = useRef<HTMLDivElement>(null)
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

    const onClick = (e: React.MouseEvent<HTMLElement>) => {
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-type-assertion
        setAnchorEl((e.currentTarget.closest('.ag-cell') ?? cellContainerRef.current) as HTMLElement)
    }

    const onClose = () => {
        setAnchorEl(null)
    }

    return (
        <div className={styles.cell} ref={cellContainerRef}>
            <span className={styles.text} onClick={onClick}>{content ?? ''}</span>
            <Icon name='arrowDown' onClick={onClick} dataTestId='cell-icon'/>
            <LegacySelectPopover
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                options={options}
                customValue={customValue}
                onChange={onChange}
                onClose={onClose}
                searchPlaceholder={searchPlaceholder}
                headerComponent={headerComponent}
                search={search}
            />
        </div>
    )
})
