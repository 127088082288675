import { useContext, useState } from 'react'

import { ValueType } from 'mobx-orm'

import { observer } from 'mobx-react-lite'

import { DateRangePicker } from '@/components/base'
import { dateToMonthInd, monthIndToDateNoTimezoneReset } from '@/utils/date/monthInd'
import { ModelInput } from '@/utils/mobx-orm'

import { ModelFormContext } from '../../ModelFromContext'

import { DateMonthPeriodInputProps } from './DateMonthPeriodInput.types'

// TODO: move it to utils
function isValidDate (date?: Date): boolean {
    return date instanceof Date && !isNaN(date as any)
}

// DEPRECATED
// REFACTORING: if RangePicker is only month picker then it should use month index format instead of Date
export const DateMonthPeriodInput = observer((props: DateMonthPeriodInputProps) => {
    const { obj, fromField, toField, fromFilter, toFilter, syncURL, syncLocalStorage, label = 'Time Period' } = props
    const modelForm = useContext(ModelFormContext)

    const [fromModelInput] = useState(() => new ModelInput({
        obj: obj || modelForm.obj,
        field: fromField,
        filter: fromFilter,
        syncURL: syncURL || modelForm.syncURL,
        syncLocalStorage: syncLocalStorage || modelForm.syncLocalStorage,
        type: ValueType.NUMBER,
    }))

    const [toModelInput] = useState(() => new ModelInput({
        obj: obj || modelForm.obj,
        field: toField,
        filter: toFilter,
        syncURL: syncURL || modelForm.syncURL,
        syncLocalStorage: syncLocalStorage || modelForm.syncLocalStorage,
        type: ValueType.NUMBER,
    }))

    const dateFrom = fromModelInput.value ? monthIndToDateNoTimezoneReset(fromModelInput.value) : undefined
    const dateTo = toModelInput.value ? monthIndToDateNoTimezoneReset(toModelInput.value) : undefined

    return (
        <DateRangePicker
            type='month'
            label={label}
            dateFrom={dateFrom}
            dateTo={dateTo}
            onChange={({ dateFrom, dateTo }) => {
                if (isValidDate(dateFrom)) fromModelInput.set(dateFrom ? dateToMonthInd(dateFrom) : undefined)
                if (isValidDate(dateTo)) toModelInput.set(dateTo ? dateToMonthInd(dateTo) : undefined)
            }}
        />
    )
})
