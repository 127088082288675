import { Model, model, field, foreign, many } from 'mobx-orm'

import { api } from '../../http-adapter'

import { Account } from './Account'
import { SourceAccount } from './SourceAccount'

@api('source-account-map')
@model
export class SourceAccountMap extends Model {
    @field source_account_id?: number
    @field account_id?:        number

    @foreign(SourceAccount) readonly source_account: SourceAccount
    @foreign(Account) readonly account:              Account
}
many(SourceAccountMap, 'source_account_id')(SourceAccount, 'maps')
